import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { IUser, defaultValue } from 'app/shared/model/user.model';
import { ICompany } from 'app/shared/model/company.model';

const initialState: EntityState<IUser> = {
  loading: false,
  errorMessage: null,
  entities: [],
  allEntities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
  error: null,
};

const apiUrl = 'api/admin/users';

// Actions

export const getEntities = createAsyncThunk('employee/fetch_entity_list', async ({ page, size, sort, name }: IQueryParams) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}&` : '?'}cacheBuster=${new Date().getTime()}${
    name !== undefined ? `&name=${name}` : ''
  }`;
  return axios.get<IUser[]>(requestUrl);
});

export const getAllEntities = createAsyncThunk('employee/fetch_all_entity_list', async () => {
  return axios.get<ICompany[]>(`${apiUrl}/list`);
});

export const getEntity = createAsyncThunk(
  'employee/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<IUser>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  'employee/create_entity',
  async (entity: any, thunkAPI) => {
    const result = await axios.post<IUser>(apiUrl, entity);
    // thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'employee/update_entity',
  async (entity: any, thunkAPI) => {
    const result = await axios.put<IUser>(`${apiUrl}`, { id: entity.id, ...entity });
    // thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const changePassword = createAsyncThunk(
  'employee/change_password',
  async (entity: any, thunkAPI) => {
    const config = {
      headers: { 'Content-Type': 'text/plain' },
    };
    const result = await axios.patch<IUser>(`api/admin/user/${entity.id}`, entity.password, config);
    // thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const enableDisableEntity = createAsyncThunk(
  'employee/partial_update_entity',
  async ({ id, enabled }: any, thunkAPI) => {
    const result = await axios.patch<IUser>(`${apiUrl}/${id}?enabled=${enabled}`);
    // thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  'employee/delete_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await axios.delete<IUser>(requestUrl);
    // thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

// slice

export const BusinessSlice = createEntitySlice({
  name: 'employee',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addMatcher(isRejected(createEntity, updateEntity), (state, action) => {
        state.updating = false;
        state.updateSuccess = false;
        state.error = action.error;
      })
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        const { data, headers } = action.payload;

        return {
          ...state,
          loading: false,
          entities: data,
          totalItems: parseInt(headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(getAllEntities), (state, action) => {
        return {
          ...state,
          loading: false,
          allEntities: action.payload.data,
        };
      })
      .addMatcher(isFulfilled(createEntity, updateEntity, enableDisableEntity, changePassword), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isPending(getEntities, getEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, enableDisableEntity, changePassword, deleteEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { reset } = BusinessSlice.actions;

// Reducer
export default BusinessSlice.reducer;
