import dayjs from 'dayjs';
import { IUserProfile } from 'app/shared/model/user-profile.model';
import { IOffer } from 'app/shared/model/offer.model';

export interface IOfferLog {
  id?: number;
  createdDate?: string | null;
  user?: IUserProfile | null;
  offer?: IOffer | null;
}

export const defaultValue: Readonly<IOfferLog> = {};
