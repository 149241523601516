import React, { useEffect, useState } from 'react';
import { Translate, translate } from 'react-jhipster';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntities } from './notification.reducer';
import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { CancelIcon, DeleteIcon } from 'app/components/icons';
import NotificationDeleteDialog from 'app/entities/notification/notification-delete-dialog';
import NotificationCreate from 'app/entities/notification/notification-create';
import moment from 'moment';
import { Spinner } from 'reactstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { getSortState, overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { SORT } from 'app/shared/util/pagination.constants';

export const Notification = () => {
  const dispatch = useAppDispatch();

  const notificationList = useAppSelector(state => state.notification.entities);
  const loading = useAppSelector(state => state.notification.loading);
  const updateSuccess = useAppSelector(state => state.notification.updateSuccess);

  const location = useLocation();
  const navigate = useNavigate();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(location, rowsPerPage, 'id'), location.search)
  );
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    setPaginationState({
      ...paginationState,
      activePage: newPage,
    });
  };
  const getAllEntities = () => {
    dispatch(
      getEntities({
        page: paginationState.activePage,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
      })
    );
  };

  useEffect(() => {
    getAllEntities();
  }, [updateSuccess]);

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setPaginationState({
      ...paginationState,
      itemsPerPage: parseInt(event.target.value, 10),
      activePage: 0,
    });
  };

  const totalItems = useAppSelector(state => state.notification.totalItems);
  const [createNotificationOpen, setCreateNotificationOpen] = React.useState(false);
  const [deleteNotificationOpen, setDeleteNotificationOpen] = React.useState(false);
  const [deleteNotificationId, setDeleteNotificationId] = React.useState(undefined);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    paginationState.activePage > 0
      ? Math.max(0, (1 + paginationState.activePage) * paginationState.itemsPerPage - notificationList.length)
      : 0;
  const sortEntities = () => {
    getAllEntities();
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort, paginationState.itemsPerPage]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const paramsPage = params.get('page');
    const sort = params.get(SORT);
    if (paramsPage && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: Number(paramsPage),
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [location.search]);

  return (
    <Container maxWidth="xl">
      <Box mt={2}>
        <Grid container justifyContent={'flex-end'} sx={{ mb: 2 }}>
          <Button
            color="primary"
            size="large"
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => {
              setCreateNotificationOpen(true);
            }}
          >
            <Translate contentKey="kafaatApp.notification.home.createLabel">Create new notification</Translate>
          </Button>
        </Grid>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ borderBottom: 1, borderColor: 'grey.300', padding: '25px 30px' }} width={'20%'}>
                  <Translate contentKey="kafaatApp.notification.title">title</Translate>
                </TableCell>
                <TableCell sx={{ borderBottom: 1, borderColor: 'grey.300', padding: '25px 30px' }} width={'25%'}>
                  <Translate contentKey="kafaatApp.notification.content">content</Translate>
                </TableCell>
                <TableCell sx={{ borderBottom: 1, borderColor: 'grey.300', padding: '25px 30px' }} width={'20%'}>
                  <Translate contentKey="kafaatApp.notification.sendDate">sendDate</Translate>
                </TableCell>
                <TableCell sx={{ borderBottom: 1, borderColor: 'grey.300', padding: '25px 30px' }} width={'15%'}>
                  <Translate contentKey="kafaatApp.notification.cancelled">cancelled</Translate>
                </TableCell>
                <TableCell sx={{ borderBottom: 1, borderColor: 'grey.300', padding: '25px 30px' }} width={'15%'}>
                  <Translate contentKey="kafaatApp.business.actions">Actions</Translate>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell colSpan={7} height={'350px'} width={'350px'}>
                    <Box justifyContent={'center'} alignItems={'center'} display={'flex'} width={'100%'} height={'100%'}>
                      <Spinner />
                    </Box>
                  </TableCell>
                </TableRow>
              ) : notificationList?.length > 0 ? (
                <>
                  {notificationList.map(row => (
                    <TableRow key={row.id}>
                      <TableCell scope="row">
                        <Typography color={'secondary'}>{row.title}</Typography>
                      </TableCell>
                      <TableCell scope="row">
                        <Typography color={'primary.dark'}>{row.content}</Typography>
                      </TableCell>
                      <TableCell>{moment(row.sendDate).format('DD/MM/YYYY hh:mm A')}</TableCell>
                      <TableCell>
                        {row.cancelled ? translate('kafaatApp.notification.yes') : translate('kafaatApp.notification.no')}
                      </TableCell>
                      <TableCell>
                        <Stack direction="row" spacing={4}>
                          {!row.cancelled && !moment().isAfter(moment(row.sendDate)) && (
                            <Tooltip title={<Translate contentKey="entity.action.cancel">Delete</Translate>}>
                              <IconButton
                                sx={{ color: 'transparent' }}
                                onClick={() => {
                                  setDeleteNotificationId(row.id);
                                  setDeleteNotificationOpen(true);
                                }}
                              >
                                <CancelIcon />
                              </IconButton>
                            </Tooltip>
                          )}
                        </Stack>
                      </TableCell>
                    </TableRow>
                  ))}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 2 * emptyRows }}>
                      <TableCell colSpan={4} />
                    </TableRow>
                  )}
                </>
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <Box justifyContent={'center'} flexDirection={'column'} textAlign={'center'} display={'flex'} padding={'50px'}>
                      <Typography variant={'subtitle1'} color={'gray'}>
                        <Translate contentKey={'home.noData'} />
                      </Typography>
                    </Box>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          {totalItems ? (
            <TablePagination
              rowsPerPageOptions={[5, 10, 20]}
              component="div"
              count={totalItems}
              rowsPerPage={rowsPerPage}
              labelRowsPerPage={translate('home.rowsPerPage')}
              labelDisplayedRows={({ from, to, count }) => `${from}–${to}`}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          ) : (
            ''
          )}
        </TableContainer>
      </Box>
      <NotificationCreate open={createNotificationOpen} setOpen={setCreateNotificationOpen} />
      <NotificationDeleteDialog open={deleteNotificationOpen} setOpen={setDeleteNotificationOpen} id={deleteNotificationId} />
    </Container>
  );
};

export default Notification;
