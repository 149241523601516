import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { translate, Translate } from 'react-jhipster';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { getEntities as getBusinessCategories } from 'app/entities/business-category/business-category.reducer';
import { createEntity, getEntity, updateEntity } from './business.reducer';
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FilledInput,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import { Field, FieldArray, Formik, getIn } from 'formik';
import AddIcon from '@mui/icons-material/Add';
import MapPicker from 'react-google-map-picker';
import useScriptRef from 'app/hooks/useScriptRef';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';
import { WEBSITE_REGEX } from 'app/config/constants';
import { AttachmentIcon } from 'app/components/icons';
import { LoadingButton } from '@mui/lab';

const ErrorMessage = ({ name }) => (
  <Field
    name={name}
    render={({ form }) => {
      const error = getIn(form.errors, name);
      const touch = getIn(form.touched, name);
      return touch && error ? error : null;
    }}
  />
);
const DefaultLocation = { lat: 24.774265, lng: 46.738586 };
const DefaultZoom = 6;
export const BusinessUpdate = ({ open, setOpen, id }) => {
  const dispatch = useAppDispatch();
  const scriptedRef = useScriptRef();
  const { enqueueSnackbar } = useSnackbar();
  const [defaultLocation, setDefaultLocation] = useState(DefaultLocation);
  const [Glocation, setGLocation] = useState(defaultLocation);
  const [zoom, setZoom] = useState(DefaultZoom);
  const handleChangeLocation = (lat: number, lng: number) => {
    setGLocation({ lat, lng });
  };
  const handleChangeZoom = newZoom => {
    setZoom(newZoom);
  };

  const navigate = useNavigate();
  const currentLocale = useAppSelector(state => state.locale.currentLocale);

  const isNew = id === undefined;

  const businessEntity = useAppSelector(state => state.business.entity);
  const loading = useAppSelector(state => state.business.loading);
  const updating = useAppSelector(state => state.business.updating);
  const updateSuccess = useAppSelector(state => state.business.updateSuccess);
  const [locationModalOpen, setLocationModalOpen] = React.useState(false);
  const businessCategories = useAppSelector(state => state.businessCategory.entities);

  const handleClose = () => {
    setOpen(false);
    navigate('/business' + location.search);
  };

  useEffect(() => {
    if (!isNew) {
      dispatch(getEntity(id));
    }
    dispatch(getBusinessCategories({}));
  }, [open]);

  useEffect(() => {
    if (updateSuccess && open) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = async values => {
    const entity = {
      ...values,
    };
    const formData: any = new FormData();
    formData.append('category.id', entity.category?.id);
    formData.append('category.name', entity.category?.name);
    formData.append('name', entity.name);
    formData.append('nameEn', entity.nameEn);

    if (entity.website) formData.append('website', entity.website);
    if (!entity.logo?.filePath && entity.logo) formData.append('logo', entity.logo);
    values.locations.map((location: any, index: number) => {
      if (location.id) formData.append(`locations[${index}].id`, location.id);
      formData.append(`locations[${index}].name`, location.name);
      formData.append(`locations[${index}].nameEn`, location.nameEn);
      formData.append(`locations[${index}].longitude`, location.longitude);
      formData.append(`locations[${index}].latitude`, location.latitude);
      formData.append(`locations[${index}].generateQR`, location.generateQR);
    });
    if (isNew) {
      const {
        meta: { requestStatus },
      } = await dispatch(createEntity(formData));
      if (requestStatus === 'fulfilled') {
        enqueueSnackbar(translate('kafaatApp.business.createSuccess'), { variant: 'success' });
      } else {
        enqueueSnackbar(translate('kafaatApp.business.error'), { variant: 'error' });
      }
    } else {
      const {
        meta: { requestStatus },
      } = await dispatch(
        updateEntity({
          id: entity.id,
          values: formData,
        })
      );
      if (requestStatus === 'fulfilled') {
        enqueueSnackbar(translate('kafaatApp.business.updateSuccess'), { variant: 'success' });
      }
    }
  };

  return (
    <Dialog maxWidth={'md'} fullWidth open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title" color={'secondary'}>
        {isNew ? (
          <Translate contentKey="kafaatApp.business.home.createLabel">Create new Business</Translate>
        ) : (
          <Translate contentKey="kafaatApp.business.home.editLabel">Edit Business</Translate>
        )}
      </DialogTitle>
      <Divider />
      {(isNew || (!isNew && !loading)) && (
        <Formik
          initialValues={
            isNew
              ? {
                  name: undefined,
                  nameEn: undefined,
                  category: undefined,
                  website: undefined,
                  logo: undefined,
                  locations: [],
                }
              : {
                  ...businessEntity,
                }
          }
          validationSchema={Yup.object().shape({
            name: Yup.string().max(255).required(translate('error.form.required')),
            nameEn: Yup.string().max(255).required(translate('error.form.required')),
            category: Yup.mixed().required(translate('error.form.required')),
            logo: Yup.mixed().nullable().required(translate('error.form.required')),
            website: Yup.string().nullable().trim().matches(WEBSITE_REGEX, translate('error.form.incorrectValue')),
            locations: Yup.array().of(
              Yup.object({
                name: Yup.string().max(255).required(translate('error.form.required')),
                nameEn: Yup.string().max(255).required(translate('error.form.required')),
                latitude: Yup.string().required(translate('error.form.required')),
                longitude: Yup.string().required(translate('error.form.required')),
                generateQR: Yup.boolean().required(translate('error.form.required')),
              })
            ),
          })}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
              await saveEntity(values);

              if (scriptedRef.current) {
                setStatus({ success: true });
                setSubmitting(false);
                handleClose();
              }
            } catch (err) {
              console.error(err);
              if (scriptedRef.current) {
                setStatus({ success: false });
                setSubmitting(false);
              }
            }
          }}
        >
          {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, setFieldValue, touched, values }) => (
            <form noValidate onSubmit={handleSubmit}>
              <DialogContent>
                <Typography color="secondary" variant="subtitle2" gutterBottom>
                  <Translate contentKey="kafaatApp.business.businessDetails">Business Details </Translate>
                </Typography>
                <FormControl fullWidth margin="normal" error={Boolean(touched.name && errors.name)}>
                  <FormLabel>
                    <Translate contentKey="kafaatApp.business.nameAr">Name </Translate>
                  </FormLabel>
                  <FilledInput
                    id="business-name"
                    type={'text'}
                    name="name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.name}
                  />
                  {touched.name && errors.name && (
                    <FormHelperText error id="standard-weight-helper-text">
                      {errors.name.toString()}
                    </FormHelperText>
                  )}
                </FormControl>
                <FormControl fullWidth margin="normal" error={Boolean(touched.nameEn && errors.nameEn)}>
                  <FormLabel>
                    <Translate contentKey="kafaatApp.business.nameEn">nameEn </Translate>
                  </FormLabel>
                  <FilledInput
                    id="business-nameEn"
                    type={'text'}
                    name="nameEn"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.nameEn}
                  />
                  {touched.nameEn && errors.nameEn && (
                    <FormHelperText error id="standard-weight-helper-text">
                      {errors.nameEn.toString()}
                    </FormHelperText>
                  )}
                </FormControl>
                <FormControl fullWidth margin="normal" variant={'filled'} error={Boolean(touched.category && errors.category)}>
                  <FormLabel>
                    <Translate contentKey="kafaatApp.business.category">category </Translate>
                  </FormLabel>
                  <Autocomplete
                    id="company-category"
                    onBlur={handleBlur}
                    inputMode={'text'}
                    value={values.category}
                    onChange={(e, value) => setFieldValue('category', value)}
                    getOptionLabel={(option: any) => (currentLocale === 'ar-ly' ? option.name : option.nameEn)}
                    renderInput={params => (
                      <TextField error={Boolean(touched.category && errors.category)} {...params} variant={'filled'} />
                    )}
                    options={businessCategories}
                  />
                  {touched.category && errors.category && (
                    <FormHelperText error id="standard-weight-helper-text">
                      {errors.category.toString()}
                    </FormHelperText>
                  )}
                </FormControl>
                <FormControl fullWidth margin="normal" error={Boolean(touched.logo && errors.logo)}>
                  <FormLabel>
                    <Translate contentKey="kafaatApp.business.logo">Logo </Translate>
                  </FormLabel>
                  <FilledInput
                    id="file-name"
                    type={'text'}
                    value={values.logo ? (values.logo.name ? values.logo.name : values.logo.fileName) : ''}
                    endAdornment={
                      <IconButton component="label">
                        <AttachmentIcon />
                        <input
                          type="file"
                          accept={'image/*'}
                          hidden
                          name={'logo'}
                          onChange={event => {
                            setFieldValue('logo', event.currentTarget.files[0]);
                          }}
                          onBlur={handleBlur}
                          id="fileupload"
                        />
                      </IconButton>
                    }
                  />
                  {touched.logo && errors.logo && (
                    <FormHelperText error id="standard-weight-helper-text">
                      {errors.logo.toString()}
                    </FormHelperText>
                  )}
                </FormControl>
                <FormControl fullWidth margin="normal" error={Boolean(touched.website && errors.website)}>
                  <FormLabel>
                    <Translate contentKey="kafaatApp.business.website">Website </Translate>
                  </FormLabel>
                  <FilledInput
                    id="business-website"
                    type={'text'}
                    name="website"
                    value={values.website}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  {touched.website && errors.website && (
                    <FormHelperText error id="standard-weight-helper-text">
                      {errors.website.toString()}
                    </FormHelperText>
                  )}
                </FormControl>
                <br /> <br />
                <Typography color="secondary" variant="subtitle2" gutterBottom>
                  <Translate contentKey="kafaatApp.business.businessLocations">Business Locations </Translate>
                </Typography>
                <FieldArray name="locations">
                  {({ insert, remove, push }) => (
                    <div>
                      {values.locations?.length > 0 &&
                        values.locations.map((location, index) => (
                          <div key={index}>
                            <Grid container justifyContent={'space-between'}>
                              <Grid item>
                                <Grid container spacing={2}>
                                  <Grid item>
                                    <FormControl
                                      margin="normal"
                                      size="small"
                                      error={Boolean(
                                        getIn(touched, `locations[${index}].name`) && getIn(errors, `locations[${index}].name`)
                                      )}
                                    >
                                      <FormLabel>
                                        <Translate contentKey="kafaatApp.business.locationNameAr">Location Name </Translate>
                                      </FormLabel>
                                      <FilledInput
                                        id={`business-location-name${index}`}
                                        type={'text'}
                                        name={`locations.${index}.name`}
                                        value={location.name}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                      />
                                      <FormHelperText error id="standard-weight-helper-text">
                                        <ErrorMessage name={`locations[${index}].name`} />
                                      </FormHelperText>
                                    </FormControl>
                                  </Grid>
                                  <Grid item>
                                    <FormControl
                                      margin="normal"
                                      size="small"
                                      error={Boolean(
                                        getIn(touched, `locations[${index}].nameEn`) && getIn(errors, `locations[${index}].nameEn`)
                                      )}
                                    >
                                      <FormLabel>
                                        <Translate contentKey="kafaatApp.business.locationNameEn">Location Name </Translate>
                                      </FormLabel>
                                      <FilledInput
                                        id={`business-location-nameEn${index}`}
                                        type={'text'}
                                        name={`locations.${index}.nameEn`}
                                        value={location.nameEn}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                      />
                                      <FormHelperText error id="standard-weight-helper-text">
                                        <ErrorMessage name={`locations[${index}].nameEn`} />
                                      </FormHelperText>
                                    </FormControl>
                                  </Grid>
                                  <Grid item>
                                    <FormControl
                                      size="small"
                                      margin="normal"
                                      error={Boolean(
                                        getIn(touched, `locations[${index}].longitude`) && getIn(errors, `locations[${index}].longitude`)
                                      )}
                                    >
                                      <FormLabel id="location-business-button">
                                        <Translate contentKey="kafaatApp.business.businessLocation">Business Location </Translate>
                                      </FormLabel>
                                      <Button
                                        aria-labelledby="location-business-button"
                                        variant="outlined"
                                        onClick={() => {
                                          if (values.locations[index].latitude)
                                            setDefaultLocation({
                                              lat: parseFloat(values.locations[index].latitude),
                                              lng: parseFloat(values.locations[index].longitude),
                                            });
                                          else setDefaultLocation(DefaultLocation);
                                          setLocationModalOpen(true);
                                        }}
                                        startIcon={<LocationOnIcon />}
                                      >
                                        <Translate contentKey="kafaatApp.business.locationOnMap">Location on Map</Translate>
                                      </Button>
                                      <Dialog open={locationModalOpen} onClose={() => setLocationModalOpen(false)}>
                                        <DialogContent>
                                          <MapPicker
                                            defaultLocation={defaultLocation}
                                            zoom={zoom}
                                            style={{ height: '600px', width: '500px' }}
                                            onChangeLocation={handleChangeLocation}
                                            onChangeZoom={handleChangeZoom}
                                            apiKey="AIzaSyDbThmL-zw_rk-mWcSGVnRgm1y-zaiwgIA"
                                          />
                                        </DialogContent>
                                        <DialogActions>
                                          <Button
                                            size={'large'}
                                            onClick={() => setLocationModalOpen(false)}
                                            variant="outlined"
                                            color={'secondary'}
                                          >
                                            <Translate contentKey="kafaatApp.business.home.cancel">Cancel</Translate>
                                          </Button>
                                          <Button
                                            size={'large'}
                                            onClick={() => {
                                              setFieldValue(`locations.${index}.longitude`, Glocation.lng);
                                              setFieldValue(`locations.${index}.latitude`, Glocation.lat);
                                              setLocationModalOpen(false);
                                            }}
                                            color="primary"
                                            variant="contained"
                                          >
                                            <Translate contentKey="kafaatApp.business.selectLocation">Select Location </Translate>
                                          </Button>
                                        </DialogActions>
                                      </Dialog>
                                      <FormHelperText error id="standard-weight-helper-text">
                                        <ErrorMessage name={`locations[${index}].longitude`} />
                                      </FormHelperText>
                                    </FormControl>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item>
                                <FormControl
                                  size="small"
                                  margin="normal"
                                  error={Boolean(
                                    getIn(touched, `locations[${index}].generateQR`) && getIn(errors, `locations[${index}].generateQR`)
                                  )}
                                >
                                  <FormLabel>
                                    <Translate contentKey="kafaatApp.business.locationQr">Location QR Code </Translate>
                                  </FormLabel>
                                  <RadioGroup
                                    row
                                    name={`locations.${index}.generateQR`}
                                    value={location.generateQR}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                  >
                                    <FormControlLabel
                                      value={true}
                                      control={<Radio size="small" />}
                                      label={
                                        <Typography fontSize={'small'}>
                                          <Translate contentKey="kafaatApp.business.locationQrTrue">Generate new </Translate>
                                        </Typography>
                                      }
                                    />
                                    <FormControlLabel
                                      value={false}
                                      control={<Radio size="small" />}
                                      label={
                                        <Typography fontSize={'small'}>
                                          <Translate contentKey="kafaatApp.business.locationQrFalse">Use Business QR Code </Translate>
                                        </Typography>
                                      }
                                    />
                                  </RadioGroup>
                                  <FormHelperText error id="standard-weight-helper-text">
                                    <ErrorMessage name={`locations[${index}].generateQR`} />
                                  </FormHelperText>
                                </FormControl>
                              </Grid>
                            </Grid>
                          </div>
                        ))}
                      <Button variant="contained" startIcon={<AddIcon />} onClick={() => push({})}>
                        <Translate contentKey="kafaatApp.business.newLocation">New Location </Translate>
                      </Button>
                    </div>
                  )}
                </FieldArray>
              </DialogContent>
              <DialogActions>
                <Button size={'large'} onClick={handleClose} variant="outlined" color={'secondary'}>
                  <Translate contentKey="kafaatApp.business.home.cancel">Cancel</Translate>
                </Button>
                <LoadingButton size={'large'} color="primary" variant="contained" loading={isSubmitting} type={'submit'}>
                  <Translate contentKey="kafaatApp.business.home.save">Save</Translate>
                </LoadingButton>
              </DialogActions>
            </form>
          )}
        </Formik>
      )}
    </Dialog>
  );
};

export default BusinessUpdate;
