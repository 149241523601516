import React, { useEffect, useState } from 'react';
// @ts-ignore
import styles from './NavMenu.module.scss';
import { AppBar, Toolbar, Grid, Button, Drawer, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Link, useLocation } from 'react-router-dom';
import { isRTL } from 'app/config/translation';
import { NavLinks } from 'app/components/PublicLayout/NavLinks';
import MenuIcon from '@mui/icons-material/Menu';
import { useAppSelector } from 'app/config/store';
import { Language } from 'app/components/PublicLayout/Language';
import { O2ELogo } from 'app/components/icons';
import { JoinUsDialog } from 'app/components/PublicLayout/JoinUsDialog';
import { ContactUsDialog } from 'app/components/PublicLayout/ContactUsDialog';
import MenuItem, { IMenuItem } from 'app/shared/layout/menus/menu-item';
import { MenuLinkI } from 'app/interfaces/menuItems';

const NavMenu = ({ openJoin, setOpenJoin, openContact, setOpenContact, handleClick, mobileOpen, setMobileOpen }) => {
  const theme = useTheme();
  const currentLocale = useAppSelector(state => state.locale.currentLocale);
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  const mdUp = useMediaQuery(theme.breakpoints.up('md'));
  const { pathname } = useLocation();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  useEffect(() => {
    setMobileOpen(false);
  }, [pathname]);

  return (
    <AppBar className={styles.appBarPadding}>
      <div style={{ display: 'flex', gap: '3rem', alignItems: 'center', width: '100%' }}>
        <O2ELogo style={{ width: '50px', height: '50px', margin: '5px' }} />
        {mdUp && (
          <div style={{ width: '100%' }}>
            <NavLinks handleClick={handleClick} />
          </div>
        )}
        {mdDown && (
          <div style={{ display: 'flex', justifyContent: 'flex-end', marginInlineStart: 'auto' }}>
            <Button color="inherit" aria-label="open drawer" onClick={handleDrawerToggle}>
              <MenuIcon color={'primary'} fontSize={'large'} />
            </Button>
          </div>
        )}
      </div>
      {mdDown && (
        <Drawer
          className={styles.drawer}
          variant="temporary"
          anchor={isRTL(currentLocale) ? 'left' : 'right'}
          open={mobileOpen}
          onClose={handleDrawerToggle}
        >
          <NavLinks handleClick={handleClick} />
        </Drawer>
      )}
      <JoinUsDialog open={openJoin} setOpen={setOpenJoin} />
      <ContactUsDialog open={openContact} setOpen={setOpenContact} />
    </AppBar>
  );
};
export { NavMenu };
