// @ts-ignore
import buyIcon from '../svg/Iconly_Curved_Buy.svg';
// @ts-ignore
import settingsIcon from '../svg/Iconly_Curved_Setting.svg';
// @ts-ignore
import activityIcon from '../svg/Iconly_Curved_Activity.svg';
// @ts-ignore
import graphIcon from '../svg/Iconly_Curved_Graph.svg';
// @ts-ignore
import workIcon from '../svg/Iconly_Curved_Work.svg';
// @ts-ignore
import userIcon from '../svg/Iconly_Curved_2_User.svg';
// @ts-ignore
import profileIcon from '../svg/Profile.svg';
// @ts-ignore
import editIcon from '../svg/Edit.svg';
// @ts-ignore
import showIcon from '../svg/Show.svg';
// @ts-ignore
import deleteIcon from '../svg/Delete.svg';
// @ts-ignore
import dangerIcon from '../svg/Danger.svg';
// @ts-ignore
import lockIcon from '../svg/Lock.svg';
// @ts-ignore
import ticketIcon from '../svg/Ticket.svg';
// @ts-ignore
import o2eLogo from '../svg/o2e brand logo 2.svg';
// @ts-ignore
import passwordIcon from '../svg/Password.svg';
// @ts-ignore
import discount from '../svg/Discount.svg';
// @ts-ignore
import chartIcon from '../svg/Chart.svg';
// @ts-ignore
import scanIcon from '../svg/Scan.svg';
// @ts-ignore
import notificationsIcon from '../svg/Notification.svg';
// @ts-ignore
import cancelIcon from '../svg/Close_Square.svg';
// @ts-ignore
import contactUsIcon from '../svg/Send.svg';
// @ts-ignore
import joinUsIcon from '../svg/Edit Square.svg';
// @ts-ignore
import downloadQRIcon from '../svg/Download.svg';
// @ts-ignore
import attachmentIcon from '../svg/attachment-2.svg';
// @ts-ignore
import categoryIcon from '../svg/Category.svg';

import { SvgIcon } from '@mui/material';
import React from 'react';

export const BuyIcon = props => <SvgIcon component={buyIcon} inheritViewBox {...props} />;
export const SettingsIcon = props => <SvgIcon component={settingsIcon} inheritViewBox {...props} />;
export const ActivityIcon = props => <SvgIcon component={activityIcon} inheritViewBox {...props} />;
export const GraphIcon = props => <SvgIcon component={graphIcon} inheritViewBox {...props} />;
export const WorkIcon = props => <SvgIcon component={workIcon} inheritViewBox {...props} />;
export const UserIcon = props => <SvgIcon component={userIcon} inheritViewBox {...props} />;
export const ProfileIcon = props => <SvgIcon component={profileIcon} inheritViewBox {...props} />;
export const EditIcon = props => <SvgIcon component={editIcon} inheritViewBox {...props} />;
export const ShowIcon = props => <SvgIcon component={showIcon} inheritViewBox {...props} />;
export const DeleteIcon = props => <SvgIcon component={deleteIcon} inheritViewBox {...props} />;
export const DangerIcon = props => <SvgIcon component={dangerIcon} inheritViewBox {...props} />;
export const LockIcon = props => <SvgIcon component={lockIcon} inheritViewBox {...props} />;
export const TicketIcon = props => <SvgIcon component={ticketIcon} inheritViewBox {...props} />;
export const O2ELogo = props => <SvgIcon component={o2eLogo} inheritViewBox {...props} />;
export const PasswordIcon = props => <SvgIcon component={passwordIcon} inheritViewBox {...props} />;
export const DiscountIcon = props => <SvgIcon component={discount} inheritViewBox {...props} />;
export const ScanIcon = props => <SvgIcon component={scanIcon} inheritViewBox {...props} />;
export const ChartIcon = props => <SvgIcon component={chartIcon} inheritViewBox {...props} />;
export const AttachmentIcon = props => <SvgIcon component={attachmentIcon} inheritViewBox {...props} />;
export const NotificationsIcon = props => <SvgIcon component={notificationsIcon} inheritViewBox {...props} />;
export const CancelIcon = props => <SvgIcon component={cancelIcon} inheritViewBox {...props} />;
export const ContactUsIcon = props => <SvgIcon component={contactUsIcon} inheritViewBox {...props} />;
export const JoinUsIcon = props => <SvgIcon component={joinUsIcon} inheritViewBox {...props} />;
export const DownloadQRIcon = props => <SvgIcon component={downloadQRIcon} inheritViewBox {...props} />;
export const CategoryIcon = props => <SvgIcon component={categoryIcon} inheritViewBox {...props} />;
