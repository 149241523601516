import axios from 'axios';
import { createAsyncThunk, isPending } from '@reduxjs/toolkit';

import { createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';

const initialState: EntityState<any> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: null,
  redeemers: [],
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const apiUrl = 'api/join-us';

// Actions

export const getEntities = createAsyncThunk(
  'joinUs/fetch_entity',
  async () => {
    const requestUrl = `${apiUrl}`;
    return axios.get<any>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const JoinUsSlice = createEntitySlice({
  name: 'joinUs',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntities.fulfilled, (state, action) => {
        state.loading = false;
        state.entities = action.payload.data;
      })
      .addMatcher(isPending(getEntities), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      });
  },
});

export const { reset } = JoinUsSlice.actions;

// Reducer
export default JoinUsSlice.reducer;
