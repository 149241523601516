import dayjs from 'dayjs';

export interface IBaseEntity {
  id?: number;
  createdDate?: string | null;
  lastModifiedDate?: string | null;
  createdBy?: string | null;
  lastModifiedBy?: string | null;
}

export const defaultValue: Readonly<IBaseEntity> = {};
