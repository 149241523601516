import dayjs from 'dayjs';

export interface INotification {
  id?: number;
  title?: string | null;
  content?: string | null;
  sendDate?: string | null;
  pushed?: boolean | null;
}

export const defaultValue: Readonly<INotification> = {
  pushed: false,
};
