import { IBusiness } from 'app/shared/model/business.model';
import { IGift } from 'app/shared/model/gift.model';

export interface ILocation {
  id?: number;
  latitude?: string | null;
  longitude?: string | null;
  name?: string | null;
  business?: IBusiness | null;
  gift?: IGift | null;
}

export const defaultValue: Readonly<ILocation> = {};
