let privacy;
export default privacy = `
<!DOCTYPE html>
    <html>
    <head>
      <meta charset='utf-8'>
      <meta name='viewport' content='width=device-width'>
      <title>سياسة الخصوصية</title>
      <style> body { font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; padding:1em;  direction: rtl;   font-size: 25px !important;    } </style>
    </head>
    <body>
    <h2>سياسة الخصوصية</h2> <p> قامت شركة كفاءات بإنشاء تطبيق O2E كتطبيق مجاني.
      </p> <p>وتُستخدم هذه الصفحة لإعلام مستخدمي هذا التطبيق بسياساتنا وما هي المعلومات التي نجمعها إذا قرر أي شخص
استخدام التطبيق الخاص بنا.
</p> <p>إذا اخترت استخدام هذا التطبيق، فأنت توافق على إعطاء لنا الإذن على جمع المعلومات واستخدامها فيما
يتعلق بهذه السياسة. يتم استخدام المعلومات الشخصية التي نقوم بتجميعها لتوفير الخدمة وتحسينها. لن نشارك
معلوماتك مع أي شخص باستثناء ما هو موضح في سياسة الخصوصية هذه.
</p> <p>إن المصطلحات المستخدمة في سياسة الخصوصية هذه لها نفس المعاني كما في الشروط والأحكام الخاصة بنا،
والتي يمكن الوصول إليها في تطبيق كفاءات ما لم يتم تحديد عكس ذلك في سياسة الخصوصية هذه.
</p> <p><strong>جمع المعلومات واستخدامها</strong></p> <p>للحصول على تجربة أفضل، أثناء استخدام خدماتنا، قد نطلب منك تزويدنا بمعلومات تعريف شخصية معينة
, بما في ذلك على سبيل المثال الاسم ، البريد الإلكتروني .. سيتم الاحتفاظ بالمعلومات التي نطلبها منك على جهازك ونحن لا نقوم بتجميعها بأي طريقة.
</p> <p>هذا التطبيق يستعمل خدمات جهات خارجية قد تجمع بعض معلوماتك المستخدمة لتحديد هويتك.</p> <div><p>أدناه روابط سياسة الخصوصية الخاصة بموفري الخدمات الجهات الخارجية المستعملة في هذا التطبيق</p> <ul><li><a href="https://www.google.com/policies/privacy/" target="_blank">Google Play Services</a></li><!----><!----><!----><!----><!----><!----><!----></ul></div> <p><strong>تسجيل البيانات</strong></p> <p> نريد أن نعلمكم أنه عند استخدام هذا التطبيق، في حالة وجود خطأ في التطبيق، نقوم بجمع البيانات
والمعلومات (من خلال منتجات جهات خارجية) على هاتفك وتسمى هذه العملية بتسجيل البيانات. قد تتضمن بيانات
السجل هذه معلومات مثل عنوان بروتوكول الإنترنت (IP) الخاص بجهازك، واسم الجهاز، وإصدار نظام التشغيل،
وتكوين التطبيق عند إستخدامه، ووقت وتاريخ استخدامك التطبيق، وغيرها من الإحصاءات.
</p> <p><strong>ملفات تعريف الارتباط (الكوكيز)</strong></p> <p>ملفات تعريف الارتباط المسمى بالكوكيز (بالإنجليزية: Cookies) عبارة عن ملفات تحتوي على كمية صغيرة من
البيانات التي يتم استخدامها بشكل شائع كمعرّفات فريدة مجهولة الهوية. يتم إرسالها إلى متصفحك من مواقع
الويب التي تزورها ويتم تخزينها على الذاكرة الداخلية لجهازك.
</p> <p>تطبيق كفاءات لا يستخدم "ملفات تعريف الارتباط" بشكل صريح. ومع ذلك، قد يستخدم التطبيق رمزًا أو
خدمة تابعة لجهة خارجية تستخدم "ملفات تعريف الارتباط" لجمع المعلومات وتحسين خدماتها. لديك خيار إما
قبول أو رفض ملفات تعريف الارتباط هذه ومعرفة متى يتم إرسال ملف تعريف الارتباط إلى جهازك. إذا اخترت رفض
ملفات تعريف الارتباط الخاصة بنا، فقد لا تتمكن من استخدام بعض أجزاء هذه الخدمة.
</p> <p><strong>مقدمي الخدمة</strong></p> <p> يجوز لنا توظيف شركات وأفراد من جهات خارجية بسبب الأسباب التالية:</p> <ul><li>لتسهيل خدمتنا التي نقدمها لكم في تطبيقينا;</li> <li>لتقديم الخدمات نيابة عنا;</li> <li>لتنفيذ الخدمات ذات الصلة بالخدمة التي نصومه لكم في تطبيقينا; أو</li> <li>لمساعدتنا في تحليل كيفية استخدام خدمتنا.</li></ul> <p> يجب على مستخدمي التطبيق كفاءات أن يعلموا بأن خادمات الجهات الخارجية المستعملة في هذا التطبيق
يمكنها الوصول إلى معلوماتك الشخصية. السبب هو تنفيذ المهام الموكلة إليهم نيابة عنا. ومع ذلك، فهي ملزمة
بعدم الكشف عن هذه المعلومات أو استخدامها لأي غرض آخر.
</p> <p><strong>الأمان</strong></p> <p> نقدر ثقتكم في تزويدنا بمعلوماتكم الشخصية، لذا فنحن نسير نحو استخدام وسائل مقبولة تجاريًا لحمايتها.
ولكن تذكر أنه لا توجد طريقة للإرسال عبر الإنترنت، أو طريقة التخزين الإلكترونية آمنة وموثوقة بنسبة
100٪ ، ولا يمكنني ضمان أمنها المطلق.
</p> <p><strong>روابط لمواقع أخرى</strong></p> <p>قد يحتوي هذا التطبيق على روابط لمواقع أخرى. إذا نقرت على رابط جهة خارجية، فسيتم توجيهك إلى هذا
الموقع. لاحظ أن هذه المواقع الخارجية لا نديرها. لذلك، أنصحك بشدة مراجعة سياسة الخصوصية الخاصة بهذه
المواقع. ليس لدينا أي سيطرة ولا نتحمل أي مسؤولية عن المحتوى أو سياسات الخصوصية أو الممارسات الخاصة
بأي مواقع أو خدمات خاصة بجهة خارجية موجودة في هذا التطبيق.
</p> <p><strong>خصوصية الأطفال</strong></p> <p>لا نتعامل في هذه الخدمات التي نقدمها في مع أي شخص يقل عمره عن 13 عامًا. نحن لا نقوم بجمع معلومات
تعريف شخصية من الأطفال دون سن 13 عامًا. في حال أكتشفنا أن طفلاً دون سن 13 عامًا قد زودنا بمعلومات
شخصية، نحذفها على الفور من خوادمنا. إذا كنت والدًا أو وصيًا وكنت على دراية بأن طفلك قد زودنا
بمعلوماته الشخصية، فيرجى الاتصال بنا حتى نتمكن من اتخاذ الإجراءات اللازمة.
</p> <p><strong>التغييرات على سياسة الخصوصية هذه</strong></p> <p>قد نقوم بتحديث سياسة الخصوصية الخاصة بنا في أي وقت. وبالتالي، ننصحك بمراجعة هذه الصفحة بشكل دوري
للإطلاع على التغيرات الجديدة في سياستنا. سوف نعلمك بأي تغييرات عن طريق نشر سياسة الخصوصية الجديدة على
هذه الصفحة. هذه التغييرات تكون فعالة فور نشرها على هذه الصفحة.
</p> <p><strong>الإتصال بنا</strong></p> <p>إذا كان لديكم أي أسئلة أو اقتراحات حول سياسة الخصوصية الخاصة بنا، فلا تترددوا بالاتصال بنا.

    </body>
    </html>

`;
