import axios from 'axios';
import { createAsyncThunk, isPending } from '@reduxjs/toolkit';

import { createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';

const initialState: EntityState<any> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: null,
  redeemers: [],
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const apiUrl = 'api/admin/dashboard';

// Actions

export const getEntity = createAsyncThunk(
  'dashboard/fetch_entity',
  async () => {
    const requestUrl = `${apiUrl}`;
    return axios.get<any>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const getRedeemers = createAsyncThunk(
  'dashboard/fetch_redeemers',
  async ({ from, to }: any) => {
    const requestUrl = `${apiUrl}/redeemers?from=${from}&to=${to}`;
    return axios.get<any>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);
// slice

export const DashboardSlice = createEntitySlice({
  name: 'dashboard',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(getRedeemers.fulfilled, (state, action) => {
        state.loading = false;
        state.redeemers = action.payload.data;
      })
      .addMatcher(isPending(getEntity, getRedeemers), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      });
  },
});

export const { reset } = DashboardSlice.actions;

// Reducer
export default DashboardSlice.reducer;
