import { ICompany } from 'app/shared/model/company.model';

export interface ICompanyAdmin {
  id?: number;
  enabled?: boolean | null;
  company?: ICompany | null;
}

export const defaultValue: Readonly<ICompanyAdmin> = {
  enabled: false,
};
