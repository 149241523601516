import axios from 'axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { serializeAxiosError } from 'app/shared/reducers/reducer.utils';

export const handleJoinUs = createAsyncThunk('public/join_us', async (data: any) => axios.post<any>('api/join-us', data), {
  serializeError: serializeAxiosError,
});

export const handleContactUs = createAsyncThunk('public/contact_us', async (data: any) => axios.post<any>('api/contact-us', data), {
  serializeError: serializeAxiosError,
});
