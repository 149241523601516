import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { IUser, defaultValue } from 'app/shared/model/user.model';

const initialState: EntityState<any> = {
  loading: false,
  errorMessage: null,
  offerPriorities: [],
  discountPriorities: [],
  entity: null,
  entities: [],
  updating: false,
  updateSuccess: false,
  error: null,
};

const apiUrl = 'api/offers/';

// Actions
export const getDiscountPriorities = createAsyncThunk('priorities/fetch_discount_priorities', async () => {
  const requestUrl = `${apiUrl}with-priority?type=DISCOUNT`;
  return axios.get<any[]>(requestUrl);
});

export const getOfferPriorities = createAsyncThunk('priorities/fetch_offer_priorities', async () => {
  const requestUrl = `${apiUrl}with-priority?type=OFFER`;
  return axios.get<any[]>(requestUrl);
});

export const getAvailablePriorities = createAsyncThunk('priorities/fetch-available_priorities', async (type: 'DISCOUNT' | 'OFFER') => {
  const requestUrl = `${apiUrl}available-priority?type=${type}`;
  return axios.get<number[]>(requestUrl);
});

export const createEntity = createAsyncThunk(
  'priorities/create_entity',
  async (data: { id: number; priority: number }, thunkAPI) => {
    const result = await axios.post<IUser>(`${apiUrl}${data.id}/priority?priority=${data.priority}`);
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  'priorities/delete_entity',
  async (id: number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await axios.delete(`${apiUrl}${id}/priority`);
    // thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

// slice

export const BusinessSlice = createEntitySlice({
  name: 'priorities',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addMatcher(isRejected(createEntity), (state, action) => {
        state.updating = false;
        state.updateSuccess = false;
        state.error = action.error;
      })
      .addMatcher(isFulfilled(getDiscountPriorities), (state, action) => {
        const { data } = action.payload;

        return {
          ...state,
          loading: false,
          discountPriorities: data,
        };
      })
      .addMatcher(isFulfilled(getOfferPriorities), (state, action) => {
        const { data } = action.payload;

        return {
          ...state,
          loading: false,
          offerPriorities: data,
        };
      })
      .addMatcher(isFulfilled(getAvailablePriorities), (state, action) => {
        const { data } = action.payload;

        return {
          ...state,
          loading: false,
          availablePriorities: data,
        };
      })
      .addMatcher(isFulfilled(createEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isPending(getOfferPriorities, getDiscountPriorities, getAvailablePriorities), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, deleteEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { reset } = BusinessSlice.actions;

// Reducer
export default BusinessSlice.reducer;
