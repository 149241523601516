import { createTheme } from '@mui/material';
import '@fontsource/readex-pro';
import { arSA } from '@mui/material/locale';

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    phone: true;
    largePhone: true;
    tablet: true;
    laptop: true;
  }
}
export const theme = (locale: string) => {
  return createTheme(
    {
      breakpoints: {
        values: {
          xs: 0, // all mobiles
          phone: 360,
          largePhone: 480,
          sm: 600, // same as small-tablet
          tablet: 768,
          md: 1024, // same as large-tablet
          laptop: 1280,
          lg: 1366, // same as large-laptop
          xl: 1600, // same as ultra
        },
      },
      direction: locale === 'ar-ly' ? 'rtl' : 'ltr',
      components: {
        MuiTypography: {
          styleOverrides: {
            root: {
              fontWeight: 200,
            },
          },
        },
        MuiPaper: {
          styleOverrides: {
            root: {
              border: 'solid 1px rgba(0, 0, 0, 0.1)',
              boxShadow: 'none',
            },
          },
        },
        MuiListItemButton: {
          styleOverrides: {
            root: {
              borderRadius: '6px 0px 0px 6px',
              '&.Mui-selected': {
                backgroundColor: '#B18758',
                '&:hover': {
                  backgroundColor: '#B18758',
                  '.MuiListItemIcon-root': {
                    color: '#fff',
                  },
                  '.MuiListItemText-root > p': {
                    color: '#fff',
                  },
                },
                '.MuiListItemIcon-root': {
                  color: '#fff',
                },
                '.MuiListItemText-root > p': {
                  color: '#fff',
                },
              },
            },
          },
        },
        MuiButton: {
          styleOverrides: {
            root: {
              textTransform: 'none',
            },
          },
        },
        MuiAppBar: {
          styleOverrides: {
            root: {
              backgroundColor: '#fff',
              boxShadow: 'none',
              height: '110px',
              borderBottom: '1px solid #E0E0E0',
              marginLeft: '4rem',
            },
          },
        },
        MuiToolbar: {
          styleOverrides: {
            root: {
              color: 'black',
            },
          },
        },
        MuiInputBase: {
          styleOverrides: {
            root: {
              input: {
                '&::placeholder': {
                  color: 'gray',
                },
              },
            },
          },
        },
        MuiFilledInput: {
          styleOverrides: {
            root: {
              backgroundColor: '#E0E0E033',
              ':before': {
                borderBottomColor: '#E0E0E0',
              },
            },
          },
        },
        MuiTablePagination: {
          styleOverrides: {
            root: {
              p: {
                marginBottom: 0,
              },
            },
          },
        },
        MuiFormLabel: {
          styleOverrides: {
            root: {
              fontSize: '14px',
              marginBottom: '8px',
            },
          },
        },
      },
      typography: {
        fontFamily: `'Readex Pro', sans-serif`,
      },
      shadows: [
        'none',
        `0px 0px 2px 0px rgba(0,0,0,0.1)`,
        `0px 0px 4px 0px rgba(0,0,0,0.1)`,
        `0px 0px 6px 0px rgba(0,0,0,0.1)`,
        `0px 0px 8px 0px rgba(0,0,0,0.1)`,
        `0px 0px 10px 0px rgba(0,0,0,0.1)`,
        `0px 0px 12px 0px rgba(0,0,0,0.1)`,
        `0px 0px 14px 0px rgba(0,0,0,0.1)`,
        `0px 0px 16px 0px rgba(0,0,0,0.1)`,
        `0px 0px 18px 0px rgba(0,0,0,0.1)`,
        `0px 0px 20px 0px rgba(0,0,0,0.1)`,
        `0px 0px 22px 0px rgba(0,0,0,0.1)`,
        `0px 0px 24px 0px rgba(0,0,0,0.1)`,
        `0px 0px 26px 0px rgba(0,0,0,0.1)`,
        `0px 0px 28px 0px rgba(0,0,0,0.1)`,
        `0px 0px 30px 0px rgba(0,0,0,0.1)`,
        `0px 0px 32px 0px rgba(0,0,0,0.1)`,
        `0px 0px 34px 0px rgba(0,0,0,0.1)`,
        `0px 0px 36px 0px rgba(0,0,0,0.1)`,
        `0px 0px 38px 0px rgba(0,0,0,0.1)`,
        `0px 0px 40px 0px rgba(0,0,0,0.1)`,
        `0px 0px 42px 0px rgba(0,0,0,0.1)`,
        `0px 0px 44px 0px rgba(0,0,0,0.1)`,
        `0px 0px 46px 0px rgba(0,0,0,0.1)`,
        `0px 0px 48px 0px rgba(0,0,0,0.1)`,
      ],
      palette: {
        primary: {
          main: '#B18758',
          contrastText: '#fff',
          dark: '#37483F',
        },
        secondary: {
          main: '#748E7F',
        },
        info: {
          main: '#37483F',
        },
        background: {
          default: '#FCFCFC',
        },
        text: {
          primary: '#B18758',
          secondary: '#37483F',
        },
        divider: '#E0E0E0',
      },
      spacing: 8,
    },
    arSA
  );
};

// #37483F
// #B18758
// #D4BDAE
// #748E7F
