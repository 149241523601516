import React, { FC } from 'react';
import { Card, Container, Grid, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { translate } from 'react-jhipster';

export const WelcomeToKafaat: FC = () => {
  const theme = useTheme();

  const mdUp = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <div
      style={{
        background: 'var(--welcome-bg)',
        paddingTop: '2rem',
        width: '100%',
        marginBottom: mdUp ? '10rem' : '4rem',
      }}
    >
      <Typography color={'primary'} fontSize={'xx-large'} marginTop={'2rem'} textAlign={'center'}>
        {translate('MENU.ABOUT_O2E')}
      </Typography>
      <Container maxWidth="md">
        <Typography
          px={'2rem'}
          textAlign={'justify'}
          sx={{
            textAlignLast: 'center',
          }}
          color={'gray'}
          mb={'1rem'}
          fontSize={'medium'}
          marginTop={'2rem'}
          alignItems={'center'}
        >
          {translate('MENU.ABOUT_O2E_DETAILS')}
        </Typography>
      </Container>
      <Grid container alignItems={'center'}>
        <Grid
          item
          xs={12}
          md={4}
          display={'flex'}
          height={'100%'}
          flexDirection={'column'}
          gap={'24px'}
          alignItems={'center'}
          padding={'3rem'}
        >
          {[].map(v => {
            return (
              <Card
                key={v}
                sx={{
                  padding: { xs: '1rem', md: '2rem' },
                  boxShadow: 10,
                  border: 'none',
                  borderRadius: '0.5rem',
                }}
              >
                <Typography color={'primary'}>Title</Typography>
                <Typography color={'secondary'} fontSize={'small'}>
                  description description description description description description description
                </Typography>
              </Card>
            );
          })}
        </Grid>

        <Grid item xs={12} md={4} display={'flex'} flexDirection={'column'} gap={'24px'} alignItems={'center'}>
          <img
            style={{ marginBottom: mdUp ? '-7rem' : '', maxWidth: '80vh', objectFit: 'cover' }}
            height={mdUp ? '840px' : '420'}
            src={'content/images/landing/app_mobile.png'}
          />
        </Grid>

        <Grid item xs={12} md={4} display={'flex'} flexDirection={'column'} gap={'24px'} alignItems={'center'} padding={'3rem'}>
          {[].map(v => {
            return (
              <Card
                key={v}
                sx={{
                  padding: { xs: '1rem', md: '2rem' },
                  boxShadow: 10,
                  border: 'none',
                  borderRadius: '0.5rem',
                }}
              >
                <Typography color={'primary'}>Title</Typography>
                <Typography color={'secondary'} fontSize={'small'}>
                  description description description description description description description{' '}
                </Typography>
              </Card>
            );
          })}
        </Grid>
      </Grid>
    </div>
  );
};
