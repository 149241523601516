import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import Business from './business';
import BusinessDetail from './business-detail';

const BusinessRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<Business />} />
    <Route path=":id">
      <Route index element={<BusinessDetail />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default BusinessRoutes;
