import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { translate, Translate } from 'react-jhipster';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { createEntity, getAvailablePriorities } from './priorities.reducer';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  FormControl,
  Divider,
  FormLabel,
  Grid,
  FormHelperText,
  Autocomplete,
  TextField,
} from '@mui/material';
import { Formik } from 'formik';
import useScriptRef from 'app/hooks/useScriptRef';
import { useSnackbar } from 'notistack';
import { getEntity, getSummaryEntities, reset } from 'app/entities/business/business.reducer';
import * as Yup from 'yup';
import { defaultValue } from 'app/shared/model/business.model';

export const PriorityCreate = ({ open, setOpen, type }) => {
  const dispatch = useAppDispatch();
  const scriptedRef = useScriptRef();
  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();

  const availablePriorities = useAppSelector(state => state.priorities.availablePriorities);
  const businesses = useAppSelector(state => state.business.entities);
  const business = useAppSelector(state => state.business.entity);
  const businessesLoading = useAppSelector(state => state.business.loading);

  const loading = useAppSelector(state => state.priorities.loading);
  const updateSuccess = useAppSelector(state => state.priorities.updateSuccess);

  const currentLocale = useAppSelector(state => state.locale.currentLocale);

  const handleClose = () => {
    setOpen(false);
    navigate('/mobile-priorities' + location.search);
  };
  useEffect(() => {
    if (open) {
      dispatch(reset());
      dispatch(getSummaryEntities({}));
      dispatch(getAvailablePriorities(type));
    }
  }, [open]);

  useEffect(() => {
    if (updateSuccess && open) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = async values => {
    const {
      meta: { requestStatus },
    } = await dispatch(createEntity({ id: values.offerId, priority: values.priority }));
    if (requestStatus === 'fulfilled') {
      enqueueSnackbar(translate('kafaatApp.business.priorities.createSuccess'), { variant: 'success' });
    }
    return requestStatus;
  };

  return (
    <Dialog maxWidth={'md'} fullWidth open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title" color={'secondary'}>
        <Translate contentKey="kafaatApp.business.priorities.addOffer">Create new priority</Translate>
      </DialogTitle>
      <Divider />
      <Formik
        initialValues={{
          business: undefined,
          offerId: undefined,
          priority: undefined,
        }}
        validationSchema={Yup.object().shape({
          business: Yup.mixed().required(translate('error.form.required')),
          offerId: Yup.mixed().required(translate('error.form.required')),
          priority: Yup.number().required(translate('error.form.required')),
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            const requestStatus = await saveEntity(values);

            if (scriptedRef.current && requestStatus === 'fulfilled') {
              setStatus({ success: true });
              setSubmitting(false);
              handleClose();
            }
          } catch (err) {
            console.error(err);
            if (scriptedRef.current) {
              setStatus({ success: false });
              setSubmitting(false);
            }
          }
        }}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, setFieldValue, touched, values }) => (
          <form noValidate onSubmit={handleSubmit}>
            <DialogContent>
              <Grid container rowSpacing={1} columnSpacing={6}>
                <Grid item xs={6}>
                  <FormControl fullWidth margin="normal" variant="filled" error={Boolean(touched.business && errors.business)}>
                    <FormLabel>
                      <Translate contentKey="kafaatApp.business.priorities.businessName">businessName </Translate>
                    </FormLabel>
                    <Autocomplete
                      id="business"
                      onBlur={handleBlur}
                      inputMode={'text'}
                      loading={businessesLoading}
                      value={values.business}
                      onChange={(e, value) => {
                        setFieldValue('business', value.id);
                        dispatch(getEntity(value.id));
                      }}
                      getOptionLabel={(option: any) => (currentLocale === 'ar-ly' ? option.name : option.nameEn)}
                      renderInput={params => (
                        <TextField error={Boolean(touched.business && errors.business)} {...params} variant={'filled'} />
                      )}
                      options={businesses}
                    />
                    {touched.business && errors.business && (
                      <FormHelperText error id="standard-weight-helper-text">
                        {errors.business.toString()}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth margin="normal" variant="filled" error={Boolean(touched.offerId && errors.offerId)}>
                    <FormLabel>
                      {type === 'OFFER' ? (
                        <Translate contentKey="kafaatApp.business.priorities.offerTitle">offer Title </Translate>
                      ) : (
                        <Translate contentKey="kafaatApp.business.priorities.discountTitle">discount Title </Translate>
                      )}
                    </FormLabel>
                    <Autocomplete
                      id="offerId"
                      loading={businessesLoading}
                      onBlur={handleBlur}
                      inputMode={'text'}
                      disabled={business === defaultValue}
                      value={values.offerId}
                      onChange={(e, value) => setFieldValue('offerId', value.id)}
                      getOptionLabel={(option: any) => (currentLocale === 'ar-ly' ? option.title : option.titleEn)}
                      renderInput={params => (
                        <TextField error={Boolean(touched.offerId && errors.offerId)} {...params} variant={'filled'} />
                      )}
                      options={business && business?.offers && business?.offers?.filter(i => i.type === type)}
                    />
                    {touched.offerId && errors.offerId && (
                      <FormHelperText error id="standard-weight-helper-text">
                        {errors.offerId.toString()}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth margin="normal" variant="filled" error={Boolean(touched.priority && errors.priority)}>
                    <FormLabel>
                      <Translate contentKey="kafaatApp.business.priorities.priority">offer priority </Translate>
                    </FormLabel>
                    <Autocomplete
                      id="priority"
                      onBlur={handleBlur}
                      inputMode={'text'}
                      value={values.priority}
                      onChange={(e, value) => setFieldValue('priority', value)}
                      renderInput={params => (
                        <TextField error={Boolean(touched.priority && errors.priority)} {...params} variant={'filled'} />
                      )}
                      options={availablePriorities}
                    />
                    {touched.priority && errors.priority && (
                      <FormHelperText error id="standard-weight-helper-text">
                        {errors.priority.toString()}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
              <br /> <br />
            </DialogContent>
            <DialogActions>
              <Button size={'large'} onClick={handleClose} variant="outlined" color={'secondary'}>
                <Translate contentKey="kafaatApp.business.home.cancel">Cancel</Translate>
              </Button>
              <Button size={'large'} color="primary" variant="contained" disabled={isSubmitting} type={'submit'}>
                <Translate contentKey="kafaatApp.business.home.save">Save</Translate>
              </Button>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};

export default PriorityCreate;
