// @ts-ignore
import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { Button, Typography, useMediaQuery } from '@mui/material';
// @ts-ignore
import styles from './NavLinks.module.scss';
import { useTheme } from '@mui/material/styles';
import { MenuItems, MenuLinkI } from 'app/interfaces/menuItems';
import { useLocation, useNavigate } from 'react-router-dom';
import { Language } from 'app/components/PublicLayout/Language';
import { translate } from 'react-jhipster';

const NavLinks: FC<{ handleClick: (item: MenuLinkI) => void }> = ({ handleClick }) => {
  const theme = useTheme();
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  const mdUp = useMediaQuery(theme.breakpoints.up('md'));
  const navigate = useNavigate();
  const { pathname } = useLocation();

  return (
    <div className={styles.list}>
      {MenuItems.map(item => {
        return (
          <div key={item.name} className={'center'}>
            <div onClick={e => handleClick(item)} className={pathname == item.link ? styles.navLinkActive : styles.navLink}>
              {translate(item.name)}
            </div>
          </div>
        );
      })}
      {mdDown && <Language />}
      {mdUp && (
        <div style={{ marginInlineStart: 'auto' }}>
          <Language />
        </div>
      )}
      <div className={styles.center}>
        <Button
          onClick={() => navigate('/login')}
          className={styles.loginButton}
          variant={'contained'}
          color={'primary'}
          sx={{ borderRadius: '50px' }}
        >
          <Typography sx={{ fontSize: '14px', color: 'var(--white)' }}>{translate('MENU.LOGIN')}</Typography>
        </Button>
      </div>
    </div>
  );
};
export { NavLinks };
