import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import React, { FC } from 'react';
import { translate } from 'react-jhipster';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { handleContactUs } from 'app/shared/reducers/landing-page.reducer';
import { useAppDispatch } from 'app/config/store';
import { useSnackbar } from 'notistack';

export const ContactUsDialog: FC<{ open: boolean; setOpen: any }> = ({ open, setOpen }) => {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const handleClose = () => {
    setOpen(false);
  };
  const validateNumberThenChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, handleChange) => {
    if (!isNaN(Number(event.target.value))) {
      handleChange(event);
    }
  };
  const handleContactUsForm = async (values: any) => {
    const {
      meta: { requestStatus },
    } = await dispatch(handleContactUs(values));
    if (requestStatus === 'fulfilled') {
      enqueueSnackbar(translate('CONTACT.SUCCESS'), { variant: 'success' });
      handleClose();
    } else {
      enqueueSnackbar(translate('CONTACT.ERROR'), { variant: 'error' });
    }
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{translate('CONTACT.TITLE')}</DialogTitle>
      <Formik
        initialValues={{
          fullName: '',
          message: '',
          phoneNumber: '',
          email: '',
        }}
        validationSchema={Yup.object().shape({
          fullName: Yup.string().required(translate('error.form.required')),
          message: Yup.string().required(translate('error.form.required')),
          phoneNumber: Yup.number().required(translate('error.form.required')),
          email: Yup.string().required(translate('error.form.required')),
        })}
        onSubmit={values => handleContactUsForm(values)}
      >
        {({ handleSubmit, values, handleChange, isSubmitting, errors, touched }) => (
          <form noValidate onSubmit={handleSubmit}>
            <DialogContent>
              <Grid container columnSpacing={4} mt={2}>
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth margin="none" error={Boolean(touched.fullName && errors.fullName)}>
                    <TextField
                      autoFocus
                      id="fullName"
                      name="fullName"
                      label={translate('CONTACT.FULL_NAME')}
                      type="text"
                      fullWidth
                      value={values.fullName}
                      onChange={handleChange}
                      variant="standard"
                    />
                    {touched.fullName && <FormHelperText error>{errors.fullName}</FormHelperText>}
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth margin="none" error={Boolean(touched.phoneNumber && errors.phoneNumber)}>
                    <TextField
                      id="phoneNumber"
                      name="phoneNumber"
                      InputProps={{ inputProps: { maxLength: 10 } }}
                      label={translate('CONTACT.PHONE_NUMBER')}
                      type="text"
                      value={values.phoneNumber}
                      onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
                        validateNumberThenChange(event, handleChange)
                      }
                      fullWidth
                      variant="standard"
                    />
                    {touched.phoneNumber && <FormHelperText error>{errors.phoneNumber}</FormHelperText>}
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth margin="none" error={Boolean(touched.email && errors.email)}>
                    <TextField
                      id="email"
                      name="email"
                      label={translate('CONTACT.EMAIL')}
                      type="text"
                      value={values.email}
                      onChange={handleChange}
                      fullWidth
                      variant="standard"
                    />
                    {touched.email && <FormHelperText error>{errors.email}</FormHelperText>}
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth margin="none" error={Boolean(touched.message && errors.message)}>
                    <TextField
                      id="message"
                      name="message"
                      onChange={handleChange}
                      value={values.message}
                      label={translate('CONTACT.MESSAGE')}
                      multiline
                      rows={3}
                      fullWidth
                      variant="standard"
                    />
                    {touched.message && <FormHelperText error>{errors.message}</FormHelperText>}
                  </FormControl>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>{translate('CONTACT.CANCEL')}</Button>
              <Button type={'submit'} variant={'contained'} disabled={isSubmitting}>
                {translate('CONTACT.SEND')}
              </Button>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};
