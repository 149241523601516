import { IStorage } from 'app/shared/model/storage.model';
import { ICompanyType } from 'app/shared/model/company-type.model';
import { IBusinessCategory } from 'app/shared/model/business-category.model';
import { ICompanyAdmin } from 'app/shared/model/company-admin.model';

export interface ICompany {
  id?: number;
  name?: string;
  domain?: string;
  enabled?: boolean | null;
  logo?: IStorage | null;
  agreement?: IStorage | null;
  type?: ICompanyType | null;
  category?: IBusinessCategory | null;
  admins?: ICompanyAdmin[] | null;
}

export const defaultValue: Readonly<ICompany> = {
  enabled: false,
};
