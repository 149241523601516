import { IStorage } from 'app/shared/model/storage.model';
import { IContact } from 'app/shared/model/contact.model';
import { ILocation } from 'app/shared/model/location.model';
import { IOffer } from 'app/shared/model/offer.model';

export interface IBusiness {
  id?: number;
  name?: string | null;
  enabled?: boolean | null;
  qrCode?: string | null;
  totalVisited?: number | null;
  agreement?: IStorage | null;
  logo?: IStorage | null;
  contact?: IContact | null;
  locations?: ILocation[] | null;
  offers?: IOffer[] | null;
}

export const defaultValue: Readonly<IBusiness> = {
  enabled: false,
};
