import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Translate, JhiPagination, JhiItemCount, translate } from 'react-jhipster';

import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import { getSortState, overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import AddIcon from '@mui/icons-material/Add';
import { enableDisableEntity, getEntities } from './business.reducer';
import {
  Box,
  Container,
  IconButton,
  Paper,
  Switch,
  Table,
  Button,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Stack,
  Typography,
  Grid,
  TablePagination,
  Tooltip,
  TextField,
  InputAdornment,
} from '@mui/material';
import { DeleteIcon, EditIcon, ShowIcon } from 'app/components/icons';
import BusinessUpdate from 'app/entities/business/business-update';
import BusinessDeleteDialog from 'app/entities/business/business-delete-dialog';
import { useSnackbar } from 'notistack';
import { Spinner } from 'reactstrap';
import SearchIcon from '@mui/icons-material/Search';

export const Business = () => {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const currentLocale = useAppSelector(state => state.locale.currentLocale);
  const [searchName, setSearchName] = useState<string>();

  const location = useLocation();
  const navigate = useNavigate();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(location, rowsPerPage, 'id'), location.search)
  );
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    setPaginationState({
      ...paginationState,
      activePage: newPage,
    });
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setPaginationState({
      ...paginationState,
      itemsPerPage: parseInt(event.target.value, 10),
      activePage: 0,
    });
  };
  const businessList = useAppSelector(state => state.business.entities);
  const loading = useAppSelector(state => state.business.loading);
  const updateSuccess = useAppSelector(state => state.business.updateSuccess);

  const totalItems = useAppSelector(state => state.business.totalItems);
  const [updateBusinessOpen, setUpdateBusinessOpen] = React.useState(false);
  const [updateBusinessId, setUpdateBusinessId] = React.useState(undefined);
  const [deleteBusinessOpen, setDeleteBusinessOpen] = React.useState(false);
  const [deleteBusinessId, setDeleteBusinessId] = React.useState(undefined);
  useEffect(() => {
    if (searchName !== undefined) {
      dispatch(
        getEntities({
          page: paginationState.activePage,
          size: paginationState.itemsPerPage,
          sort: `${paginationState.sort},${paginationState.order}`,
          name: searchName,
        })
      );
    }
  }, [searchName]);
  const getAllEntities = () => {
    dispatch(
      getEntities({
        page: paginationState.activePage,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
      })
    );
  };

  const enableDisable = async (id, enabled) => {
    const {
      meta: { requestStatus },
    } = await dispatch(
      enableDisableEntity({
        id: id,
        enabled: enabled,
      })
    );
    if (requestStatus === 'fulfilled') {
      enqueueSnackbar(translate(enabled ? 'kafaatApp.business.enableSuccess' : 'kafaatApp.business.disableSuccess'), {
        variant: 'success',
      });
    }
  };

  useEffect(() => {
    getAllEntities();
  }, [updateSuccess]);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    paginationState.activePage > 0 ? Math.max(0, (1 + paginationState.activePage) * paginationState.itemsPerPage - businessList.length) : 0;

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (location.search !== endURL) {
      navigate(`${location.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort, paginationState.itemsPerPage]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const paramsPage = params.get('page');
    const sort = params.get(SORT);
    if (paramsPage && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: Number(paramsPage),
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [location.search]);

  return (
    <Container maxWidth="xl">
      <Box mt={2}>
        <Grid container justifyContent={'flex-end'} sx={{ mb: 2 }}>
          <Stack direction={'row'}>
            <form>
              <TextField
                sx={{ mt: 1, mx: 2 }}
                id="search-bar"
                onChange={e => {
                  setSearchName(e.target.value.trim());
                }}
                placeholder={translate('kafaatApp.company.sreachPlaceholder')}
                size="small"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position={'start'}>
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </form>
            <Button
              color="primary"
              size="large"
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => {
                setUpdateBusinessId(undefined);
                setUpdateBusinessOpen(true);
              }}
            >
              <Translate contentKey="kafaatApp.business.home.createLabel">Create new Business</Translate>
            </Button>
          </Stack>
        </Grid>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ borderBottom: 1, borderColor: 'grey.300', padding: '25px 30px' }} width={'50%'}>
                  <Translate contentKey="kafaatApp.business.name">Name</Translate>
                </TableCell>
                <TableCell sx={{ borderBottom: 1, borderColor: 'grey.300', padding: '25px 30px' }} width={'15%'}>
                  <Translate contentKey="kafaatApp.business.logo">Logo</Translate>
                </TableCell>
                <TableCell sx={{ borderBottom: 1, borderColor: 'grey.300', padding: '25px 30px' }} width={'15%'}>
                  <Translate contentKey="kafaatApp.business.enabled">Enabled</Translate>
                </TableCell>
                <TableCell sx={{ borderBottom: 1, borderColor: 'grey.300', padding: '25px 30px' }} width={'20%'}>
                  <Translate contentKey="kafaatApp.business.actions">Actions</Translate>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell colSpan={7} height={'350px'} width={'350px'}>
                    <Box justifyContent={'center'} alignItems={'center'} display={'flex'} width={'100%'} height={'100%'}>
                      <Spinner />
                    </Box>
                  </TableCell>
                </TableRow>
              ) : businessList?.length > 0 ? (
                <>
                  {businessList.map(row => (
                    <TableRow key={row.name}>
                      <TableCell scope="row">
                        <Typography color={'primary.dark'}>{currentLocale === 'ar-ly' ? row.name : row.nameEn}</Typography>
                      </TableCell>
                      <TableCell>{row.logo && <img src={row.logo.filePath} height={50} width={50} />}</TableCell>
                      <TableCell>
                        <Switch
                          color={'secondary'}
                          checked={row.enabled}
                          onChange={() => {
                            enableDisable(row.id, !row.enabled);
                          }}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      </TableCell>
                      <TableCell>
                        <Stack direction="row" spacing={2}>
                          <Tooltip title={<Translate contentKey="entity.action.view">View</Translate>}>
                            <IconButton sx={{ color: 'transparent' }} onClick={() => navigate(`/business/${row.id}`)}>
                              <ShowIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title={<Translate contentKey="entity.action.edit">Edit</Translate>}>
                            <IconButton
                              sx={{ color: 'transparent' }}
                              onClick={() => {
                                setUpdateBusinessId(row.id);
                                setUpdateBusinessOpen(true);
                              }}
                            >
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title={<Translate contentKey="entity.action.delete">Delete</Translate>}>
                            <IconButton
                              sx={{ color: 'transparent' }}
                              onClick={() => {
                                setDeleteBusinessId(row.id);
                                setDeleteBusinessOpen(true);
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  ))}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 2 * emptyRows }}>
                      <TableCell colSpan={4} />
                    </TableRow>
                  )}
                </>
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <Box justifyContent={'center'} flexDirection={'column'} textAlign={'center'} display={'flex'} padding={'50px'}>
                      <Typography variant={'subtitle1'} color={'gray'}>
                        <Translate contentKey={'home.noData'} />
                      </Typography>
                    </Box>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          {totalItems ? (
            <TablePagination
              rowsPerPageOptions={[5, 10, 20]}
              component="div"
              count={totalItems}
              rowsPerPage={rowsPerPage}
              labelRowsPerPage={translate('home.rowsPerPage')}
              labelDisplayedRows={({ from, to, count }) => `${from}–${to}`}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          ) : (
            ''
          )}
        </TableContainer>
      </Box>
      <BusinessUpdate open={updateBusinessOpen} setOpen={setUpdateBusinessOpen} id={updateBusinessId} />
      <BusinessDeleteDialog open={deleteBusinessOpen} setOpen={setDeleteBusinessOpen} id={deleteBusinessId} />
    </Container>
  );
};

export default Business;
