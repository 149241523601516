import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, Grid, TextField } from '@mui/material';
import React, { FC } from 'react';
import { translate } from 'react-jhipster';
// @ts-ignore
import classes from './JoinUsDialog.module.scss';
import { handleJoinUs } from 'app/shared/reducers/landing-page.reducer';
import { useAppDispatch } from 'app/config/store';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';
import { Formik } from 'formik';

export const JoinUsDialog: FC<{ open: boolean; setOpen: any }> = ({ open, setOpen }) => {
  const dispatch = useAppDispatch();

  const handleClose = () => {
    setOpen(false);
  };

  const { enqueueSnackbar } = useSnackbar();
  const handleJoinUsForm = async (values: any) => {
    const {
      meta: { requestStatus },
    } = await dispatch(handleJoinUs(values));
    if (requestStatus === 'fulfilled') {
      enqueueSnackbar(translate('JOIN.SUCCESS'), { variant: 'success' });
      handleClose();
    } else {
      enqueueSnackbar(translate('JOIN.ERROR'), { variant: 'error' });
    }
  };
  const validateNumberThenChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, handleChange) => {
    if (!isNaN(Number(event.target.value))) {
      handleChange(event);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{translate('JOIN.MODAL_TITLE')}</DialogTitle>
      <Formik
        initialValues={{
          entityName: '',
          requesterName: '',
          numOfEmployees: '',
          phoneNumber: '',
          email: '',
          note: '',
        }}
        validationSchema={Yup.object().shape({
          entityName: Yup.string().required(translate('error.form.required')),
          requesterName: Yup.string().required(translate('error.form.required')),
          numOfEmployees: Yup.number().required(translate('error.form.required')),
          phoneNumber: Yup.number().required(translate('error.form.required')),
          email: Yup.number().required(translate('error.form.required')),
        })}
        onSubmit={values => handleJoinUsForm(values)}
      >
        {({ handleSubmit, values, handleChange, isSubmitting, errors, touched }) => (
          <form noValidate onSubmit={handleSubmit}>
            <DialogContent>
              <Grid container columnSpacing={4} mt={2}>
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth margin="none" error={Boolean(touched.entityName && errors.entityName)}>
                    <TextField
                      autoFocus
                      id="entityName"
                      name="entityName"
                      label={translate('JOIN.ENTITY_NAME')}
                      type="text"
                      fullWidth
                      value={values.entityName}
                      onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => handleChange(event)}
                      variant="standard"
                    />
                    {touched.entityName && <FormHelperText error>{errors.entityName}</FormHelperText>}
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth margin="none" error={Boolean(touched.requesterName && errors.requesterName)}>
                    <TextField
                      id="requesterName"
                      name="requesterName"
                      label={translate('JOIN.REQUESTER_NAME')}
                      type="text"
                      fullWidth
                      onChange={handleChange}
                      value={values.requesterName}
                      variant="standard"
                    />

                    {touched.requesterName && <FormHelperText error>{errors.requesterName}</FormHelperText>}
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth margin="none" error={Boolean(touched.numOfEmployees && errors.numOfEmployees)}>
                    <TextField
                      id="numOfEmployees"
                      name="numOfEmployees"
                      onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
                        validateNumberThenChange(event, handleChange)
                      }
                      value={values.numOfEmployees}
                      className={classes.noArrowsInput}
                      label={translate('JOIN.NUMBER_OF_EMPLOYEES')}
                      type="text"
                      fullWidth
                      variant="standard"
                    />
                    {touched.numOfEmployees && <FormHelperText error>{errors.numOfEmployees}</FormHelperText>}
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth margin="none" error={Boolean(touched.phoneNumber && errors.phoneNumber)}>
                    <TextField
                      id="phoneNumber"
                      name="phoneNumber"
                      InputProps={{ inputProps: { maxLength: 10 } }}
                      label={translate('JOIN.PHONE_NUMBER')}
                      type="text"
                      value={values.phoneNumber}
                      onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
                        validateNumberThenChange(event, handleChange)
                      }
                      fullWidth
                      variant="standard"
                    />
                    {touched.phoneNumber && <FormHelperText error>{errors.phoneNumber}</FormHelperText>}
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth margin="none" error={Boolean(touched.email && errors.email)}>
                    <TextField
                      id="email"
                      name="email"
                      label={translate('JOIN.EMAIL')}
                      type="text"
                      value={values.email}
                      onChange={handleChange}
                      fullWidth
                      variant="standard"
                    />
                    {touched.email && <FormHelperText error>{errors.email}</FormHelperText>}
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <FormControl fullWidth margin="none">
                    <TextField
                      id="note"
                      name="note"
                      onChange={handleChange}
                      value={values.note}
                      label={translate('JOIN.NOTE')}
                      multiline
                      rows={3}
                      fullWidth
                      variant="standard"
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>{translate('JOIN.CANCEL')}</Button>
              <Button type={'submit'} variant={'contained'} disabled={isSubmitting}>
                {translate('JOIN.TITLE')}
              </Button>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};
