import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';
import Entities from 'app/entities/company/entities';
import EntityOverview from 'app/entities/company/entity-overview';

const EntitiesRoutes = () => {
  return (
    <div>
      <ErrorBoundaryRoutes>
        <Route index element={<Entities />} />
        <Route path=":id" element={<EntityOverview />} />
      </ErrorBoundaryRoutes>
    </div>
  );
};

export default EntitiesRoutes;
