import React, { FC } from 'react';
import { Container, Grid, Typography } from '@mui/material';
import { translate } from 'react-jhipster';

interface IFeatureCard {
  id: number;
  icon?: any;
  title: string;
  description?: string;
  content: string;
}

const featureCards: IFeatureCard[] = [
  {
    id: 1,
    title: 'MENU.WELCOME.OFFERS.TITLE',
    icon: <img src={'content/images/landing/discount_offers.png'} />,
    content: 'MENU.WELCOME.OFFERS.DESCRIPTION',
  },
  {
    id: 2,
    title: 'MENU.WELCOME.REWARDS.TITLE',
    icon: <img src={'content/images/landing/rewards_gifts.png'} />,
    content: 'MENU.WELCOME.REWARDS.DESCRIPTION',
  },
  {
    id: 3,
    title: 'MENU.WELCOME.AVAILABLE.TITLE',
    icon: <img height={'100px'} src={'content/images/landing/portal.png'} />,
    content: 'MENU.WELCOME.AVAILABLE.DESCRIPTION',
  },
];
export const FeatureCards: FC = () => {
  return (
    <div>
      <Typography color={'primary'} fontSize={'xx-large'} marginTop={'2rem'} textAlign={'center'}>
        {translate('MENU.FEATURES')}
      </Typography>
      <Typography textAlign={'center'} color={'gray'} mb={'1rem'} fontSize={'large'}>
        {translate('MENU.IMPROVE_LOYALTY')}
      </Typography>
      <Grid container>
        {featureCards.map(n => {
          return (
            <Grid item key={n.id} xs={12} sm={12} lg={12} padding={'1rem'} textAlign={'center'} mt={'2rem'}>
              {n.icon && n.icon}
              <Typography fontSize={'large'} mt={'1rem'} color={'primary'}>
                {translate(n.title)}
              </Typography>
              <Container maxWidth="sm">
                <Typography fontSize={'medium'}>{translate(n.content)}</Typography>
              </Container>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};
