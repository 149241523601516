import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { translate, Translate } from 'react-jhipster';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntity, updateEntity, createEntity, reset } from '../employee/employee.reducer';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  FilledInput,
  FormControl,
  Typography,
  Divider,
  FormLabel,
  Select,
  MenuItem,
  Grid,
  FormHelperText,
} from '@mui/material';
import { FieldArray, Formik } from 'formik';
import useScriptRef from 'app/hooks/useScriptRef';
import { useSnackbar } from 'notistack';
import { getEntities as getCompanies } from 'app/entities/company/company.reducer';
import * as Yup from 'yup';
import { PHONE_REGEX } from 'app/config/constants';
import { LoadingButton } from '@mui/lab';

export const AdminUpdate = ({ open, setOpen, id }) => {
  const dispatch = useAppDispatch();
  const scriptedRef = useScriptRef();
  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();

  const isNew = id === undefined;
  const employeeEntity = useAppSelector(state => state.employee.entity);
  const loading = useAppSelector(state => state.employee.loading);
  const updating = useAppSelector(state => state.employee.updating);
  const updateSuccess = useAppSelector(state => state.employee.updateSuccess);

  const handleClose = () => {
    setOpen(false);
    navigate('/settings' + location.search);
  };

  useEffect(() => {
    if (!isNew) {
      dispatch(getEntity(id));
    }
  }, [open]);

  useEffect(() => {
    if (updateSuccess && open) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = async values => {
    values.login = values.email;
    const entity = {
      ...employeeEntity,
      ...values,
    };
    if (isNew) {
      values.authorities = ['ROLE_ADMIN'];
      const {
        meta: { requestStatus },
      } = await dispatch(createEntity(values));
      if (requestStatus === 'fulfilled') {
        enqueueSnackbar(translate('settings.createAdminSuccess'), { variant: 'success' });
      } else {
        enqueueSnackbar(translate('kafaatApp.employee.emailExist'), { variant: 'error' });
      }
    } else {
      const {
        meta: { requestStatus },
      } = await dispatch(updateEntity(entity));
      if (requestStatus === 'fulfilled') {
        enqueueSnackbar(translate('settings.updateAdminSuccess'), { variant: 'success' });
      } else {
        enqueueSnackbar(translate('kafaatApp.employee.emailExist'), { variant: 'error' });
      }
    }
  };

  return (
    <Dialog maxWidth={'md'} fullWidth open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title" color={'secondary'}>
        {isNew ? (
          <Translate contentKey="settings.createAdmin">Create new employee</Translate>
        ) : (
          <Translate contentKey="settings.editAdmin">Edit employee</Translate>
        )}
      </DialogTitle>
      <Divider />
      {(isNew || (!isNew && !loading)) && (
        <Formik
          initialValues={
            isNew
              ? {
                  firstName: undefined,
                  lastName: undefined,
                  phoneNumber: undefined,
                  email: undefined,
                  password: undefined,
                }
              : {
                  ...employeeEntity,
                }
          }
          validationSchema={Yup.object().shape({
            firstName: Yup.string().max(255).required(translate('error.form.required')),
            lastName: Yup.string().max(255).required(translate('error.form.required')),
            phoneNumber: Yup.string()
              .trim()
              .matches(PHONE_REGEX, translate('error.form.invalidPhone'))
              .required(translate('error.form.required')),
            email: Yup.string().email(translate('error.form.invalidEmail')).required(translate('error.form.required')),
            password: Yup.string().max(255).required(translate('error.form.required')),
          })}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
              await saveEntity(values);

              if (scriptedRef.current) {
                setStatus({ success: true });
                setSubmitting(false);
                handleClose();
              }
            } catch (err) {
              console.error(err);
              if (scriptedRef.current) {
                setStatus({ success: false });
                setSubmitting(false);
              }
            }
          }}
        >
          {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, setFieldValue, touched, values }) => (
            <form noValidate onSubmit={handleSubmit}>
              <DialogContent>
                <Grid container rowSpacing={1} columnSpacing={6}>
                  <Grid item xs={6}>
                    <FormControl fullWidth margin="normal" error={Boolean(touched.firstName && errors.firstName)}>
                      <FormLabel>
                        <Translate contentKey="kafaatApp.employee.firstName">Name </Translate>
                      </FormLabel>
                      <FilledInput
                        id="firstName"
                        type={'text'}
                        name="firstName"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.firstName}
                      />
                      {touched.firstName && errors.firstName && (
                        <FormHelperText error id="standard-weight-helper-text">
                          {errors.firstName.toString()}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth margin="normal" error={Boolean(touched.lastName && errors.lastName)}>
                      <FormLabel>
                        <Translate contentKey="kafaatApp.employee.lastName">Name </Translate>
                      </FormLabel>
                      <FilledInput
                        id="lastName"
                        type={'text'}
                        name="lastName"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.lastName}
                      />
                      {touched.lastName && errors.lastName && (
                        <FormHelperText error id="standard-weight-helper-text">
                          {errors.lastName.toString()}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  {isNew && (
                    <Grid item xs={6}>
                      <FormControl fullWidth margin="normal" error={Boolean(touched.password && errors.password)}>
                        <FormLabel>
                          <Translate contentKey="kafaatApp.employee.password">Password </Translate>
                        </FormLabel>
                        <FilledInput
                          id="employee-password"
                          type={'text'}
                          name="password"
                          value={values.password}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                        {touched.password && errors.password && (
                          <FormHelperText error id="standard-weight-helper-text">
                            {errors.password.toString()}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  )}
                  <Grid item xs={6}>
                    <FormControl fullWidth margin="normal" error={Boolean(touched.email && errors.email)}>
                      <FormLabel>
                        <Translate contentKey="kafaatApp.employee.email">Email </Translate>
                      </FormLabel>
                      <FilledInput
                        id="employee-email"
                        type={'text'}
                        name="email"
                        value={values.email}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      {touched.email && errors.email && (
                        <FormHelperText error id="standard-weight-helper-text">
                          {errors.email.toString()}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth margin="normal" error={Boolean(touched.phoneNumber && errors.phoneNumber)}>
                      <FormLabel>
                        <Translate contentKey="kafaatApp.employee.phoneNumber">phoneNumber </Translate>
                      </FormLabel>
                      <FilledInput
                        id="employee-phoneNumber"
                        type={'text'}
                        name="phoneNumber"
                        value={values.phoneNumber}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      {touched.phoneNumber && errors.phoneNumber && (
                        <FormHelperText error id="standard-weight-helper-text">
                          {errors.phoneNumber.toString()}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
                <br /> <br />
              </DialogContent>
              <DialogActions>
                <Button size={'large'} onClick={handleClose} variant="outlined" color={'secondary'}>
                  <Translate contentKey="kafaatApp.business.home.cancel">Cancel</Translate>
                </Button>
                <LoadingButton size={'large'} color="primary" variant="contained" loading={isSubmitting} type={'submit'}>
                  <Translate contentKey="kafaatApp.business.home.save">Save</Translate>
                </LoadingButton>
              </DialogActions>
            </form>
          )}
        </Formik>
      )}
    </Dialog>
  );
};

export default AdminUpdate;
