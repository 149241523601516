import React from 'react';
import { Typography, useMediaQuery, useTheme } from '@mui/material';
import { O2ELogo } from 'app/components/icons';
import { translate } from 'react-jhipster';
import { MenuItems } from 'app/interfaces/menuItems';
import { useNavigate } from 'react-router-dom';

export const LandingPageFooter = ({ handleClick }) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const mdUp = useMediaQuery(theme.breakpoints.up('md'));
  const smUp = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <div style={{ width: '100%' }}>
      <div
        style={{
          background: 'var(--light-bg)',
          width: '100%',
          display: 'flex',
          color: 'var(--secondary)',
          padding: mdUp ? '2rem 3rem' : '1rem',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: smUp ? 'row' : 'column',
            gap: '2rem',
            paddingInline: '2rem',
            alignItems: 'center',
          }}
        >
          <div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
            <O2ELogo style={{ width: '80px', height: '80px', margin: '5px', marginBottom: '2rem' }} />
          </div>
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              paddingInline: smUp ? '2rem' : '1rem',
              gap: '1rem',
              justifyContent: smUp ? 'start' : 'center',
            }}
          >
            {MenuItems.map(item => {
              return (
                <Typography key={item.name} onClick={() => handleClick(item)} style={{ cursor: 'pointer' }} fontSize={'medium'}>
                  {translate(item.name)}
                </Typography>
              );
            })}
          </div>
        </div>
      </div>
      <div
        style={{
          background: 'var(--primary)',
          width: '100%',
          display: 'flex',
          flexDirection: smUp ? 'row' : 'column',
          justifyContent: 'space-between',
          color: 'var(--white)',
          padding: mdUp ? '2rem 3rem' : '1rem',
          alignItems: 'center',
          gap: '1rem',
        }}
      >
        {/* <div style={{ cursor: 'pointer' }} onClick={() => navigate('/terms')}> */}
        {/*   {translate('MENU.FOOTER.TERMS')} */}
        {/* </div> */}
        <div>{translate('MENU.FOOTER.COPYRIGHT')}</div>
        <div>
          {translate('MENU.FOOTER.POWERED_BY')}
          <a href={'https://kbs.sa/'} style={{ margin: '5px' }}>
            <img src={'content/images/landing/kafaat_logo.png'} width={'80px'} />
          </a>
        </div>
      </div>
    </div>
  );
};
