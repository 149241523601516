import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';
import MobilePriorities from 'app/entities/mobile-priorities/mobile-priorities';

const MobilePrioritiesRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<MobilePriorities />} />
  </ErrorBoundaryRoutes>
);

export default MobilePrioritiesRoutes;
