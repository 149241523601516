import React, { useEffect } from 'react';
import { Translate } from 'react-jhipster';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { Box, Container, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { getEntities } from './contactUs.reducer';
import { Spinner } from 'reactstrap';

export const ContactUs = () => {
  const dispatch = useAppDispatch();

  const list = useAppSelector(state => state.contactUs.entities);
  const loading = useAppSelector(state => state.contactUs.loading);

  const getAllEntities = () => {
    dispatch(getEntities());
  };
  useEffect(() => {
    getAllEntities();
  }, []);

  return (
    <Container maxWidth="xl">
      <Box mt={2}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ borderBottom: 1, borderColor: 'grey.300', padding: '25px 30px' }} width={'30%'}>
                  <Translate contentKey="home.contactUs.fullName">fullname</Translate>
                </TableCell>
                <TableCell sx={{ borderBottom: 1, borderColor: 'grey.300', padding: '25px 30px' }} width={'20%'}>
                  <Translate contentKey="home.contactUs.email">email</Translate>
                </TableCell>
                <TableCell sx={{ borderBottom: 1, borderColor: 'grey.300', padding: '25px 30px' }} width={'20%'}>
                  <Translate contentKey="home.contactUs.phoneNumber">phoneNumber</Translate>
                </TableCell>
                <TableCell sx={{ borderBottom: 1, borderColor: 'grey.300', padding: '25px 30px' }} width={'30%'}>
                  <Translate contentKey="home.contactUs.message">message</Translate>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell colSpan={7} height={'350px'} width={'350px'}>
                    <Box justifyContent={'center'} alignItems={'center'} display={'flex'} width={'100%'} height={'100%'}>
                      <Spinner />
                    </Box>
                  </TableCell>
                </TableRow>
              ) : list && list?.length > 0 ? (
                <>
                  {list &&
                    list?.map(row => (
                      <TableRow key={row.id}>
                        <TableCell>
                          <Typography color={'primary.dark'}>{row.fullName}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography color={'primary.dark'}>{row.email}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography color={'primary.dark'}>{row.phoneNumber}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography color={'primary.dark'}>{row.message}</Typography>
                        </TableCell>
                      </TableRow>
                    ))}
                </>
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <Box justifyContent={'center'} flexDirection={'column'} textAlign={'center'} display={'flex'} padding={'50px'}>
                      <Typography variant={'subtitle1'} color={'gray'}>
                        <Translate contentKey={'home.noData'} />
                      </Typography>
                    </Box>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Container>
  );
};

export default ContactUs;
