import React, { useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Translate, translate } from 'react-jhipster';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getRedeemsLogs } from './company.reducer';

import {
  Box,
  Container,
  Paper,
  Table,
  Button,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Link,
  TablePagination,
} from '@mui/material';
import EmployeeUpdate from 'app/entities/employee/employee-update';
import { Spinner } from 'reactstrap';
import { getSortState, overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';

export const RedeemsLogs = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams<'id'>();
  const totalItems = useAppSelector(state => state.company.totalredeemsLogs);
  const location = useLocation();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(location, rowsPerPage, 'id'), location.search)
  );
  const redeemsLogs = useAppSelector(state => state.company.redeemsLogs);
  const loading = useAppSelector(state => state.company.loading);

  const [updateEmployeeOpen, setUpdateEmployeeOpen] = React.useState(false);
  const [viewMode, setViewMode] = React.useState(false);
  const [updateEmployeeId, setUpdateEmployeeId] = React.useState(undefined);

  const getAllEntities = () => {
    dispatch(
      getRedeemsLogs({
        id,
        page: paginationState.activePage,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
      })
    );
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    setPaginationState({
      ...paginationState,
      activePage: newPage,
    });
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setPaginationState({
      ...paginationState,
      itemsPerPage: parseInt(event.target.value, 10),
      activePage: 0,
    });
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    paginationState.activePage > 0 ? Math.max(0, (1 + paginationState.activePage) * paginationState.itemsPerPage - redeemsLogs.length) : 0;

  const sortEntities = () => {
    getAllEntities();
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort, paginationState.itemsPerPage]);

  return (
    <Container maxWidth="xl">
      <Box mt={2}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ borderBottom: 1, borderColor: 'grey.300', padding: '25px 30px' }} width={'20%'}>
                  <Translate contentKey="kafaatApp.employee.name">Name</Translate>
                </TableCell>
                <TableCell sx={{ borderBottom: 1, borderColor: 'grey.300', padding: '25px 30px' }} width={'10%'}>
                  <Translate contentKey="kafaatApp.business.totalRedeems">total Redeems</Translate>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell colSpan={7} height={'350px'} width={'350px'}>
                    <Box justifyContent={'center'} alignItems={'center'} display={'flex'} width={'100%'} height={'100%'}>
                      <Spinner />
                    </Box>
                  </TableCell>
                </TableRow>
              ) : redeemsLogs?.length > 0 ? (
                <>
                  {redeemsLogs.map(row => (
                    <TableRow key={row.name}>
                      <TableCell scope="row">
                        <Link
                          component="button"
                          variant="body2"
                          color={'primary.dark'}
                          onClick={() => {
                            setUpdateEmployeeId(row.email);
                            setViewMode(true);
                            setUpdateEmployeeOpen(true);
                          }}
                        >
                          <Typography color={'primary.dark'}>{`${row.firstName} ${row.lastName}`}</Typography>
                        </Link>
                        <Typography color={'gray'}>{row.email}</Typography>
                      </TableCell>
                      <TableCell scope="row">
                        <Typography color={'primary.dark'}>{row.totalRedeems}</Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 2 * emptyRows }}>
                      <TableCell colSpan={4} />
                    </TableRow>
                  )}
                </>
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <Box justifyContent={'center'} flexDirection={'column'} textAlign={'center'} display={'flex'} padding={'50px'}>
                      <Typography variant={'subtitle1'} color={'gray'}>
                        <Translate contentKey={'home.noData'} />
                      </Typography>
                    </Box>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          {totalItems ? (
            <TablePagination
              rowsPerPageOptions={[5, 10, 20]}
              component="div"
              count={totalItems}
              rowsPerPage={rowsPerPage}
              labelRowsPerPage={translate('home.rowsPerPage')}
              labelDisplayedRows={({ from, to, count }) => `${from}–${to}`}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          ) : (
            ''
          )}
        </TableContainer>
      </Box>
      <EmployeeUpdate open={updateEmployeeOpen} setOpen={setUpdateEmployeeOpen} id={updateEmployeeId} viewMode={viewMode} />
    </Container>
  );
};

export default RedeemsLogs;
