import storage from 'app/entities/storage/storage.reducer';
import companyType from 'app/entities/company-type/company-type.reducer';
import company from 'app/entities/company/company.reducer';
import userProfile from 'app/entities/user-profile/user-profile.reducer';
import role from 'app/entities/role/role.reducer';
import permission from 'app/entities/permission/permission.reducer';
import baseEntity from 'app/entities/base-entity/base-entity.reducer';
import companyAdmin from 'app/entities/company-admin/company-admin.reducer';
import businessCategory from 'app/entities/business-category/business-category.reducer';
import business from 'app/entities/business/business.reducer';
import location from 'app/entities/location/location.reducer';
import contact from 'app/entities/contact/contact.reducer';
import offer from 'app/entities/business/offer.reducer';
import bookmark from 'app/entities/bookmark/bookmark.reducer';
import gift from 'app/entities/gift/gift.reducer';
import offerLog from 'app/entities/offer-log/offer-log.reducer';
import notification from 'app/entities/notification/notification.reducer';
import employee from 'app/entities/employee/employee.reducer';
import dashboard from 'app/modules/home/dashboard.reducer';
import reports from 'app/modules/reports/reports.reducer';
import admins from 'app/entities/settings/admins.reducer';
import joinUs from 'app/modules/join-us/joinUs.reducer';
import contactUs from 'app/modules/contact-us/contactUs.reducer';
import priorities from 'app/entities/mobile-priorities/priorities.reducer';

/* jhipster-needle-add-reducer-import - JHipster will add reducer here */

const entitiesReducers = {
  storage,
  companyType,
  company,
  userProfile,
  role,
  permission,
  baseEntity,
  companyAdmin,
  businessCategory,
  business,
  employee,
  location,
  contact,
  offer,
  bookmark,
  gift,
  offerLog,
  notification,
  dashboard,
  reports,
  admins,
  joinUs,
  contactUs,
  priorities,
  /* jhipster-needle-add-reducer-combine - JHipster will add reducer here */
};

export default entitiesReducers;
