import React, { FC } from 'react';
import { FeatureCards } from 'app/components/PublicLayout/FeatureCards';
import { Button, Container, Typography, useMediaQuery } from '@mui/material';
import { AboutKafaat } from 'app/components/PublicLayout/AboutKafaat';

// @ts-ignore
import styles from './LandingPage.module.scss';
import { WelcomeToKafaat } from 'app/components/PublicLayout/WelcomeToKafaat';
import { useTheme } from '@mui/material/styles';
import { LandingPageFooter } from 'app/components/PublicLayout/LandingPageFooter';
import { DownloadAppCard } from 'app/components/PublicLayout/DownloadAppCard';
import { Partners } from 'app/components/PublicLayout/Partners';
import { translate } from 'react-jhipster';

const LandingPage: FC = () => {
  const theme = useTheme();
  const lgUp = useMediaQuery(theme.breakpoints.up('lg'));
  const mdUp = useMediaQuery(theme.breakpoints.up('md'));
  const smUp = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <div className={styles.pagePadding}>
      <div
        id={'/'}
        className={styles.minusPadding}
        style={{
          background: 'var(--light-bg)',
          paddingInline: '1rem',
          height: mdUp ? '80vh' : '60vh',
          position: 'relative',
          overflow: 'hidden',
        }}
      >
        <div
          style={{
            position: 'absolute',
            inset: 0,
            display: 'flex',
            flexDirection: 'column',
            paddingInline: '1rem',
            alignContent: 'center',
            justifyContent: 'start',
            overflow: 'hidden',
            textAlign: 'center',
            gap: '24px',
            paddingTop: smUp ? '2rem' : 0,
          }}
        >
          <Container sx={{ maxWidth: '580px !important' }}>
            <Typography zIndex={2} mb={1} color={'primary'} fontSize={'xx-large'}>
              {translate('MENU.WELCOME.TITLE')}
            </Typography>
            <Typography
              sx={{
                zIndex: 2,
                textAlign: 'justify',
                textAlignLast: 'center',
              }}
            >
              {translate('MENU.WELCOME.DESCRIPTION')}
            </Typography>
          </Container>
          <img
            height={417}
            style={{
              margin: 'auto',
              position: 'absolute',
              bottom: lgUp ? 0 : '-60px',
              left: 0,
              right: 0,
              maxHeight: '40vh',
              maxWidth: '90vw',
              marginTop: 'auto',
              objectFit: 'contain',
              zIndex: 1,
            }}
            src={'content/images/landing/app_intro.png'}
          />
          <img
            style={{ position: 'absolute', bottom: 0, marginInline: '-10px', width: 'calc(100% + 20px)', zIndex: 2 }}
            src={'content/images/landing/landing-clip-path.png'}
          />
          {mdUp && (
            <>
              <img
                style={{
                  position: 'absolute',
                  inset: 0,
                  marginInlineStart: 'auto',
                  width: '305ox',
                  height: '174px',
                  marginInlineEnd: '130px',
                  marginTop: 'auto',
                  marginBottom: '200px',
                  zIndex: 0,
                }}
                src={'content/images/landing/Group 18854@2x.png'}
              />
              <img
                style={{
                  position: 'absolute',
                  inset: 0,
                  marginInlineEnd: 'auto',
                  width: '100ox',
                  height: '55px',
                  marginInlineStart: '450px',
                  marginTop: 'auto',
                  marginBottom: '200px',
                  zIndex: 0,
                }}
                src={'content/images/landing/Group 18854@2x.png'}
              />
              <img
                style={{
                  position: 'absolute',
                  inset: 0,
                  marginInlineEnd: 'auto',
                  width: '305ox',
                  height: '174px',
                  marginInlineStart: '130px',
                  marginTop: 'auto',
                  marginBottom: '240px',
                  zIndex: 0,
                }}
                src={'content/images/landing/Group 18854@2x.png'}
              />
            </>
          )}
        </div>
      </div>
      <div id="about" style={{ marginTop: '2rem' }} className={styles.minusPadding}>
        <WelcomeToKafaat />
      </div>
      <div style={{ marginBottom: '3rem' }}>
        <DownloadAppCard />
      </div>
      <div id="features">
        <FeatureCards />
      </div>
      <Partners />
    </div>
  );
};

export { LandingPage };
