import React, { useEffect } from 'react';
import { Translate } from 'react-jhipster';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { Box, Container, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { getEntities } from './joinUs.reducer';
import { Spinner } from 'reactstrap';

export const JoinUs = () => {
  const dispatch = useAppDispatch();

  const list = useAppSelector(state => state.joinUs.entities);
  const loading = useAppSelector(state => state.joinUs.loading);

  const getAllEntities = () => {
    dispatch(getEntities());
  };

  useEffect(() => {
    getAllEntities();
  }, []);

  return (
    <Container maxWidth="xl">
      <Box mt={2}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ borderBottom: 1, borderColor: 'grey.300', padding: '25px 30px' }} width={'30%'}>
                  <Translate contentKey="home.joinUs.requesterName">requesterName</Translate>
                </TableCell>
                <TableCell sx={{ borderBottom: 1, borderColor: 'grey.300', padding: '25px 30px' }} width={'20%'}>
                  <Translate contentKey="home.joinUs.phoneNumber">phoneNumber</Translate>
                </TableCell>
                <TableCell sx={{ borderBottom: 1, borderColor: 'grey.300', padding: '25px 30px' }} width={'20%'}>
                  <Translate contentKey="home.joinUs.entityName">entityName</Translate>
                </TableCell>
                <TableCell sx={{ borderBottom: 1, borderColor: 'grey.300', padding: '25px 30px' }} width={'10%'}>
                  <Translate contentKey="home.joinUs.numOfEmployees">numOfEmployees</Translate>
                </TableCell>
                <TableCell sx={{ borderBottom: 1, borderColor: 'grey.300', padding: '25px 30px' }} width={'20%'}>
                  <Translate contentKey="home.joinUs.note">note</Translate>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell colSpan={7} height={'350px'} width={'350px'}>
                    <Box justifyContent={'center'} alignItems={'center'} display={'flex'} width={'100%'} height={'100%'}>
                      <Spinner />
                    </Box>
                  </TableCell>
                </TableRow>
              ) : list?.length > 0 ? (
                <>
                  {list?.map(row => (
                    <TableRow key={row.id}>
                      <TableCell>
                        <Typography color={'primary.dark'}>{row.requesterName}</Typography>
                        <Typography color={'gray'}>{row.email}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography color={'primary.dark'}>{row.phoneNumber}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography color={'primary.dark'}>{row.entityName}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography color={'primary.dark'}>{row.numOfEmployees}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography color={'primary.dark'}>{row.note}</Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                </>
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <Box justifyContent={'center'} flexDirection={'column'} textAlign={'center'} display={'flex'} padding={'50px'}>
                      <Typography variant={'subtitle1'} color={'gray'}>
                        <Translate contentKey={'home.noData'} />
                      </Typography>
                    </Box>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Container>
  );
};

export default JoinUs;
