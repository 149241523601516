import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import Employees from './employees';

const EmployeeRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<Employees />} />
  </ErrorBoundaryRoutes>
);

export default EmployeeRoutes;
