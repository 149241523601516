import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Translate } from 'react-jhipster';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntity } from './company.reducer';
import { Autocomplete, Button, FilledInput, FormControl, FormLabel, Grid, Paper, Stack, TextField, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { BuyIcon, DiscountIcon, EditIcon, ScanIcon, TicketIcon, UserIcon, WorkIcon } from 'app/components/icons';
import Box from '@mui/material/Box';
import DownloadIcon from '@mui/icons-material/Download';
import { Spinner } from 'reactstrap';
import CompanyUpdate from 'app/entities/company/company-update';
import EntityUsersTable from 'app/entities/company/entityUsersTable';
import RedeemsLogs from 'app/entities/company/redeemsLogs';

export const EntityOverview = () => {
  const dispatch = useAppDispatch();
  const [updateCompanyOpen, setUpdateCompanyOpen] = React.useState(false);
  const [updateCompanyId, setUpdateCompanyId] = React.useState(undefined);
  const currentLocale = useAppSelector(state => state.locale.currentLocale);

  const { id } = useParams<'id'>();
  const updateSuccess = useAppSelector(state => state.company.updateSuccess);
  useEffect(() => {
    dispatch(getEntity(id));
  }, [updateSuccess]);

  const companyEntity = useAppSelector(state => state.company.entity);
  return (
    <>
      {companyEntity?.id?.toString() !== id ? (
        <Grid container justifyContent={'center'} alignItems={'center'} display={'flex'} width={'100%'} height={'500px'}>
          <Spinner />
        </Grid>
      ) : (
        <>
          <Grid container justifyContent={'flex-end'} sx={{ mb: 2 }}>
            <Button
              color="info"
              size="large"
              variant="text"
              startIcon={
                <IconButton sx={{ padding: 0, color: 'transparent' }}>
                  <EditIcon />
                </IconButton>
              }
              onClick={() => {
                setUpdateCompanyId(id);
                setUpdateCompanyOpen(true);
              }}
            >
              <Translate contentKey="kafaatApp.company.editCompany">Edit entity</Translate>
            </Button>
          </Grid>
          <Grid
            container
            justifyContent={'center'}
            sx={{
              backgroundColor: '#B18758',
              padding: '1rem',
              margin: '1rem',
              color: '#fff',
              height: '6rem',
            }}
          >
            <Grid item xs={2}>
              {companyEntity.logo && (
                <Box
                  sx={{
                    width: '80px',
                    height: '80px',
                    marginTop: '1.5rem',
                    borderRadius: '50%',
                    overflow: 'hidden',
                    background: `url(${companyEntity.logo.filePath}) no-repeat center top`,
                    backgroundSize: '80px 80px',
                    color: 'transparent',
                    padding: '0.5rem',
                    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                  }}
                ></Box>
              )}
            </Grid>
          </Grid>
          <br />
          <br />

          <Box>
            <Grid container spacing={3} justifyContent={'center'} width={'100%'} display={'flex'}>
              <Grid item xs={5} sm={5} md={4} lg={2} xl={2}>
                <Paper
                  component={Stack}
                  sx={{ width: '100%', height: '100%', textAlign: 'center', py: 4, px: 1 }}
                  direction="column"
                  justifyContent="center"
                >
                  <Box>
                    <UserIcon />
                  </Box>
                  <Typography variant={'h5'} color={'secondary'}>
                    {companyEntity?.numOfUsers}
                  </Typography>
                  <Typography variant={'subtitle1'} color={'primary.dark'}>
                    <Translate contentKey={'home.numberOfUsers'} />
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={5} sm={5} md={4} lg={2} xl={2}>
                <Paper
                  component={Stack}
                  sx={{ width: '100%', height: '100%', textAlign: 'center', py: 4, px: 1 }}
                  direction="column"
                  justifyContent="center"
                >
                  <Box>
                    <BuyIcon />
                  </Box>
                  <Typography variant={'h5'} color={'secondary'}>
                    {companyEntity?.numOfGifts}
                  </Typography>
                  <Typography variant={'subtitle1'} color={'primary.dark'}>
                    <Translate contentKey={'home.numOfGifts'} />
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={5} sm={5} md={4} lg={2} xl={2}>
                <Paper
                  component={Stack}
                  sx={{ width: '100%', height: '100%', textAlign: 'center', py: 4, px: 1 }}
                  direction="column"
                  justifyContent="center"
                >
                  <Box>
                    <ScanIcon />
                  </Box>
                  <Typography variant={'h5'} color={'secondary'}>
                    {companyEntity?.numOfRedeemsByQR}
                  </Typography>
                  <Typography variant={'subtitle1'} color={'primary.dark'}>
                    <Translate contentKey={'home.totalQRCodeRedeems'} />
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={5} sm={5} md={4} lg={2} xl={2}>
                <Paper
                  component={Stack}
                  sx={{ width: '100%', height: '100%', textAlign: 'center', py: 4, px: 1 }}
                  direction="column"
                  justifyContent="center"
                >
                  <Box>
                    <DiscountIcon />
                  </Box>
                  <Typography variant={'h5'} color={'secondary'}>
                    {companyEntity?.numOfRedeemsByCode}
                  </Typography>
                  <Typography variant={'subtitle1'} color={'primary.dark'}>
                    <Translate contentKey={'home.totalDiscountCodeRedeems'} />
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={5} sm={5} md={4} lg={2} xl={2}>
                <Paper
                  component={Stack}
                  sx={{ width: '100%', height: '100%', textAlign: 'center', py: 4, px: 1 }}
                  direction="column"
                  justifyContent="center"
                >
                  <Box>
                    <TicketIcon />
                  </Box>
                  <Typography variant={'h5'} color={'secondary'}>
                    {companyEntity?.totalRedeems}
                  </Typography>
                  <Typography variant={'subtitle1'} color={'primary.dark'}>
                    <Translate contentKey={'home.totalRedeems'} />
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
          </Box>
          <Box mt={2}>
            <Grid container width={'100%'} justifyContent={'center'}>
              <Grid container rowSpacing={1} columnSpacing={6} width={'70%'}>
                <Grid item xs={6}>
                  <FormControl disabled={true} fullWidth margin="normal">
                    <FormLabel>
                      <Translate contentKey="kafaatApp.company.nameAr">Name </Translate>
                    </FormLabel>
                    <FilledInput id="company-name" type={'text'} name="name" value={companyEntity.name} />
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl disabled={true} fullWidth margin="normal">
                    <FormLabel>
                      <Translate contentKey="kafaatApp.company.nameEn">nameEn </Translate>
                    </FormLabel>
                    <FilledInput id="company-nameEn" type={'text'} name="nameEn" value={companyEntity.nameEn} />
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl disabled={true} fullWidth margin="normal">
                    <FormLabel>
                      <Translate contentKey="kafaatApp.company.domain">Domain </Translate>
                    </FormLabel>
                    <FilledInput id="company-domain" type={'text'} name="domain" value={companyEntity.domain} />
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl disabled={true} fullWidth margin="normal" variant="filled">
                    <FormLabel>
                      <Translate contentKey="kafaatApp.company.type">Type </Translate>
                    </FormLabel>
                    <Autocomplete
                      disabled={true}
                      id="company-type"
                      inputMode={'text'}
                      options={[]}
                      value={companyEntity.type}
                      getOptionLabel={(option: any) => (currentLocale === 'ar-ly' ? option.name : option.nameEn)}
                      renderInput={params => <TextField {...params} variant={'filled'} />}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  {companyEntity?.agreement && (
                    <Button
                      color="primary"
                      size="large"
                      variant="contained"
                      startIcon={<DownloadIcon />}
                      onClick={() => {
                        const a = document.createElement('a');
                        a.href = companyEntity?.agreement?.filePath;
                        a.target = '_blank';
                        a.download = companyEntity?.agreement?.fileName;
                        a.click();
                      }}
                    >
                      <Translate contentKey="kafaatApp.business.download">Download</Translate>
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <br />
            <EntityUsersTable />
            <br />
            <RedeemsLogs />
            <br />
          </Box>
        </>
      )}
      <CompanyUpdate open={updateCompanyOpen} setOpen={setUpdateCompanyOpen} id={updateCompanyId} />
    </>
  );
};

export default EntityOverview;
