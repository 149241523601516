import React, { useState } from 'react';
import { NavMenu } from 'app/components/PublicLayout/NavMenu';
import { Grid } from '@mui/material';
import { LandingPageFooter } from 'app/components/PublicLayout/LandingPageFooter';
import { MenuLinkI } from 'app/interfaces/menuItems';
import { useLocation, useNavigate } from 'react-router-dom';

const PublicLayout = ({ children }) => {
  const [openJoin, setOpenJoin] = useState(false);
  const [openContact, setOpenContact] = useState(false);
  const [mobileOpen, setMobileOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const handleClick: (item: MenuLinkI) => void = ({ link, onClickKey, type }) => {
    if (type === 'route' && pathname != link) {
      navigate(link);
    } else if (onClickKey) {
      switch (onClickKey) {
        case 'join-us':
          setOpenJoin(true);
          break;
        case 'contact-us':
          setOpenContact(true);
          break;
      }
    } else {
      if (pathname == '/') {
        const el = document.getElementById(link);
        if (el) {
          el.scrollIntoView({ behavior: 'smooth' });
        }
      } else {
        navigate('/');
        setTimeout(() => {
          const el = document.getElementById(link);
          if (el) {
            el.scrollIntoView({ behavior: 'smooth' });
          }
        }, 200);
      }
    }
    setMobileOpen(false);
  };

  return (
    <Grid container direction={'column'} minHeight={'100vh'}>
      <NavMenu
        openJoin={openJoin}
        setOpenJoin={setOpenJoin}
        openContact={openContact}
        setOpenContact={setOpenContact}
        handleClick={handleClick}
        mobileOpen={mobileOpen}
        setMobileOpen={setMobileOpen}
      />
      {children}
      <LandingPageFooter handleClick={handleClick} />
    </Grid>
  );
};
export { PublicLayout };
