import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Translate, translate } from 'react-jhipster';

import { SORT } from 'app/shared/util/pagination.constants';
import { getSortState, overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntities } from './gift.reducer';
import GiftUpdate from 'app/entities/gift/gift-update';
import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { Spinner } from 'reactstrap';
import { DeleteIcon, EditIcon } from 'app/components/icons';
import GiftDeleteDialog from 'app/entities/gift/gift-delete-dialog';

export const Gift = () => {
  const dispatch = useAppDispatch();

  const location = useLocation();
  const navigate = useNavigate();
  const [updateId, setUpdateId] = React.useState(undefined);
  const [updateOpen, setUpdateOpen] = React.useState(false);
  const [deleteOpen, setDeleteOpen] = React.useState(false);
  const [deleteId, setDeleteId] = React.useState(undefined);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(location, rowsPerPage, 'id'), location.search)
  );
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    setPaginationState({
      ...paginationState,
      activePage: newPage,
    });
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setPaginationState({
      ...paginationState,
      itemsPerPage: parseInt(event.target.value, 10),
      activePage: 0,
    });
  };

  const giftList = useAppSelector(state => state.gift.entities);
  const loading = useAppSelector(state => state.gift.loading);

  const updateSuccess = useAppSelector(state => state.gift.updateSuccess);

  const totalItems = useAppSelector(state => state.gift.totalItems);

  const getAllEntities = () => {
    dispatch(
      getEntities({
        page: paginationState.activePage,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
      })
    );
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (location.search !== endURL) {
      navigate(`${location.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    getAllEntities();
  }, [updateSuccess]);

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort, paginationState.itemsPerPage]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const paramsPage = params.get('page');
    const sort = params.get(SORT);
    if (paramsPage && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: Number(paramsPage),
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [location.search]);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    paginationState.activePage > 0 ? Math.max(0, (1 + paginationState.activePage) * paginationState.itemsPerPage - giftList.length) : 0;

  return (
    <Container maxWidth="xl">
      <Box mt={2}>
        <Grid container justifyContent={'flex-end'} sx={{ mb: 2 }}>
          <Button
            color="primary"
            size="large"
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => {
              setUpdateId(undefined);
              setUpdateOpen(true);
            }}
          >
            <Translate contentKey="kafaatApp.gift.home.createLabel">Create new Gift</Translate>
          </Button>
        </Grid>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ borderBottom: 1, borderColor: 'grey.300', padding: '25px 30px' }} width={'30%'}>
                  <Translate contentKey="kafaatApp.gift.employee">employee</Translate>
                </TableCell>
                <TableCell sx={{ borderBottom: 1, borderColor: 'grey.300', padding: '25px 30px' }} width={'15%'}>
                  <Translate contentKey="kafaatApp.gift.expirationDate">expirationDate</Translate>
                </TableCell>
                <TableCell sx={{ borderBottom: 1, borderColor: 'grey.300', padding: '25px 30px' }} width={'15%'}>
                  <Translate contentKey="kafaatApp.gift.details">details</Translate>
                </TableCell>
                <TableCell sx={{ borderBottom: 1, borderColor: 'grey.300', padding: '25px 30px' }} width={'10%'}>
                  <Translate contentKey="kafaatApp.employee.actions">Actions</Translate>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell colSpan={7} height={'350px'} width={'350px'}>
                    <Box justifyContent={'center'} alignItems={'center'} display={'flex'} width={'100%'} height={'100%'}>
                      <Spinner />
                    </Box>
                  </TableCell>
                </TableRow>
              ) : giftList?.length > 0 ? (
                <>
                  {giftList.map(row => (
                    <TableRow key={row.id}>
                      <TableCell scope="row">
                        <Typography color={'secondary'}>{`${row.user.firstName} ${row.user.lastName}`}</Typography>
                      </TableCell>
                      <TableCell>{row.expirationDate}</TableCell>
                      <TableCell>{row.details}</TableCell>
                      <TableCell>
                        <Stack direction="row" spacing={2}>
                          <Tooltip title={<Translate contentKey="entity.action.edit">Edit</Translate>}>
                            <IconButton
                              sx={{ color: 'transparent' }}
                              onClick={() => {
                                setUpdateId(row.id);
                                setUpdateOpen(true);
                              }}
                            >
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title={<Translate contentKey="entity.action.delete">Delete</Translate>}>
                            <IconButton
                              sx={{ color: 'transparent' }}
                              onClick={() => {
                                setDeleteId(row.id);
                                setDeleteOpen(true);
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  ))}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 2 * emptyRows }}>
                      <TableCell colSpan={4} />
                    </TableRow>
                  )}
                </>
              ) : (
                <TableRow>
                  <TableCell colSpan={4}>
                    <Box justifyContent={'center'} flexDirection={'column'} textAlign={'center'} display={'flex'} padding={'50px'}>
                      <Typography variant={'subtitle1'} color={'gray'}>
                        <Translate contentKey={'home.noData'} />
                      </Typography>
                    </Box>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          {totalItems ? (
            <TablePagination
              rowsPerPageOptions={[5, 10, 20]}
              component="div"
              count={totalItems}
              rowsPerPage={rowsPerPage}
              labelRowsPerPage={translate('home.rowsPerPage')}
              labelDisplayedRows={({ from, to, count }) => `${from}–${to}`}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          ) : (
            ''
          )}
        </TableContainer>
      </Box>
      <GiftUpdate open={updateOpen} setOpen={setUpdateOpen} id={updateId} />
      <GiftDeleteDialog open={deleteOpen} setOpen={setDeleteOpen} id={deleteId} />
    </Container>
  );
};

export default Gift;
