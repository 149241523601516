import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Translate, translate } from 'react-jhipster';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { enableDisableEntity } from '../employee/employee.reducer';
import { getEntityUsers, getRelatedToFilterList } from './company.reducer';

import {
  Box,
  Container,
  IconButton,
  Paper,
  Switch,
  Table,
  Button,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Stack,
  Typography,
  Grid,
  Tooltip,
  TextField,
  Link,
  Autocomplete,
  TablePagination,
} from '@mui/material';
import { DeleteIcon, EditIcon, PasswordIcon } from 'app/components/icons';
import { useSnackbar } from 'notistack';
import EmployeeUpdate from 'app/entities/employee/employee-update';
import EmployeeDeleteDialog from 'app/entities/employee/employee-delete-dialog';
import EmployeeChangePassword from 'app/entities/employee/employee-change-password';
import { Spinner } from 'reactstrap';
import SearchIcon from '@mui/icons-material/Search';
import { getSortState, overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';

export const EntityUsersTable = () => {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [searchName, setSearchName] = useState<string>();
  const [type, setType] = useState<string>();
  const [relatedToUseEmail, setRelatedToUseEmail] = useState<string>();
  const [filter, setFilter] = useState<{ relatedToUseEmail: string; type: string; name: string; email: string }>();

  const currentLocale = useAppSelector(state => state.locale.currentLocale);
  const { id } = useParams<'id'>();
  const totalItems = useAppSelector(state => state.company.totalItems);
  const location = useLocation();
  const navigate = useNavigate();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(location, rowsPerPage, 'id'), location.search)
  );
  const entityUsers = useAppSelector(state => state.company.entityUsers);
  const relatedToFilterList = useAppSelector(state => state.company.relatedToFilterList);
  const loading = useAppSelector(state => state.company.loading);
  const updateSuccess = useAppSelector(state => state.employee.updateSuccess);

  const [updateEmployeeOpen, setUpdateEmployeeOpen] = React.useState(false);
  const [viewMode, setViewMode] = React.useState(false);
  const [updateEmployeeId, setUpdateEmployeeId] = React.useState(undefined);
  const [deleteEmployeeOpen, setDeleteEmployeeOpen] = React.useState(false);
  const [deleteEmployeeId, setDeleteEmployeeId] = React.useState(undefined);
  const [changePasswordOpen, setChangePasswordOpen] = React.useState(false);
  const [changePasswordId, setChangePasswordId] = React.useState(undefined);

  const getAllEntities = () => {
    dispatch(
      getEntityUsers({
        id,
        page: paginationState.activePage,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
        ...filter,
      })
    );
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    setPaginationState({
      ...paginationState,
      activePage: newPage,
    });
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setPaginationState({
      ...paginationState,
      itemsPerPage: parseInt(event.target.value, 10),
      activePage: 0,
    });
  };
  const enableDisable = async (employeeId: number, enabled) => {
    const {
      meta: { requestStatus },
    } = await dispatch(
      enableDisableEntity({
        id: employeeId,
        enabled,
      })
    );
    if (requestStatus === 'fulfilled') {
      enqueueSnackbar(translate(enabled ? 'kafaatApp.employee.enableSuccess' : 'kafaatApp.employee.disableSuccess'), {
        variant: 'success',
      });
    }
  };

  useEffect(() => {
    getAllEntities();
  }, [updateSuccess, filter]);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    paginationState.activePage > 0 ? Math.max(0, (1 + paginationState.activePage) * paginationState.itemsPerPage - entityUsers.length) : 0;

  const sortEntities = () => {
    getAllEntities();
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort, paginationState.itemsPerPage]);

  useEffect(() => {
    dispatch(getRelatedToFilterList(id));
  }, []);

  return (
    <Container maxWidth="xl">
      <Box mt={2}>
        <Grid container justifyContent={'center'} sx={{ mb: 2 }}>
          <form>
            <Stack direction={'row'}>
              <TextField
                sx={{ mt: 1, mx: 2 }}
                id="search-bar"
                onChange={e => {
                  setSearchName(e.target.value.trim());
                }}
                placeholder={translate('kafaatApp.company.name')}
                size="small"
              />
              <Autocomplete
                sx={{ mt: 1, mx: 2, width: '200px' }}
                id="employee-type"
                inputMode={'text'}
                onChange={(e, value) => setType(value)}
                getOptionLabel={(option: any) => translate(`kafaatApp.employee.${option}`)}
                renderInput={params => <TextField {...params} size="small" placeholder={translate('kafaatApp.employee.type')} />}
                options={['EMPLOYEE', 'ENTITY_ADMIN', 'FAMILY_USER']}
              />
              <Autocomplete
                sx={{ mt: 1, mx: 2, width: '200px' }}
                id="employee-relatedToByEmail"
                inputMode={'text'}
                onChange={(e, value) => setRelatedToUseEmail(value?.email)}
                getOptionLabel={(option: any) => `${option.firstName} ${option.lastName} (${option.email})`}
                renderInput={params => (
                  <TextField {...params} size="small" placeholder={translate('kafaatApp.employee.relatedToByEmail')} />
                )}
                options={relatedToFilterList}
              />
              <Button
                color="primary"
                size="large"
                variant="contained"
                startIcon={<SearchIcon />}
                onClick={() => {
                  setFilter({ relatedToUseEmail, type, name: searchName, email: searchName });
                }}
              >
                <Translate contentKey="kafaatApp.company.search">search</Translate>
              </Button>
            </Stack>
          </form>
        </Grid>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ borderBottom: 1, borderColor: 'grey.300', padding: '25px 30px' }} width={'20%'}>
                  <Translate contentKey="kafaatApp.employee.name">Name</Translate>
                </TableCell>
                <TableCell sx={{ borderBottom: 1, borderColor: 'grey.300', padding: '25px 30px' }} width={'10%'}>
                  <Translate contentKey="kafaatApp.employee.type">Type</Translate>
                </TableCell>
                <TableCell sx={{ borderBottom: 1, borderColor: 'grey.300', padding: '25px 30px' }} width={'20%'}>
                  <Translate contentKey="kafaatApp.employee.relatedTo">Related To</Translate>
                </TableCell>
                <TableCell sx={{ borderBottom: 1, borderColor: 'grey.300', padding: '25px 30px' }} width={'10%'}>
                  <Translate contentKey="kafaatApp.employee.invitations">Invitations</Translate>
                </TableCell>
                <TableCell sx={{ borderBottom: 1, borderColor: 'grey.300', padding: '25px 30px' }} width={'15%'}>
                  <Translate contentKey="kafaatApp.employee.enabled">Enabled</Translate>
                </TableCell>
                <TableCell sx={{ borderBottom: 1, borderColor: 'grey.300', padding: '25px 30px' }} width={'15%'}>
                  <Translate contentKey="kafaatApp.employee.actions">Actions</Translate>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell colSpan={7} height={'350px'} width={'350px'}>
                    <Box justifyContent={'center'} alignItems={'center'} display={'flex'} width={'100%'} height={'100%'}>
                      <Spinner />
                    </Box>
                  </TableCell>
                </TableRow>
              ) : entityUsers?.length > 0 ? (
                <>
                  {entityUsers.map(row => (
                    <TableRow key={row.name}>
                      <TableCell scope="row">
                        <Link
                          component="button"
                          variant="body2"
                          color={'primary.dark'}
                          onClick={() => {
                            setUpdateEmployeeId(row.login);
                            setViewMode(true);
                            setUpdateEmployeeOpen(true);
                          }}
                        >
                          <Typography color={'primary.dark'}>{`${row.firstName} ${row.lastName}`}</Typography>
                        </Link>
                        <Typography color={'gray'}>{row.email}</Typography>
                      </TableCell>
                      <TableCell scope="row">
                        <Typography color={'primary.dark'}>
                          {row.familyAccount ? (
                            <Translate contentKey="kafaatApp.employee.familyMember">Family Member</Translate>
                          ) : (
                            row.type && <Translate contentKey={`kafaatApp.employee.${row.type}`}>{row.type}</Translate>
                          )}
                        </Typography>
                      </TableCell>
                      <TableCell scope="row">
                        {row.familyAccount && row.relatedTo && (
                          <>
                            <Link
                              align={'left'}
                              component="button"
                              variant="body2"
                              color={'primary.dark'}
                              onClick={() => {
                                setUpdateEmployeeId(row.relatedTo.login);
                                setViewMode(true);
                                setUpdateEmployeeOpen(true);
                              }}
                            >
                              <Typography color={'primary.dark'}>{`${row.relatedTo.firstName} ${row.relatedTo.lastName}`}</Typography>
                            </Link>
                            <Typography color={'gray'}>{row.relatedTo.email}</Typography>
                          </>
                        )}
                      </TableCell>
                      <TableCell scope="row">
                        <Typography color={'primary.dark'}>
                          {currentLocale === 'ar-ly'
                            ? `${row.allowedTotalInvitations}/${row.numOfInvitations}`
                            : `${row.numOfInvitations}/${row.allowedTotalInvitations}`}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Switch
                          color={'secondary'}
                          checked={row.enabled}
                          onChange={() => {
                            enableDisable(row.id, !row.enabled);
                          }}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      </TableCell>
                      <TableCell>
                        <Stack direction="row" spacing={2}>
                          <Tooltip title={<Translate contentKey="entity.action.edit">Edit</Translate>}>
                            <IconButton
                              sx={{ color: 'transparent' }}
                              onClick={() => {
                                setUpdateEmployeeId(row.login);
                                setViewMode(false);
                                setUpdateEmployeeOpen(true);
                              }}
                            >
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title={<Translate contentKey="entity.action.delete">Delete</Translate>}>
                            <IconButton
                              sx={{ color: 'transparent' }}
                              onClick={() => {
                                setDeleteEmployeeId(row.id);
                                setDeleteEmployeeOpen(true);
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title={<Translate contentKey="kafaatApp.employee.changePassword">Change Password</Translate>}>
                            <IconButton
                              sx={{ color: 'transparent' }}
                              onClick={() => {
                                setChangePasswordId(row.id);
                                setChangePasswordOpen(true);
                              }}
                            >
                              <PasswordIcon />
                            </IconButton>
                          </Tooltip>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  ))}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 2 * emptyRows }}>
                      <TableCell colSpan={4} />
                    </TableRow>
                  )}
                </>
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <Box justifyContent={'center'} flexDirection={'column'} textAlign={'center'} display={'flex'} padding={'50px'}>
                      <Typography variant={'subtitle1'} color={'gray'}>
                        <Translate contentKey={'home.noData'} />
                      </Typography>
                    </Box>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          {totalItems ? (
            <TablePagination
              rowsPerPageOptions={[5, 10, 20]}
              component="div"
              count={totalItems}
              rowsPerPage={rowsPerPage}
              labelRowsPerPage={translate('home.rowsPerPage')}
              labelDisplayedRows={({ from, to, count }) => `${from}–${to}`}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          ) : (
            ''
          )}
        </TableContainer>
      </Box>
      <EmployeeUpdate open={updateEmployeeOpen} setOpen={setUpdateEmployeeOpen} id={updateEmployeeId} viewMode={viewMode} />
      <EmployeeChangePassword open={changePasswordOpen} setOpen={setChangePasswordOpen} id={changePasswordId} />
      <EmployeeDeleteDialog open={deleteEmployeeOpen} setOpen={setDeleteEmployeeOpen} id={deleteEmployeeId} />
    </Container>
  );
};

export default EntityUsersTable;
