import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { translate, Translate } from 'react-jhipster';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import { Button, Dialog, DialogActions, DialogContent, Typography } from '@mui/material';
import { DangerIcon } from 'app/components/icons';
import { useSnackbar } from 'notistack';
import { deleteEntity } from 'app/entities/company-admin/company-admin.reducer';

export const CompanyAdminDeleteDialog = ({ open, setOpen, id }) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const updateSuccess = useAppSelector(state => state.companyAdmin.updateSuccess);
  const loading = useAppSelector(state => state.companyAdmin.loading);
  const { enqueueSnackbar } = useSnackbar();

  const handleClose = () => {
    navigate('/entityAdmins' + location.search);
    setOpen(false);
  };

  useEffect(() => {
    if (updateSuccess && open) {
      handleClose();
    }
  }, [updateSuccess]);

  const confirmDelete = async () => {
    const {
      meta: { requestStatus },
    } = await dispatch(deleteEntity(id));
    if (requestStatus === 'fulfilled') {
      enqueueSnackbar(translate('kafaatApp.companyAdmin.deleteSuccess'), { variant: 'success' });
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth={'md'}>
      <DialogContent sx={{ p: 4, justifyContent: 'center' }}>
        <Typography variant={'h6'} color={'#E02020'} textAlign="center" mb={2}>
          <DangerIcon sx={{ color: 'transparent' }} />
          <br />
          <Translate contentKey="kafaatApp.business.home.deleteConfirmation">Delete Confirmation</Translate>
        </Typography>
        <Typography color={'secondary'} textAlign="center">
          <Translate contentKey="kafaatApp.employee.deleteConfirm">Delete Confirm</Translate>
        </Typography>
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent: 'center',
          mb: 2,
        }}
      >
        <Button size={'large'} color="primary" variant="contained" disabled={loading} onClick={confirmDelete}>
          <Translate contentKey="kafaatApp.business.home.delete">Delete</Translate>
        </Button>
        <Button size={'large'} onClick={handleClose} variant="outlined" color={'secondary'}>
          <Translate contentKey="kafaatApp.business.home.cancel">Cancel</Translate>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CompanyAdminDeleteDialog;
