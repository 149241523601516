import React from 'react';
import { Route } from 'react-router-dom';
import Loadable from 'react-loadable';

import Register from 'app/modules/account/register/register';
import Activate from 'app/modules/account/activate/activate';
import PasswordResetInit from 'app/modules/account/password-reset/init/password-reset-init';
import PasswordResetFinish from 'app/modules/account/password-reset/finish/password-reset-finish';
import Logout from 'app/modules/login/logout';
import { Home } from 'app/modules/home/home';
import EntitiesRoutes from 'app/entities/company';
import PrivateRoute from 'app/shared/auth/private-route';
import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';
import PageNotFound from 'app/shared/error/page-not-found';
import { AUTHORITIES } from 'app/config/constants';
import BusinessRoutes from 'app/entities/business';
import { Layout } from 'app/components/Layout';
import EmployeeRoutes from 'app/entities/employee';
import GiftRoutes from 'app/entities/gift';
import CompanyAdminRoutes from 'app/entities/company-admin';
import SettingsRoutes from 'app/entities/settings';
import Reports from 'app/modules/reports/reports';
import { PublicLayout } from 'app/components/PublicLayout';
import { LandingPage } from 'app/components/PublicLayout/LandingPage';
import NotificationRoutes from 'app/entities/notification';
import ContactUs from 'app/modules/contact-us/contact-us';
import JoinUs from 'app/modules/join-us/join-us';
import privacy from 'app/privacy';
import { Terms } from 'app/terms';
import MobilePriorities from 'app/entities/mobile-priorities';
import MobilePrioritiesRoutes from 'app/entities/mobile-priorities';
import FamilyPasswordResetFinish from 'app/modules/account/family-password-reset/finish/family-password-reset-finish';

export const paths = {
  login: {
    pathname: '/login',
    breadcrumb: '',
    title: '',
    description: '',
  },
  home: {
    pathname: '/home',
    breadcrumb: 'routes.dashboard',
    title: 'routes.dashboard',
    description: 'routes.dashboardDescription',
  },
  not_authorized: {
    pathname: '/not-authorized',
    breadcrumb: '',
    title: '',
  },
  page404: {
    pathname: '/404',
    breadcrumb: '',
    title: '',
  },
  business: {
    title: 'routes.business',
    pathname: `/business`,
    breadcrumb: 'routes.business',
    description: 'routes.businessDescription',
  },
  entities: {
    pathname: '/entities',
    title: 'routes.entities',
    breadcrumb: 'routes.entities',
    description: 'routes.entitiesDescription',
  },
  employees: {
    pathname: '/employees',
    title: 'routes.employees',
    breadcrumb: 'routes.employees',
    description: 'routes.employeesDescription',
  },
  entityAdmins: {
    pathname: '/entityAdmins',
    title: 'routes.entityAdmins',
    breadcrumb: 'routes.entityAdmins',
    description: 'routes.entityAdminsDescription',
  },
  reports: {
    pathname: '/reports',
    title: 'routes.reports',
    breadcrumb: 'routes.reports',
    description: 'routes.reportsDescription',
  },
  giftcards: {
    pathname: '/giftcards',
    title: 'routes.giftcards',
    breadcrumb: 'routes.giftcards',
    description: 'routes.giftcardsDescription',
  },
  notifications: {
    pathname: '/notifications',
    title: 'routes.notifications',
    breadcrumb: 'routes.notifications',
    description: 'routes.notificationsDescription',
  },
  settings: {
    pathname: '/settings',
    title: 'routes.settings',
    breadcrumb: 'routes.settings',
    description: 'routes.settingsDescription',
  },
  joinUs: {
    pathname: '/join-us-forms',
    title: 'routes.joinUs',
    breadcrumb: 'routes.joinUs',
    description: 'routes.joinUsDescription',
  },
  contactUs: {
    pathname: '/contact-us-forms',
    title: 'routes.contactUs',
    breadcrumb: 'routes.contactUs',
    description: 'routes.contactUsDescription',
  },
  mobilePriorities: {
    pathname: '/mobile-priorities',
    title: 'routes.mobilePriorities',
    breadcrumb: 'routes.mobilePriorities',
    description: 'routes.mobilePrioritiesDescription',
  },
};
const loading = <div>loading ...</div>;

const Account = Loadable({
  loader: () => import(/* webpackChunkName: "account" */ 'app/modules/account'),
  loading: () => loading,
});
const Login = Loadable({
  loader: () => import(/* webpackChunkName: "administration" */ 'app/modules/login/login'),
  loading: () => loading,
});
const Admin = Loadable({
  loader: () => import(/* webpackChunkName: "administration" */ 'app/modules/administration'),
  loading: () => loading,
});

const AppRoutes = () => {
  return (
    <div className="view-routes">
      <ErrorBoundaryRoutes>
        <Route
          path={'home'}
          element={
            <PrivateRoute>
              <Layout>
                <Home />
              </Layout>
            </PrivateRoute>
          }
        />
        <Route
          path={'business/*'}
          element={
            <PrivateRoute>
              <Layout>
                <BusinessRoutes />
              </Layout>
            </PrivateRoute>
          }
        />
        <Route
          path={'entities/*'}
          element={
            <PrivateRoute>
              <Layout>
                <EntitiesRoutes />
              </Layout>
            </PrivateRoute>
          }
        />
        <Route
          path={'employees/*'}
          element={
            <PrivateRoute>
              <Layout>
                <EmployeeRoutes />
              </Layout>
            </PrivateRoute>
          }
        />
        <Route
          path={'giftcards/*'}
          element={
            <PrivateRoute>
              <Layout>
                <GiftRoutes />
              </Layout>
            </PrivateRoute>
          }
        />
        <Route
          path={'entityAdmins/*'}
          element={
            <PrivateRoute>
              <Layout>
                <CompanyAdminRoutes />
              </Layout>
            </PrivateRoute>
          }
        />
        <Route
          path={'reports/*'}
          element={
            <PrivateRoute>
              <Layout>
                <Reports />
              </Layout>
            </PrivateRoute>
          }
        />
        <Route
          path={'notifications/*'}
          element={
            <PrivateRoute>
              <Layout>
                <NotificationRoutes />
              </Layout>
            </PrivateRoute>
          }
        />
        <Route
          path={'settings/*'}
          element={
            <PrivateRoute>
              <Layout>
                <SettingsRoutes />
              </Layout>
            </PrivateRoute>
          }
        />
        <Route
          path={'contact-us-forms'}
          element={
            <PrivateRoute>
              <Layout>
                <ContactUs />
              </Layout>
            </PrivateRoute>
          }
        />
        <Route
          path={'join-us-forms'}
          element={
            <PrivateRoute>
              <Layout>
                <JoinUs />
              </Layout>
            </PrivateRoute>
          }
        />
        <Route
          path={'mobile-priorities'}
          element={
            <PrivateRoute>
              <Layout>
                <MobilePrioritiesRoutes />
              </Layout>
            </PrivateRoute>
          }
        />
        <Route path="login" element={<Login />} />
        <Route path="logout" element={<Logout />} />
        <Route path="account">
          <Route
            path="*"
            element={
              <PrivateRoute hasAnyAuthorities={[AUTHORITIES.ADMIN, AUTHORITIES.USER]}>
                <Account />
              </PrivateRoute>
            }
          />
          <Route path="register" element={<Register />} />
          <Route path="activate" element={<Activate />} />
          <Route path="reset">
            <Route path="request" element={<PasswordResetInit />} />
            <Route path="finish" element={<PasswordResetFinish />} />
            <Route path="family/finish" element={<FamilyPasswordResetFinish />} />
          </Route>
        </Route>
        <Route
          path="admin/*"
          element={
            <PrivateRoute>
              <Admin />
            </PrivateRoute>
          }
        />
        <Route path={'terms'} element={<Terms />} />
        <Route
          path={'privacy'}
          element={
            <PublicLayout>
              {/*TODO add terms component*/}
              <div style={{ flex: '1' }}>
                <div dangerouslySetInnerHTML={{ __html: privacy }} />
              </div>
            </PublicLayout>
          }
        />
        <Route
          index
          element={
            <PublicLayout>
              <LandingPage />
            </PublicLayout>
          }
        />
        <Route path="*" element={<PageNotFound />} />
      </ErrorBoundaryRoutes>
    </div>
  );
};

export default AppRoutes;
