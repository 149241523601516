import axios from 'axios';
import { createAsyncThunk, isPending } from '@reduxjs/toolkit';

import { createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';

const initialState: EntityState<any> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: null,
  pdf: null,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const apiUrl = 'api/admin/report';

// Actions

export const getEntity = createAsyncThunk(
  'reports/fetch_entity',
  async ({ from, to }: any) => {
    const requestUrl = from && to ? `${apiUrl}?from=${from}&to=${to}` : `${apiUrl}`;
    return axios.get<any>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);
// slice
export const getPDF = createAsyncThunk(
  'reports/fetch_entity_pdf',
  async ({ language, from, to }: any) => {
    const requestUrl = from && to ? `${apiUrl}/xlsx?language=${language}&from=${from}&to=${to}` : `${apiUrl}/xlsx?language=${language}`;
    return axios.get<any>(requestUrl, { responseType: 'blob' });
  },
  { serializeError: serializeAxiosError }
);

export const ReportsSlice = createEntitySlice({
  name: 'reports',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(getPDF.fulfilled, (state, action) => {
        state.loading = false;
        state.pdf = action.payload.data;
      })
      .addMatcher(isPending(getEntity, getPDF), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      });
  },
});

export const { reset } = ReportsSlice.actions;

// Reducer
export default ReportsSlice.reducer;
