// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".iNuFsqbYK9FO0uWhkgcB input::-webkit-outer-spin-button,\n.iNuFsqbYK9FO0uWhkgcB input::-webkit-inner-spin-button {\n  -webkit-appearance: none;\n  margin: 0;\n}", "",{"version":3,"sources":["webpack://./src/main/webapp/app/components/PublicLayout/JoinUsDialog/JoinUsDialog.module.scss"],"names":[],"mappings":"AACE;;EAEE,wBAAA;EACA,SAAA;AAAJ","sourcesContent":[".noArrowsInput {\n  input::-webkit-outer-spin-button,\n  input::-webkit-inner-spin-button {\n    -webkit-appearance: none;\n    margin: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"noArrowsInput": "iNuFsqbYK9FO0uWhkgcB"
};
export default ___CSS_LOADER_EXPORT___;
