import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { translate, Translate } from 'react-jhipster';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntities as getStorages } from 'app/entities/storage/storage.reducer';
import { getEntities as getCompanyTypes } from 'app/entities/company-type/company-type.reducer';
import { createEntity, getEntity, updateEntity } from './company.reducer';
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FilledInput,
  FormControl,
  FormHelperText,
  FormLabel,
  IconButton,
  TextField,
} from '@mui/material';
import { Formik } from 'formik';
import useScriptRef from 'app/hooks/useScriptRef';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';
import { AttachmentIcon } from 'app/components/icons';
import { LoadingButton } from '@mui/lab';

export const CompanyUpdate = ({ open, setOpen, id }) => {
  const dispatch = useAppDispatch();
  const scriptedRef = useScriptRef();

  const navigate = useNavigate();

  const isNew = id === undefined;
  const { enqueueSnackbar } = useSnackbar();

  const companyTypes = useAppSelector(state => state.companyType.entities);
  const companyEntity = useAppSelector(state => state.company.entity);
  const loading = useAppSelector(state => state.company.loading);
  const updating = useAppSelector(state => state.company.updating);
  const updateSuccess = useAppSelector(state => state.company.updateSuccess);
  const currentLocale = useAppSelector(state => state.locale.currentLocale);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (open) {
      if (!isNew) {
        dispatch(getEntity(id));
      }

      dispatch(getStorages({}));
      dispatch(getCompanyTypes({}));
    }
  }, [open]);

  useEffect(() => {
    if (updateSuccess && open) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = async values => {
    const entity = {
      ...values,
    };
    const formData: any = new FormData();
    formData.append('type.id', entity.type.id);
    formData.append('name', entity.name);
    formData.append('nameEn', entity.nameEn);
    formData.append('domain', entity.domain);
    if (!entity.agreement?.filePath && entity.agreement) formData.append('agreement', entity.agreement);
    if (!entity.logo?.filePath && entity.logo) formData.append('logo', entity.logo);

    if (isNew) {
      const {
        meta: { requestStatus },
      } = await dispatch(createEntity(formData));
      if (requestStatus === 'fulfilled') {
        enqueueSnackbar(translate('kafaatApp.company.createSuccess'), { variant: 'success' });
      }
    } else {
      const {
        meta: { requestStatus },
      } = await dispatch(
        updateEntity({
          id: entity.id,
          values: formData,
        })
      );
      if (requestStatus === 'fulfilled') {
        enqueueSnackbar(translate('kafaatApp.company.updateSuccess'), { variant: 'success' });
      }
    }
  };

  return (
    <Dialog maxWidth={'md'} fullWidth open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title" color={'secondary'}>
        {isNew ? (
          <Translate contentKey="kafaatApp.company.home.createLabel">Create new Entity</Translate>
        ) : (
          <Translate contentKey="kafaatApp.company.home.editLabel">Edit Entity</Translate>
        )}
      </DialogTitle>
      <Divider />
      {(isNew || (!isNew && !loading)) && (
        <Formik
          initialValues={
            isNew
              ? {
                  name: undefined,
                  nameEn: undefined,
                  domain: undefined,
                  agreement: undefined,
                  logo: undefined,
                  type: undefined,
                }
              : {
                  ...companyEntity,
                  type: companyEntity?.type,
                }
          }
          validationSchema={Yup.object().shape({
            name: Yup.string().max(255).required(translate('error.form.required')),
            nameEn: Yup.string().max(255).required(translate('error.form.required')),
            domain: Yup.string()
              .max(255)
              .trim()
              .matches(/[a-z0-9._%+-]+\.[a-z0-9.-]+/, translate('error.form.incorrectValue'))
              .required(translate('error.form.required')),
            agreement: Yup.mixed().nullable(),
            logo: Yup.mixed().required(translate('error.form.required')),
            type: Yup.mixed().required(translate('error.form.required')),
          })}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
              await saveEntity(values);

              if (scriptedRef.current) {
                setStatus({ success: true });
                setSubmitting(false);
                handleClose();
              }
            } catch (err) {
              console.error(err);
              if (scriptedRef.current) {
                setStatus({ success: false });
                setSubmitting(false);
              }
            }
          }}
        >
          {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, setFieldValue, touched, values }) => (
            <form noValidate onSubmit={handleSubmit}>
              <DialogContent>
                <FormControl fullWidth margin="normal" error={Boolean(touched.name && errors.name)}>
                  <FormLabel>
                    <Translate contentKey="kafaatApp.company.nameAr">Name </Translate>
                  </FormLabel>
                  <FilledInput
                    id="company-name"
                    type={'text'}
                    name="name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.name}
                  />
                  {touched.name && errors.name && (
                    <FormHelperText error id="standard-weight-helper-text">
                      {errors.name.toString()}
                    </FormHelperText>
                  )}
                </FormControl>
                <FormControl fullWidth margin="normal" error={Boolean(touched.nameEn && errors.nameEn)}>
                  <FormLabel>
                    <Translate contentKey="kafaatApp.company.nameEn">nameEn </Translate>
                  </FormLabel>
                  <FilledInput
                    id="company-nameEn"
                    type={'text'}
                    name="nameEn"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.nameEn}
                  />
                  {touched.nameEn && errors.nameEn && (
                    <FormHelperText error id="standard-weight-helper-text">
                      {errors.nameEn.toString()}
                    </FormHelperText>
                  )}
                </FormControl>
                <FormControl fullWidth margin="normal" error={Boolean(touched.domain && errors.domain)}>
                  <FormLabel>
                    <Translate contentKey="kafaatApp.company.domain">Domain </Translate>
                  </FormLabel>
                  <FilledInput
                    id="company-domain"
                    type={'text'}
                    name="domain"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.domain}
                    placeholder={'kbs.sa'}
                  />
                  {touched.domain && errors.domain && (
                    <FormHelperText error id="standard-weight-helper-text">
                      {errors.domain.toString()}
                    </FormHelperText>
                  )}
                </FormControl>
                <FormControl fullWidth margin="normal" error={Boolean(touched.logo && errors.logo)}>
                  <FormLabel>
                    <Translate contentKey="kafaatApp.company.logo">Logo </Translate>
                  </FormLabel>
                  <FilledInput
                    id="file-name"
                    type={'text'}
                    value={values.logo ? (values.logo.name ? values.logo.name : values.logo.fileName) : ''}
                    endAdornment={
                      <IconButton component="label">
                        <AttachmentIcon />
                        <input
                          type="file"
                          accept={'image/*'}
                          hidden
                          name={'logo'}
                          onChange={event => {
                            setFieldValue('logo', event.currentTarget.files[0]);
                          }}
                          onBlur={handleBlur}
                          id="fileupload"
                        />
                      </IconButton>
                    }
                  />
                  {touched.logo && errors.logo && (
                    <FormHelperText error id="standard-weight-helper-text">
                      {errors.logo.toString()}
                    </FormHelperText>
                  )}
                </FormControl>
                <FormControl fullWidth margin="normal" variant="filled" error={Boolean(touched.type && errors.type)}>
                  <FormLabel>
                    <Translate contentKey="kafaatApp.company.type">Type </Translate>
                  </FormLabel>
                  <Autocomplete
                    id="company-type"
                    onBlur={handleBlur}
                    inputMode={'text'}
                    value={values.type}
                    onChange={(e, value) => setFieldValue('type', value)}
                    getOptionLabel={(option: any) => (currentLocale === 'ar-ly' ? option.name : option.nameEn)}
                    renderInput={params => <TextField error={Boolean(touched.type && errors.type)} {...params} variant={'filled'} />}
                    options={companyTypes}
                  />
                  {touched.type && errors.type && (
                    <FormHelperText error id="standard-weight-helper-text">
                      {errors.type.toString()}
                    </FormHelperText>
                  )}
                </FormControl>
                <FormControl fullWidth margin="normal">
                  <FormLabel>
                    <Translate contentKey="kafaatApp.company.agreement">Agreement </Translate>
                  </FormLabel>
                  <FilledInput
                    id="file-name"
                    type={'text'}
                    value={values.agreement ? (values.agreement.name ? values.agreement.name : values.agreement.fileName) : ''}
                    endAdornment={
                      <IconButton component="label">
                        <AttachmentIcon />
                        <input
                          type="file"
                          accept={'application/pdf'}
                          hidden
                          name={'agreement'}
                          onChange={event => {
                            setFieldValue('agreement', event.currentTarget.files[0]);
                          }}
                          onBlur={handleBlur}
                          id="fileupload"
                        />
                      </IconButton>
                    }
                  />
                </FormControl>
              </DialogContent>
              <DialogActions>
                <Button size={'large'} onClick={handleClose} variant="outlined" color={'secondary'}>
                  <Translate contentKey="kafaatApp.business.home.cancel">Cancel</Translate>
                </Button>
                <LoadingButton size={'large'} color="primary" variant="contained" loading={isSubmitting} type={'submit'}>
                  <Translate contentKey="kafaatApp.business.home.save">Save</Translate>
                </LoadingButton>
              </DialogActions>
            </form>
          )}
        </Formik>
      )}
    </Dialog>
  );
};

export default CompanyUpdate;
