import { useMediaQuery, ListItem } from '@mui/material';
import { useTheme } from '@mui/material/styles';
// @ts-ignore
import styles from './Language.module.scss';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import React from 'react';
import { Storage } from 'react-jhipster';
import { setLocale } from 'app/shared/reducers/locale';
import { isRTL } from 'app/config/translation';
const Language = () => {
  const theme = useTheme();
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  let inputRef: HTMLInputElement;
  const currentLocale = useAppSelector(state => state.locale.currentLocale);

  const dispatch = useAppDispatch();

  const handleLocaleChange = langKey => {
    Storage.session.set('locale', langKey);
    dispatch(setLocale(langKey));
    document.querySelector('html').setAttribute('dir', isRTL(langKey) ? 'rtl' : 'ltr');
  };
  return (
    <>
      <div className={styles.center}>
        <div className={styles.link} onClick={() => handleLocaleChange(isRTL(currentLocale) ? 'en' : 'ar-ly')}>
          {isRTL(currentLocale) ? 'English' : 'العربية'}
        </div>
      </div>
    </>
  );
};
export { Language };
