import React, { useEffect } from 'react';
import { Translate } from 'react-jhipster';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import AddIcon from '@mui/icons-material/Add';
import { getDiscountPriorities, getOfferPriorities } from './priorities.reducer';
import {
  Box,
  Container,
  IconButton,
  Paper,
  Table,
  Button,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Stack,
  Typography,
  Grid,
  Tooltip,
} from '@mui/material';
import { DeleteIcon } from 'app/components/icons';
import { Spinner } from 'reactstrap';
import { PriorityCreate } from 'app/entities/mobile-priorities/priority-create';
import PriorityDeleteDialog from 'app/entities/mobile-priorities/priority-delete-dialog';

export const MobilePriorities = () => {
  const dispatch = useAppDispatch();

  const offerPriorities = useAppSelector(state => state.priorities.offerPriorities);
  const discountPriorities = useAppSelector(state => state.priorities.discountPriorities);

  const loading = useAppSelector(state => state.priorities.loading);
  const updateSuccess = useAppSelector(state => state.priorities.updateSuccess);

  const [createPriorityOpen, setCreatePriorityOpen] = React.useState(false);
  const [deletePriorityOpen, setDeletePriorityOpen] = React.useState(false);
  const [type, setType] = React.useState<'DISCOUNT' | 'OFFER'>();
  const [deletePriorityId, setDeletePriorityId] = React.useState(undefined);

  useEffect(() => {
    dispatch(getDiscountPriorities());
    dispatch(getOfferPriorities());
  }, [updateSuccess]);

  return (
    <Container maxWidth="xl">
      <Box mt={2}>
        <Grid container sx={{ mb: 2 }} justifyContent={'space-between'}>
          <Typography color="secondary" variant="h6" gutterBottom>
            <Translate contentKey="kafaatApp.business.businessOffers">Business Offers </Translate>
          </Typography>
          <Button
            color="primary"
            size="large"
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => {
              setType('OFFER');
              setCreatePriorityOpen(true);
            }}
          >
            <Translate contentKey="kafaatApp.business.priorities.addOffer">Add Offer</Translate>
          </Button>
        </Grid>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ borderBottom: 1, borderColor: 'grey.300', padding: '25px 30px' }} width={'20%'}>
                  <Translate contentKey="kafaatApp.business.priorities.offerTitle">offer title</Translate>
                </TableCell>
                <TableCell sx={{ borderBottom: 1, borderColor: 'grey.300', padding: '25px 30px' }} width={'20%'}>
                  <Translate contentKey="kafaatApp.business.priorities.businessName">business name</Translate>
                </TableCell>
                <TableCell sx={{ borderBottom: 1, borderColor: 'grey.300', padding: '25px 30px' }} width={'15%'}>
                  <Translate contentKey="kafaatApp.business.priorities.priority">priority</Translate>
                </TableCell>
                <TableCell sx={{ borderBottom: 1, borderColor: 'grey.300', padding: '25px 30px' }} width={'20%'}>
                  <Translate contentKey="kafaatApp.employee.actions">Actions</Translate>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell colSpan={7} height={'350px'} width={'350px'}>
                    <Box justifyContent={'center'} alignItems={'center'} display={'flex'} width={'100%'} height={'100%'}>
                      <Spinner />
                    </Box>
                  </TableCell>
                </TableRow>
              ) : offerPriorities?.length > 0 ? (
                <>
                  {offerPriorities.map(row => (
                    <TableRow key={row.name}>
                      <TableCell scope="row">
                        <Typography color={'primary.dark'}>{row.title}</Typography>
                      </TableCell>
                      <TableCell scope="row">
                        <Typography color={'gray'}>{row.businessName}</Typography>
                      </TableCell>
                      <TableCell scope="row">
                        <Typography color={'gray'}>{row.priority}</Typography>
                      </TableCell>
                      <TableCell>
                        <Stack direction="row" spacing={2}>
                          <Tooltip title={<Translate contentKey="entity.action.delete">Delete</Translate>}>
                            <IconButton
                              sx={{ color: 'transparent' }}
                              onClick={() => {
                                setDeletePriorityId(row.id);
                                setDeletePriorityOpen(true);
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  ))}
                </>
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <Box justifyContent={'center'} flexDirection={'column'} textAlign={'center'} display={'flex'} padding={'50px'}>
                      <Typography variant={'subtitle1'} color={'gray'}>
                        <Translate contentKey={'home.noData'} />
                      </Typography>
                    </Box>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box mt={2}>
        <Grid container sx={{ mb: 2 }} justifyContent={'space-between'}>
          <Typography color="secondary" variant="h6" gutterBottom>
            <Translate contentKey="kafaatApp.business.businessDiscounts">Business Discounts </Translate>
          </Typography>
          <Button
            color="primary"
            size="large"
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => {
              setType('DISCOUNT');
              setCreatePriorityOpen(true);
            }}
          >
            <Translate contentKey="kafaatApp.business.priorities.addDiscount">Add Discounts</Translate>
          </Button>
        </Grid>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ borderBottom: 1, borderColor: 'grey.300', padding: '25px 30px' }} width={'20%'}>
                  <Translate contentKey="kafaatApp.business.priorities.discountTitle">discount title</Translate>
                </TableCell>
                <TableCell sx={{ borderBottom: 1, borderColor: 'grey.300', padding: '25px 30px' }} width={'20%'}>
                  <Translate contentKey="kafaatApp.business.priorities.businessName">business name</Translate>
                </TableCell>
                <TableCell sx={{ borderBottom: 1, borderColor: 'grey.300', padding: '25px 30px' }} width={'15%'}>
                  <Translate contentKey="kafaatApp.business.priorities.priority">priority</Translate>
                </TableCell>
                <TableCell sx={{ borderBottom: 1, borderColor: 'grey.300', padding: '25px 30px' }} width={'20%'}>
                  <Translate contentKey="kafaatApp.employee.actions">Actions</Translate>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell colSpan={7} height={'350px'} width={'350px'}>
                    <Box justifyContent={'center'} alignItems={'center'} display={'flex'} width={'100%'} height={'100%'}>
                      <Spinner />
                    </Box>
                  </TableCell>
                </TableRow>
              ) : discountPriorities?.length > 0 ? (
                <>
                  {discountPriorities.map(row => (
                    <TableRow key={row.name}>
                      <TableCell scope="row">
                        <Typography color={'primary.dark'}>{row.title}</Typography>
                      </TableCell>
                      <TableCell scope="row">
                        <Typography color={'gray'}>{row.businessName}</Typography>
                      </TableCell>
                      <TableCell scope="row">
                        <Typography color={'gray'}>{row.priority}</Typography>
                      </TableCell>
                      <TableCell>
                        <Stack direction="row" spacing={2}>
                          <Tooltip title={<Translate contentKey="entity.action.delete">Delete</Translate>}>
                            <IconButton
                              sx={{ color: 'transparent' }}
                              onClick={() => {
                                setDeletePriorityId(row.id);
                                setDeletePriorityOpen(true);
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  ))}
                </>
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <Box justifyContent={'center'} flexDirection={'column'} textAlign={'center'} display={'flex'} padding={'50px'}>
                      <Typography variant={'subtitle1'} color={'gray'}>
                        <Translate contentKey={'home.noData'} />
                      </Typography>
                    </Box>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <PriorityCreate open={createPriorityOpen} setOpen={setCreatePriorityOpen} type={type} />
      <PriorityDeleteDialog open={deletePriorityOpen} setOpen={setDeletePriorityOpen} id={deletePriorityId} />
    </Container>
  );
};

export default MobilePriorities;
