interface MenuLinkI {
  link: string;
  name: string;
  title?: string;
  onClickKey?: string;
  type?: string;
}

const MenuItems: MenuLinkI[] = [
  {
    link: '/',
    name: 'MENU.HOME',
    title: 'MENU.HOME',
    type: 'route',
  },
  {
    link: 'about',
    name: 'MENU.ABOUT',
    title: 'MENU.ABOUT',
  },
  {
    link: 'features',
    name: 'MENU.FEATURES',
    title: 'MENU.FEATURES',
  },
  {
    link: 'join-us',
    onClickKey: 'join-us',
    name: 'MENU.JOIN',
    title: 'MENU.JOIN',
  },
  {
    link: 'contact-us',
    onClickKey: 'contact-us',
    name: 'MENU.CONTACT',
    title: 'MENU.CONTACT',
  },
];

export { MenuItems };
export type { MenuLinkI };
