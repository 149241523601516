import React, { FC } from 'react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export const Partners: FC = () => {
  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up('md'));

  const partnerLogos: string[] = [
    'content/images/landing/Transport general authority.png',
    'content/images/landing/Riyadh Airports.png',
    'content/images/landing/NEOM.png',
    'content/images/landing/ministry of communications.png',
    'content/images/landing/General Entertainment Authority.png',
    'content/images/landing/Elm.png',
  ];
  return (
    <div
      style={{
        maxWidth: mdUp ? 'calc(100vw - 6rem)' : 'calc(100vw - 2rem)',
        marginBottom: '2rem',
      }}
    >
      {/* <Typography color={'primary'} fontSize={'xx-large'} marginTop={'8rem'} textAlign={'center'} marginBottom={'2rem'}> */}
      {/*   {translate('MENU.CLIENTS')} */}
      {/* </Typography> */}
      {/* <Swiper */}
      {/*   dir={isRTL ? 'rtl' : 'ltr'} */}
      {/*   autoplay={{ delay: 2000 }} */}
      {/*   modules={[Pagination, A11y]} */}
      {/*   spaceBetween={50} */}
      {/*   slidesPerView={1} */}
      {/*   navigation */}
      {/*   style={{ paddingBottom: '1rem' }} */}
      {/*   pagination={{ clickable: true }} */}
      {/*   breakpoints={{ */}
      {/*     480: { */}
      {/*       slidesPerView: 2, */}
      {/*     }, */}
      {/*     600: { */}
      {/*       slidesPerView: 3, */}
      {/*     }, */}
      {/*     1024: { */}
      {/*       slidesPerView: 4, */}
      {/*     }, */}
      {/*     1280: { */}
      {/*       slidesPerView: 6, */}
      {/*     }, */}
      {/*   }} */}
      {/* > */}
      {/*   {partnerLogos?.map((value: any, index) => { */}
      {/*     return ( */}
      {/*       <SwiperSlide key={index} style={{ paddingBlock: '1rem' }}> */}
      {/*         <div style={{ height: '80px', display: 'flex', justifyContent: 'center' }}> */}
      {/*           <img style={{ height: '100%', objectFit: 'cover' }} src={value} /> */}
      {/*         </div> */}
      {/*       </SwiperSlide> */}
      {/*     ); */}
      {/*   })} */}
      {/* </Swiper> */}
    </div>
  );
};
