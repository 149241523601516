import { IOffer } from 'app/shared/model/offer.model';
import { IBusiness } from 'app/shared/model/business.model';

export interface IBookmark {
  id?: number;
  offer?: IOffer | null;
  business?: IBusiness | null;
}

export const defaultValue: Readonly<IBookmark> = {};
