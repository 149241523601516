import { BreadcrumbsRoute, ChildrenPaths, ParentPaths, PathsObj } from '../interfaces/IPaths';
import { translate } from 'react-jhipster';
import { paths } from 'app/routes';

export const useCustomBreadcrumbs = () => {
  const routeHandler = (item: PathsObj) => {
    const { children, breadcrumb, pathname, ...rest } = item;
    let temp: BreadcrumbsRoute = { ...rest, children: [] };
    if (breadcrumb) {
      temp = { ...rest, breadcrumb: translate(breadcrumb) };
    }
    temp = { ...temp, path: pathname };
    if (children) {
      const newArray: BreadcrumbsRoute[] = Object.keys(children)
        .map(key => {
          const rest = children[key as ChildrenPaths];
          if (rest) {
            return routeHandler(rest);
          }
        })
        .filter(i => !!i);
      if (newArray && newArray.length > 0) {
        temp = { ...temp, children: newArray };
      }
    }
    return temp;
  };
  const routes = Object.keys(paths)
    .map(key => {
      const rest = paths[key as ParentPaths];
      if (rest) {
        return routeHandler(rest);
      }
    })
    .filter(i => !!i);
  return { routes };
};
