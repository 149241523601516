import dayjs from 'dayjs';
import { IBusiness } from 'app/shared/model/business.model';
import { IUserProfile } from 'app/shared/model/user-profile.model';
import { IStorage } from 'app/shared/model/storage.model';
import { IContact } from 'app/shared/model/contact.model';
import { ILocation } from 'app/shared/model/location.model';

export interface IGift {
  id?: number;
  description?: string | null;
  expirationDate?: string | null;
  enabled?: boolean | null;
  name?: string | null;
  business?: IBusiness | null;
  user?: IUserProfile | null;
  card?: IStorage | null;
  image?: IStorage | null;
  contact?: IContact | null;
  addresses?: ILocation[] | null;
}

export const defaultValue: Readonly<IGift> = {
  enabled: false,
};
