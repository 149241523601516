import React from 'react';
import { Box, Grid, Paper, useMediaQuery } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { LocaleMenu } from 'app/shared/layout/menus';
import { Storage } from 'react-jhipster';
import { setLocale } from 'app/shared/reducers/locale';
import { isRTL } from 'app/config/translation';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import Divider from '@mui/material/Divider';
import { useTheme } from '@mui/material/styles';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100vh',
    minHeight: '100%',
  },
  leftSection: {
    background: '#FCFCFC',
  },
  rightSection: {
    borderLeft: `1px solid #E0E0E0`,
  },
  backButton: {
    marginLeft: '2px',
  },
  card: {
    overflow: 'visible',
    display: 'flex',
    position: 'relative',
    margin: '10px 20px',
    width: '100%',
  },
  content: {
    padding: '20px',
    width: '100%',
  },
  forgot: {
    textDecoration: 'none',
    paddingLeft: '16px',
  },
  margin: {
    marginTop: '20px',
    marginBottom: '10px',
  },
  vec: {
    width: '500px',
    height: '500px',
  },
  vecMobile: {
    width: '50px',
    height: '50px',
  },
}));

const LoginLayout = ({ children }) => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const currentLocale = useAppSelector(state => state.locale.currentLocale);
  const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));

  const handleLocaleChange = event => {
    const langKey = currentLocale === 'en' ? 'ar-ly' : 'en';
    Storage.session.set('locale', langKey);
    dispatch(setLocale(langKey));
    document.querySelector('html').setAttribute('dir', isRTL(langKey) ? 'rtl' : 'ltr');
  };

  return (
    <Grid container className={classes.root}>
      <Grid container xs={0} sm={0} md={7} lg={7} className={classes.leftSection}>
        <Grid container display={'flex'} alignContent={'center'} justifyContent={'center'}>
          <Grid item>
            <img src={'content/images/o2e brand logo 2.svg'} alt="vec" className={matchUpMd ? classes.vec : classes.vecMobile} />
          </Grid>
        </Grid>
      </Grid>
      <Grid justifyContent={'flex-end'} container component={Paper} alignItems="flex-start" xs={12} sm={12} md={5} lg={5}>
        <Box className={'p-3'}>
          <LocaleMenu currentLocale={currentLocale} onClick={handleLocaleChange} />
        </Box>
        <Grid container alignItems="center" className={classes.card}>
          <Grid className={classes.content}>{children}</Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default LoginLayout;
