import { ICompany } from 'app/shared/model/company.model';
import { IUser } from 'app/shared/model/user.model';
import { IRole } from 'app/shared/model/role.model';
import { Gender } from 'app/shared/model/enumerations/gender.model';

export interface IUserProfile {
  id?: number;
  phoneNumber?: string | null;
  address?: string | null;
  deleted?: boolean | null;
  enabled?: boolean | null;
  gender?: Gender | null;
  age?: number | null;
  company?: ICompany | null;
  user?: IUser | null;
  role?: IRole | null;
}

export const defaultValue: Readonly<IUserProfile> = {
  deleted: false,
  enabled: false,
};
