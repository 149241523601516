import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import CompanyAdmin from './company-admin';

const CompanyAdminRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<CompanyAdmin />} />
  </ErrorBoundaryRoutes>
);

export default CompanyAdminRoutes;
