import React from 'react';
import { PublicLayout } from 'app/components/PublicLayout';
import { Container } from '@mui/material';

export const Terms = () => {
  return (
    <PublicLayout>
      <Container style={{ flex: '1', textAlign: 'justify', lineHeight: 'initial' }}>
        <h4 style={{ textAlign: 'center' }} className="pt-5 pb-5">
          الشروط والأحكام الخاصة بالموردين
        </h4>
        <p>
          يقدم O2E (يُشار إليه هنا فيما بعد O2E، أو &quot;نحن&quot;، أو &quot;ضمير الملكية نا&quot;) تطبيقاً يسمح للشركات بتقديم مكافآت
          ونقاط الولاء للعملاء مباشرة عن طريق تطبيق ذكي (يُشار إليها هنا فيما بعد بـ&quot;الخدمات&quot;)، كما وتقدم للشركات برامج وتطبيقات
          تمكِّنا من تتبع استخدام العملاء لتلك العروض والمكافآت (يُشار إليها هنا فيما بعد باسم &quot;التطبيقات&quot;). وتنطبق الشروط
          والأحكام الخاصة بالموردين (يُشار إليها هنا فيما بعد بـ&quot;الشروط&quot;) على وصولك واستخدامك للخدمات والتطبيقات.
          و&quot;المورد&quot; يعني أي كيان توريد ووكلائه الذين يستخدمون التطبيقات أو يوفرون الخدمات لعملائه.
        </p>

        <h5 className="pt-4">التعريفات</h5>
        <p>
          تعني المصطلحات التالية المكتوبة بحروف كبيرة المعاني المنصوص عليها في هذا القسم. "العميل" يعني المستخدم النهائي الذي حصل على ترخيص
          من O2E لاستخدام هذه الخدمات و وافق على أحكام وشروط الاستخدام التي وضعتها O2E. "شروط وأحكام استخدام O2E" تعني اتفاقية ترخيص
          المستخدم النهائي القياسي O2E، والتي يمكن تعديلها من قبل O2E وفقًا لتقديره الخاص والتي يتوفر النسخة الحالية منه هنا. "المحتوى
          الترويجي." يعني أي محتوى إعلاني أو ترويجي أو تسويقي يوفره صاحب المتجر لعرضه على أي عميل (بما في ذلك على سبيل المثال لا الحصر، أي
          برنامج نقاط أو مكافآت او عروض) بما في ذلك أي شعارات أو أسماء تجارية أو علامات تجارية أو أي محتوى آخر للبائع يتم إدراجه في هذا
          المحتوى.
        </p>

        <h5 className="pt-4">الترخيص</h5>
        <p>
          يخضع للبنود والشروط الموضحة في هذه الاتفاقية، وتظل بنود هذه الاتفاقية سارية المفعول خلال هذه الفترة. ويمنحO2E صاحب المتجر بموجب
          هذه الاتفاقية ترخيصًا محدودًا لاستخدام التطبيقات وتقديم الخدمات لعملائه ويكون هذا الترخيص محدودًا وقابلًا للإلغاء وغير حصري وغير
          قابل للنقل ولا يستطيع صاحب المتجر بموجبه منح ترخيص من الباطن لآخرين.
        </p>

        <h5 className="pt-4">القيود</h5>
        <p>
          يحتفظ صاحب المتجر بجميع إشعارات حقوق الطبع والنشر وغيرها من إشعارات الملكية الواردة في التطبيقات والخدمات. وباستثناء ما هو منصوص
          عليه صراحةً في هذه الوثيقة، لا يجوز للبائع ولا يسمح صاحب المتجر لأي طرف ثالث، (أ) بالتوجيه العكسي أو محاولة العثور على الكود
          الأساسي للتطبيقات أو الخدمات؛ (ب) تعديل التطبيقات أو الخدمات أو إدخال أي رمز أو منتج، أو التلاعب بالتطبيقات أو الخدمات بأي طريقة
          أخرى؛ أو (ج) تعديل أو تأطير أو إعادة إنتاج أو أرشفة أو بيع أو استئجار أو تبادل أو إنشاء أعمال مشتقة من هذه التطبيقات أو نشر نسخ
          مطبوعة أو بوسائل إلكترونية أو أدائها أو عرضها علنًا أو نشرها أو توزيعها أو بثها أو إعادة إرسالها أو ترويجها لأي طرف ثالث أو على أي
          موقع إلكتروني لطرف آخر أو استخدم التطبيقات أو الخدمات بطريقة تخالف الطريقة المسموح بها في هذه البنود صراحةً. وبالحد الذي تكون فيه
          أي من القيود المنصوص عليها في هذا القسم غير قابلة للتنفيذ بموجب القانون المعمول به، يجب على صاحب المتجر إشعار O2E خطيًا كل مرة قبل
          الانخراط في الأنشطة المبينة أعلاه. وتحتفظ O2E بحق إجراء تعديلات على التطبيقات والخدمات في أي وقت دون إشعار مسبق.
        </p>

        <h5 className="pt-4">المحتوى الترويجي</h5>
        <p>
          يجوز للبائع أن يقدم محتوى ترويجيًا للعملاء من خلال هذه الخدمات وفقًا لما تسمح به O2Eويتحمل صاحب المتجر المسؤولية الكاملة عن أداء
          المحتوى الترويجي بالكامل أو دقته أو موثوقيته أو نزاهته أو جودته أو صلاحيته. ويمثل صاحب المتجر ويضمن أنه لا يوجد محتوى ترويجي ينتهك
          حقوق الملكية الفكرية أو الحقوق الأدبية أو حقوق أي طرف آخر. وتحتفظ O2E بحقها، وفقًا لتقديرها الخاص، في حذف أي محتوى ترويجي ينتهك
          هذه البنود في أي وقت دون إشعار مسبق أو تطلب من صاحب المتجر القيام بذلك. ويظل صاحب المتجر مسئولاً وحده عن أي نقاط أو مكافآت مقدمة
          للعملاء ويجب عليه تعويض O2E بالكامل في حالة إخفاقه في الوفاء بأي من التزاماته بموجب هذه النقاط أو المكافآت. ولا تطالب O2E بملكية
          المحتوى الترويجي. ومع ذلك، يمنح صاحب المتجر O2E ترخيصًا عالميًا غير حصري وخال من حقوق الملكية لجميع حقوق استخدام المحتوى الترويجي
          وتعديل وتغيير ودمج وتضمين وتخصيص وتسجيل وإعادة إنتاج المحتوى الترويجي بما يحقق أغراض هذه البنود، بما في ذلك الحق في ممارسة هذا
          الترخيص من خلال مقاولين مستقلين.
        </p>

        <h5 className="pt-4">الملكية الفكرية</h5>
        <p>
          ملكية O2Eتملك شركة كفاءات حق الملكية وجميع حقوق الملكية لـ أو المتعلقة بـ (أ) التطبيق والخدمات والوثائق ذات الصلة وجميع التعزيزات
          والمشتقات وإصلاحات الأخطاء أو التحسينات على النسخة السابقة؛ (ب) جميع الأسماء التجارية والعلامات التجارية وشعارات O2E و (ج) تظل
          جميع البيانات التي يتم جمعها من خلال برنامج خادم O2E فيما يتعلق بالنسخة السابقة مجتمعة ،) "ملك O2E") لـ O2E فقط أو مرخصيها في جميع
          الأوقات.
        </p>

        <h5 className="pt-4">ملكية صاحب المتجر</h5>
        <p>
          يظل حق الملكية وجميع حقوق الملكية لـ أو المتعلقة بالمحتوى الترويجي وجميع الأسماء التجارية والعلامات التجارية والشعارات الخاصة
          بصاحب المتجر (مجتمعة، "ملك صاحب المتجر" ) للبائع أو مرخصيه في جميع الأوقات. ليس هناك بيع. تُعد أي إشارة لبيع أو إعادة بيع أو شراء
          ملكية O2E أو ملكية صاحب المتجر لهذه البنود والوثائق ذات الصلة أو أي إشارات لأثر مماثل بمثابة ترخيص بذلك وفقًا لأحكام وشروط هذه
          الشروط. بيانات العميل. ستزود O2E صاحب المتجر ببعض البيانات المحددة المتعلقة بالعملاء، بما في ذلك اسم العميل وبعض البيانات
          المتبادلة المتفق عليها الخاصة بأنشطة العميل في موقع عمل صاحب المتجر ("بيانات العميل"). ويوافق صاحب المتجر على أن يلتزم بسياسة
          الخصوصية لدى O2E وكافة القوانين والقواعد واللوائح المعمول بها فيما يتعلق بجميع بيانات العميل وجميع استخداماتها في جميع الأوقات.
          ويقوم صاحب المتجر بتعويض O2E بصورة كاملة (ومسئولي ومديري وموظفي ومستشاري O2E) عن أي انتهاك لالتزاماتها بموجب هذا البند. وقد تشمل
          بيانات العميل: اسم العميل وعدد الزيارات وتفاصيل الزيارات واستخدام العروض. الدعم. في حالة الحاجة لأي مساعدة تتعلق بالتطبيقات، يمكن
          للعملاء الاتصال بـ O2E باستخدام البريد الإلكتروني علىo2e@kbs.sa أو باستخدام صور التواصل الأخرى الموضحة على موقعنا الإلكتروني.
        </p>

        <h5 className="pt-4">السرية</h5>
        <p>
          يقر صاحب المتجر بأنه يجوز O2E أن تفصح للبائع عن بعض المعلومات السرية الخاصة بـ أو المتعلقة O2E أو التطبيقات أو الخدمات. ويجوز
          للبائع الإفصاح لـ O2E عن معلومات محددة تتعلق بأعماله وعملياته. ويتفق الطرفان، ("الطرف المتلقي") الذي يتلقى معلومات من الطرف الآخر
          و("الطرف المفصح") الذي يحدد أو يصف معلومات محددة بأنها سرية أو تتعلق بالملكية أو يجب اعتبارها بوضوح معلومات سرية وخاصة (يُطلق
          عليها مجتمعة اسم "معلومات سرية" )، على الحفاظ على سرية هذه المعلومات السرية خلال مدة سريان الاتفاقية وبعدها وعدم الكشف عن هذه
          المعلومات أو استخدامها إلا فيما يحقق التزامات الطرف المتلقي أدناه. ولا يجب أن تتضمن المعلومات السرية معلومات (1) معروفة بصورة
          قانونية أو قام الطرف المتلقي بتطويرها وإنشائها بصورة قانونية دون الوصول إلى أو استخدام المعلومات السرية الخاصة بالطرف المُفصح، أو
          (2) تلقاها الطرف المتلقي من أي طرف آخر دون قيود، أو (3) متاحة للعموم بدون قيود السرية؛ أو (4) يلزم الإفصاح عنها بموجب القانون، على
          أن يقدم الطرف المتلقي إشعارًا فوريًا بهذا الطلب. ويلتزم موظفو الطرف المتلقي والعاملون لديه الذين يحتاجون إلى الإلمام بهذه
          المعلومات التي يجب الالتزام بسريتها خطيًا بتقييد لا يقل عن المنصوص عليه في هذه الوثيقة بتقييد إفشاء المعلومات السرية. ولا يجب على
          صاحب المتجر إفشاء أي معلومات تتعلق بنتائج أي اختبار أو تقييم للتطبيقات أو الخدمات لأي جهة أخرى دون موافقة خطية مسبقة من O2E .
        </p>

        <h5 className="pt-4">الضمانات؛ التعويض؛ إخلاء المسئولية.</h5>

        <h5 className="pt-4">ضمانات صاحب المتجر</h5>
        <p>
          يمثل صاحب المتجر ويضمن أن المحتوى الترويجي الذي يقدمه: (أ) لا يحتوي على مواد فاحشة أو فاضحة أو افترائية أو غير مراعية للأعراق
          والاثنيات أو مزعجة أو مهددة أو مسيئة بأي طريقة أخرى؛ (ب) لا يحتوي على مواد إباحية أو مواد قد تكون ضارة بالقصر؛ (ج) لا يحتوي على
          مواد تنتهك أو تخالف حقوق الملكية الفكرية أو الحقوق الأدبية أو التجارية لأي طرف ثالث؛ (د) لا يحتوي على أي فيروس أو أي مكون ضار أو
          تخريبي؛ و (هـ) يتوافق مع جميع القوانين واللوائح والقواعد المعمول بها، بما في ذلك جميع القوانين المعمول بها فيما يتعلق بالإعلان
          والتسويق والمحتوى والخدمات الترويجية.
        </p>

        <h5 className="pt-4">إخلاء المسئولية</h5>
        <p>
          يتم توفير التطبيقات والخدمات والوثائق ذات الصلة "كما هي". وتخلي O2E مسئوليتها صراحًة عن أي ضمانات (يشمل ذلك ما يتعلق بأداء التطبيق
          أو الخدمات، بما في ذلك علي سبل المثال لا الحصر الضمانات الصريحة أو الضمنية الخاصة بالتسويق أو الملاءمة لغرض معين أو حق الملكية أو
          عدم الانتهاك، علي أن يكون أي اعتماد على التطبيقات أو الخدمات على مسئولية صاحب المتجر، ولا تتحمل O2E ولن تتحمل أي مسؤولية عن
          التطبيقات أو الخدمات، بما في ذلك على سبيل المثال لا الحصر أي أخطاء أو مغالطات في نقاط الولاء أو المكافآت التي نشأت أو توفرت من
          خلال التطبيق أو الخدمات.
        </p>

        <h5 className="pt-4">تحديد المسؤولية</h5>
        <p>
          لا تتحمل شركة O2E أو مديروها أو مسئولوها أو موظفوها أو وكلاؤها أو المساهمون فيها تحت أي ظرف من الظروف أي مسؤولية تجاه صاحب المتجر
          أو أي عميل أو أي طرف ثالث عن أي تعويضات، بما في ذلك على سبيل المثال لا الحصر، التعويضات العامة أو العرضية أو المترتبة أو غير
          المباشرة أو المباشرة أو الخاصة أو الجزائية التي تنشأ عن أو تتعلق بالتطبيق أو الخدمات. ولا يجب بأي حال أن تتجاوز المسؤولية الكاملة
          لشركة O2E بموجب أي حكم من هذه الاحكام في مجملها المبلغ الذي استلمه من صاحب المتجر، باستثناء حالات الوفاة أو الإصابة الناجمة عن
          الإهمال الجسيم من قبل O2E بالحد الذي يحظر به القانون المعمول به تقييد التعويضات في مثل هذه الحالات.
        </p>

        <h5 className="pt-4">الترويج والدعاية والإعلان</h5>
        <p>
          يجب أن تتوافق جميع العروض الترويجية والدعاية والإعلان وتوزيع التطبيق والخدمات مع سياسات وسمعة O2E القياسية. وقد يتفق الطرفان خطيًا
          على أن يدرج كل منهما إشارة إلى الطرف الآخر وشعاره على موقعه الإلكتروني، والمواد التسويقية والترويجية (بأي وسيلة) علي أن يمتثل كل
          طرف لإرشادات العلامات التجارية المعمول بها لدى الطرف الآخر. وقد يتفق الطرفان على إصدار بيان صحفي مشترك بصيغة متفق عليها بين
          الطرفين حيث يتم الإعلان عن قيام علاقة بينهما. ويجب أن يتفق الطرفان على نص هذا البيان الصحفي وعلي نص الإعلانات وكذلك علي نص أي
          إعلان أو منشور مستقبلي يتعلق بهيكل أو شروط هذه الاتفاقية قبل أن يتم النشر، إلا أنه يجوز لأي من الطرفين إعادة نشر أي معلومات واردة
          في بيان صحفي تمت الموافقة عليه مسبقًا، بما في ذلك النشر على الموقع الإلكتروني لهذا الطرف وكذلك مواده التسويقية. تبدأ مدة سريان هذا
          العقد ("مدة السريان") من تاريخ قبول صاحب المتجر لهذه الأحكام ويستمر لمدة سنة واحدة ميلادية.
        </p>

        <h5 className="pt-4">الفسخ</h5>
        <p>
          يجوز لأي من الطرفين فسخ هذه الاتفاقية بإشعار مسبق مدته 30 يومًا، علي أنه يحق لـ O2E فسخ هذه الشروط وجميع الحقوق الممنوحة بموجب هذه
          الاتفاقية دون سابق إنذار إذا كان صاحب المتجر سيقوم باستخدام أو تسويق التطبيق أو الخدمات بما يخالف أي قوانين أو قواعد أو لوائح
          معمول بها.
        </p>

        <h5 className="pt-4">أثر الفسخ</h5>
        <p>
          عند انقضاء أو فسخ هذه الشروط لأي سبب من الأسباب: (أ) يتوقف صاحب المتجر فورًا عن مباشرة الحقوق الممنوحة بموجب هذه الاتفاقية، ويتوقف
          فورًا عن تقديم الخدمات لعملائه؛ (ب) يسدد كل طرف للطرف الآخر جميع المبالغ المستحقة بموجب هذه الاتفاقية من تاريخ الفسخ؛ (ج) يجب أن
          يتوقف صاحب المتجر عن استخدام جميع ممتلكات O2E، وأن يقوم بإرجاع جميع نسخ المعلومات السرية O2E فورًا أو يتلفها وفقًا لتقدير O2E
          وحدها.
        </p>

        <h5 className="pt-4">استمرار البنود بعد الفسخ</h5>
        <p>
          تستمر البنود (القيود)، (الملكية الفكرية)، (بيانات المستخدم)، (السرية)، (الضمانات؛ التعويض؛ إخلاء المسئولية)، (حدود المسؤولية)
          وكذلك أي مدفوعات مستحقة وغير مدفوعة ، وكذلك هذا البند بعد فسخ أو انقضاء هذه الشروط لأي سبب. اتفاقية صاحب المتجر (تشير كلمة "أنت"
          إلى المستخدم كما هو محدد في البند 1 بهذه الوثيقة). ولكي تتمكن من إدارة برنامج الولاء الخاص بك، سيتم تزويدك بما يلي: أ) الدخول على
          لوحة القيادة الإلكترونية، والتي يمكنك من خلالها تتبع وتعديل مختلف سمات البرنامج. ب) جهاز أندرويد (هاتف أو لوحي) لكل موقع مسجل لدى
          O2E. وبالتوقيع أدناه، فإنك توافق على تشغيل برنامج الولاء الخاص بك بطريقة نزيهة وسليمة وعليك أن تدرك أنه في الوقت الذي تمنحك فيه
          O2E إمكانية الدخول على منصتها، إلا أن مسؤولية إدارة برنامج الولاء الخاص بك تقع على عاتقك. التسعير: يوافق المستخدم على نقاط التسعير
          الخاصة بـ O2E حسب ما عرضه عليك مندوب المبيعات أو أي ممثل مفوض من O2E. أحكام متنوعة: تُعد كل من O2E وصاحب المتجر أطراف مستقلة و
          يعتزمون البقاء كذلك. ولا يجب اعتبار أو تفسير أي شيء مُضمّن في هذه الاتفاقيات على أنه يُنشئ علاقة بين موكل ووكيل أو شراكة أو مشروع
          مشترك، وفيما عدا ما يتفق الطرفان عليه تحديدًا بصورة خطية بخلاف ذلك، لا يجوز لأي من الطرفين اعتبار نفسه وكيلًا أو ممثلًا قانونيًا
          أو شريكًا أو شركة تابعة أو شريك بمشروع مشترك أو مُستخدم أو موظف لدى الطرف الآخر. ولا يجب أن يكون لأي من الطرفين أو لأي مسئول أو
          موظف تابع له، تحت أي ظرف ، أي حق بصورة تضامنية أو فردية في إلزام الطرف الآخر بتقديم أي تمثيلات أو ضمانات، أو قبول الخدمة، أو
          استلام إشعارات، أو القيام بأي تصرف أو شيء نيابةً عن الطرف الآخر، ما عدا ما يفوضه به هذا الطرف الآخر خطيًا ووفقًا لتقديره الخاص.
          وتمثل هذه الأحكام الاتفاق الكامل بين الطرفين فيما يتعلق بموضوعها وتلغي وتحل محل جميع الاتفاقيات الأخرى بين الطرفين ، سواء كانت
          مكتوبة أو شفوية ، فيما يتعلق بموضوعها. ويجوز توقيع هذه الأحكام من نسختين أو أكثر، ويُعد كل منها أصلًا ، وتشكل جميعها نفس المستند
          الواحد. ولا يُعد التنازل عن أي تقصير بموجب هذه الشروط أو بموجب أي من شروط وأحكام هذه البنود تنازلًا مستمرًا أو تنازلًا عن أي تقصير
          أخرى أو عن أي حكم أو شرط آخر، ولكن ينطبق فقط على الحالة المعني بها هذا التنازل. كما أنه يجوز لO2E بموجب هذه الشروط التنازل عن
          حقوقها أو التزاماتها. ويوفق صاحب المتجر وفقا لهذه الشروط على عدم التنازل عن أي حقوق؛ وتُعد أي محاولة للتنازل لاغية وباطلة وتؤدي
          إلى فسخ هذه الشروط. وإذا كان أي جزء من هذه الشروط باطلًا أو غير قابل للتنفيذ ، فلن يؤثر هذا البطلان أو عدم القابلية للتنفيذ على
          السريان أو قابلية التنفيذ لأي فقرة أو بند آخر من هذه الشروط وتظل تامة السريان والأثر بصورة تامة. وتخضع هذه الشروط لقوانين المملكة
          العربية السعودية، وتكون للمحاكم المختصة في المملكة العربية السعودية الاختصاص القضائي الحصري للنظر في أي نزاعات تنشأ بموجب هذه
          الشروط.
        </p>
      </Container>
    </PublicLayout>
  );
};

export default Terms;
