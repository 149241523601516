import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { translate, Translate } from 'react-jhipster';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { changePassword, getEntity } from '../employee/employee.reducer';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  FilledInput,
  FormControl,
  Divider,
  FormLabel,
  Typography,
  FormHelperText,
} from '@mui/material';
import { Formik, useFormikContext } from 'formik';
import useScriptRef from 'app/hooks/useScriptRef';
import { useSnackbar } from 'notistack';
import { DangerIcon } from 'app/components/icons';
import * as Yup from 'yup';

export const CompanyAdminChangePassword = ({ open, setOpen, id }) => {
  const dispatch = useAppDispatch();
  const scriptedRef = useScriptRef();
  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();

  const isNew = id === undefined;
  const loading = useAppSelector(state => state.employee.loading);
  const updating = useAppSelector(state => state.employee.updating);
  const updateSuccess = useAppSelector(state => state.employee.updateSuccess);
  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const [entity, setEntity] = React.useState<any>();

  const handleClose = () => {
    setOpen(false);
  };
  const handleConfirmClose = () => {
    setConfirmOpen(false);
  };

  useEffect(() => {
    if (updateSuccess && open) {
      handleClose();
      handleConfirmClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    const e = {
      id,
      password: values.password,
    };
    setEntity(e);
    setConfirmOpen(true);
  };

  const save = async () => {
    const {
      meta: { requestStatus },
    } = await dispatch(changePassword(entity));
    if (requestStatus === 'fulfilled') {
      enqueueSnackbar(translate('kafaatApp.companyAdmin.changePasswordSuccess'), { variant: 'success' });
    }
  };

  return (
    <>
      {(isNew || (!isNew && !loading)) && (
        <Dialog maxWidth={'sm'} fullWidth open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <Formik
            validationSchema={Yup.object().shape({
              password: Yup.string().max(50).required(translate('error.form.required')),
            })}
            initialValues={{ password: undefined }}
            onSubmit={(values, { setErrors, setStatus, setSubmitting }) => {
              try {
                saveEntity(values);

                if (scriptedRef.current) {
                  setStatus({ success: true });
                  setSubmitting(false);
                  handleClose();
                }
              } catch (err) {
                console.error(err);
                if (scriptedRef.current) {
                  setStatus({ success: false });
                  setSubmitting(false);
                }
              }
            }}
          >
            {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, setFieldValue, touched, values }) => (
              <form noValidate onSubmit={handleSubmit}>
                <DialogTitle id="form-dialog-title" color={'secondary'}>
                  <Translate contentKey="kafaatApp.employee.changePassword">Change Password</Translate>
                </DialogTitle>
                <Divider />
                <DialogContent>
                  <FormControl fullWidth margin="normal" error={Boolean(touched.password && errors.password)}>
                    <FormLabel>
                      <Translate contentKey="kafaatApp.employee.newPassword">New Password </Translate>
                    </FormLabel>
                    <FilledInput
                      id="employee-new-password"
                      type={'text'}
                      name="password"
                      value={values.password}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {touched.password && errors.password && (
                      <FormHelperText error id="standard-weight-helper-text">
                        {errors.password.toString()}
                      </FormHelperText>
                    )}
                  </FormControl>
                  <br /> <br />
                </DialogContent>
                <DialogActions>
                  <Button size={'large'} onClick={handleClose} variant="outlined" color={'secondary'}>
                    <Translate contentKey="kafaatApp.business.home.cancel">Cancel</Translate>
                  </Button>
                  <Button size={'large'} color="primary" variant="contained" type={'submit'}>
                    <Translate contentKey="kafaatApp.business.home.save">Save</Translate>
                  </Button>
                </DialogActions>
              </form>
            )}
          </Formik>
        </Dialog>
      )}

      <Dialog open={confirmOpen} onClose={handleConfirmClose} maxWidth={'md'} fullWidth>
        <DialogContent sx={{ p: 4, justifyContent: 'center' }}>
          <Typography variant={'h6'} color={'#E02020'} textAlign="center" mb={2}>
            <DangerIcon sx={{ color: 'transparent' }} />
            <br />
            <Translate contentKey="kafaatApp.employee.home.changePasswordConfirmation"> Confirmation</Translate>
          </Typography>
          <Typography color={'secondary'} textAlign="center">
            <Translate contentKey="kafaatApp.employee.changePasswordConfirmMsg"> Confirm?</Translate>
          </Typography>
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: 'center',
            mb: 2,
          }}
        >
          <Button size={'large'} color="primary" variant="contained" onClick={() => save()}>
            <Translate contentKey="kafaatApp.employee.home.confirm">Confirm</Translate>
          </Button>
          <Button size={'large'} onClick={handleConfirmClose} variant="outlined" color={'secondary'}>
            <Translate contentKey="kafaatApp.business.home.cancel">Cancel</Translate>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CompanyAdminChangePassword;
