import React, { FC } from 'react';
import { Typography, useMediaQuery, useTheme } from '@mui/material';
import { translate } from 'react-jhipster';

export const DownloadAppCard: FC = () => {
  const theeme = useTheme();

  const mdUp = useMediaQuery(theeme.breakpoints.up('md'));
  const lgUp = useMediaQuery(theeme.breakpoints.up('lg'));
  const smUp = useMediaQuery(theeme.breakpoints.up('sm'));
  const tabletUp = useMediaQuery(theeme.breakpoints.up('tablet'));
  const phoneUp = useMediaQuery(theeme.breakpoints.up('largePhone'));

  return (
    <div
      style={{
        borderRadius: '3rem',
        height: '250px',
        paddingInline: tabletUp ? '3rem' : '1rem',
        background: '#eae3dd',
        display: 'flex',
        justifyContent: tabletUp ? 'flex-start' : 'center',
        alignItems: 'center',
        position: 'relative',
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'column', paddingInlineStart: mdUp ? '3rem' : '0' }}>
        <Typography zIndex={1} fontSize={'x-large'} mb={3} color={'primary'}>
          {translate('MENU.DOWNLOAD')}
        </Typography>
        {/*<Typography zIndex={1} >*/}
        {/*  Download app description*/}
        {/*</Typography>*/}
        <div
          style={{
            display: 'flex',
            flexDirection: phoneUp ? 'row' : 'column',
            alignItems: phoneUp ? 'start' : 'center',
            gap: '1rem',
            zIndex: 1,
          }}
        >
          <a href={'https://play.google.com/store/apps/details?id=com.kafaat.loyalty'}>
            <img height={48} width={143} src={'content/images/landing/Google Play Badge@2x.png'} />
          </a>
          <a href={'https://apps.apple.com/sa/app/o2e/id6451219521'}>
            <img height={48} width={143} src={'content/images/landing/App Store Badge@2x.png'} />
          </a>
        </div>
      </div>
      {tabletUp && (
        <div
          style={{
            position: 'absolute',
            inset: 0,
            overflow: 'hidden',
            display: 'flex',
            zIndex: 0,
            justifyContent: 'flex-end',
            alignItems: 'flex-end',
          }}
        >
          <img
            style={{ height: '200px', objectFit: 'cover', marginInlineEnd: lgUp ? '15rem' : mdUp ? '10rem' : '4rem' }}
            src={'content/images/landing/Group 17095.png'}
          />
        </div>
      )}
    </div>
  );
};
