import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { ICompany, defaultValue } from 'app/shared/model/company.model';
import { IBusiness } from 'app/shared/model/business.model';

const initialState: EntityState<ICompany> = {
  loading: false,
  errorMessage: null,
  entities: [],
  allEntities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const apiUrl = 'api/companies';

// Actions

export const getEntities = createAsyncThunk(
  'company/fetch_entity_list',
  async ({ page, size, sort, name, typeId, enabled }: IQueryParams) => {
    const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}&` : '?'}cacheBuster=${new Date().getTime()}${
      name ? `&name=${name}` : ''
    }${typeId !== undefined ? `&typeId=${typeId}` : ''}${enabled !== undefined ? `&enabled=${enabled}` : ''}`;
    return axios.get<ICompany[]>(requestUrl);
  }
);

export const getAllEntities = createAsyncThunk('company/fetch_all_entity_list', async () => {
  return axios.get<ICompany[]>(`${apiUrl}/list`);
});

export const getEntity = createAsyncThunk(
  'company/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<ICompany>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const getEntityUsers = createAsyncThunk(
  'company/fetch_entity_users',
  async ({ id, name, email, type, relatedToUseEmail, page, size, sort }: IQueryParams) => {
    const params = new URLSearchParams();
    if (name) {
      params.append('name', name);
    }
    if (email) {
      params.append('email', email);
    }
    if (type) {
      params.append('type', type);
    }
    if (relatedToUseEmail) {
      params.append('relatedToUseEmail', relatedToUseEmail);
    }
    const requestUrl = `api/admin/company/${id}/users`;
    return axios.get<any[]>(`${requestUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}&` : '?'}cacheBuster=${new Date().getTime()}`, {
      params,
    });
  },
  { serializeError: serializeAxiosError }
);

export const getRedeemsLogs = createAsyncThunk(
  'company/fetch_entity_redeemsLogs',
  async ({ id, page, size, sort }: IQueryParams) => {
    const requestUrl = `api/offer-logs/company/${id}`;
    return axios.get<any[]>(`${requestUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}&` : '?'}cacheBuster=${new Date().getTime()}`);
  },
  { serializeError: serializeAxiosError }
);

export const getRelatedToFilterList = createAsyncThunk(
  'company/fetch_entity_users_filter',
  async (id: string) => {
    const requestUrl = `api/admin/company/${id}/users/filter`;
    return axios.get<any[]>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  'company/create_entity',
  async (entity: any, thunkAPI) => {
    const config = {
      headers: { 'Content-Type': 'multipart/form-data' },
    };
    const result = await axios.post<ICompany>(apiUrl, entity, config);
    // thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'company/update_entity',
  async (entity: any, thunkAPI) => {
    const config = {
      headers: { 'Content-Type': 'multipart/form-data' },
    };

    const result = await axios.put<ICompany>(`${apiUrl}/${entity.id}`, entity.values, config);
    // thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
  'company/partial_update_entity',
  async (entity: ICompany, thunkAPI) => {
    const result = await axios.patch<ICompany>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const enableDisableEntity = createAsyncThunk(
  'company/partial_update_entity',
  async ({ id, enabled }: any, thunkAPI) => {
    const result = await axios.patch<IBusiness>(`${apiUrl}/${id}?enabled=${enabled}`);
    // thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  'company/delete_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await axios.delete<ICompany>(requestUrl);
    // thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

// slice

export const CompanySlice = createEntitySlice({
  name: 'company',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        const { data, headers } = action.payload;

        return {
          ...state,
          loading: false,
          entities: data,
          totalItems: parseInt(headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(getEntityUsers), (state, action) => {
        const { data, headers } = action.payload;

        return {
          ...state,
          loading: false,
          entityUsers: data,
          totalItems: parseInt(headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(getRedeemsLogs), (state, action) => {
        const { data, headers } = action.payload;

        return {
          ...state,
          loading: false,
          redeemsLogs: data,
          totalredeemsLogs: parseInt(headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(getRelatedToFilterList), (state, action) => {
        const { data } = action.payload;

        return {
          ...state,
          loading: false,
          relatedToFilterList: data,
        };
      })
      .addMatcher(isFulfilled(getAllEntities), (state, action) => {
        return {
          ...state,
          loading: false,
          allEntities: action.payload.data,
        };
      })
      .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity, enableDisableEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isPending(getEntities, getEntity, getEntityUsers), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity, enableDisableEntity, deleteEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { reset } = CompanySlice.actions;

// Reducer
export default CompanySlice.reducer;
