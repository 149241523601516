import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import Gift from './gift';
import GiftDetail from './gift-detail';
import GiftUpdate from './gift-update';
import GiftDeleteDialog from './gift-delete-dialog';

const GiftRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<Gift />} />
  </ErrorBoundaryRoutes>
);

export default GiftRoutes;
