import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Translate, translate } from 'react-jhipster';

import { convertDateTimeFromServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { createEntity, reset } from './notification.reducer';
import {
  Autocomplete,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FilledInput,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  TextField,
} from '@mui/material';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import useScriptRef from 'app/hooks/useScriptRef';
import { useSnackbar } from 'notistack';
import { getAllEntities as getAllEmployees } from 'app/entities/employee/employee.reducer';
import moment from 'moment';
import { CheckBox } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';

export const NotificationCreate = ({ open, setOpen }) => {
  const dispatch = useAppDispatch();
  const scriptedRef = useScriptRef();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [allUsers, setAllUsers] = useState(false);

  const isNew = true;

  const notificationEntity = useAppSelector(state => state.notification.entity);
  const loading = useAppSelector(state => state.notification.loading);
  const updating = useAppSelector(state => state.notification.updating);
  const updateSuccess = useAppSelector(state => state.notification.updateSuccess);
  const allEmployees = useAppSelector(state => state.employee.allEntities);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    dispatch(reset());
    dispatch(getAllEmployees());
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = async values => {
    values.sendDate = moment(values.sendDate).toISOString();
    values.targetUsers = allUsers ? [] : values.targetUsers.map(a => a.id);
    const entity = {
      ...notificationEntity,
      ...values,
    };
    const {
      meta: { requestStatus },
    } = await dispatch(createEntity(entity));
    if (requestStatus === 'fulfilled') {
      enqueueSnackbar(translate('kafaatApp.notification.createSuccess'), { variant: 'success' });
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          sendDate: displayDefaultDateTime(),
        }
      : {
          ...notificationEntity,
          sendDate: convertDateTimeFromServer(notificationEntity.sendDate),
        };

  return (
    <Dialog maxWidth={'md'} fullWidth open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title" color={'secondary'}>
        <Translate contentKey="kafaatApp.notification.home.createLabel">createLabel</Translate>
      </DialogTitle>
      <Divider />
      <Formik
        initialValues={{
          title: undefined,
          content: undefined,
          sendDate: null,
          targetUsers: [],
          all_Users: allUsers,
        }}
        validationSchema={Yup.object().shape({
          title: Yup.string().required(translate('error.form.required')),
          content: Yup.string().required(translate('error.form.required')),
          sendDate: Yup.string().nullable().required(translate('error.form.required')),
          all_Users: Yup.boolean(),
          targetUsers: Yup.array().when('all_Users', (all_Users, schema) =>
            all_Users ? schema : schema.min(1, translate('error.form.required')).required(translate('error.form.required'))
          ),
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            await saveEntity(values);

            if (scriptedRef.current) {
              setStatus({ success: true });
              setSubmitting(false);
              handleClose();
            }
          } catch (err) {
            console.error(err);
            if (scriptedRef.current) {
              setStatus({ success: false });
              setSubmitting(false);
            }
          }
        }}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, setFieldValue, touched, values }) => (
          <form noValidate onSubmit={handleSubmit}>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControl fullWidth margin="normal" error={Boolean(touched.title && errors.title)}>
                    <FormLabel>
                      <Translate contentKey="kafaatApp.notification.title">title </Translate>
                    </FormLabel>
                    <FilledInput type={'text'} name="title" onBlur={handleBlur} onChange={handleChange} value={values.title} />
                    {touched.title && errors.title && (
                      <FormHelperText error id="standard-weight-helper-text">
                        {errors.title.toString()}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth margin="normal" error={Boolean(touched.content && errors.content)}>
                    <FormLabel>
                      <Translate contentKey="kafaatApp.notification.content">content </Translate>
                    </FormLabel>
                    <FilledInput
                      multiline
                      rows={3}
                      type={'text'}
                      name="content"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.content}
                    />
                    {touched.content && errors.content && (
                      <FormHelperText error id="standard-weight-helper-text">
                        {errors.content.toString()}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth margin="normal" variant="filled" error={Boolean(touched.targetUsers && errors.targetUsers)}>
                    <FormLabel>
                      <Translate contentKey="kafaatApp.notification.targetUsers">targetUsers </Translate>
                    </FormLabel>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={e => {
                            setAllUsers(e.target.checked);
                            setFieldValue('all_Users', e.target.checked);
                          }}
                        />
                      }
                      label={<Translate contentKey="kafaatApp.notification.allUsers">allUsers </Translate>}
                    />
                    <Autocomplete
                      multiple
                      disabled={allUsers}
                      onBlur={handleBlur}
                      inputMode={'text'}
                      value={values.targetUsers}
                      onChange={(e, value) => setFieldValue('targetUsers', value)}
                      getOptionLabel={(option: any) => `${option.firstName} ${option.lastName}`}
                      renderInput={params => (
                        <TextField error={Boolean(touched.targetUsers && errors.targetUsers)} {...params} variant={'filled'} />
                      )}
                      options={allEmployees}
                    />
                    {touched.targetUsers && errors.targetUsers && (
                      <FormHelperText error id="standard-weight-helper-text">
                        {errors.targetUsers.toString()}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth margin="normal" error={Boolean(touched.sendDate && errors.sendDate)}>
                    <FormLabel>
                      <Translate contentKey="kafaatApp.notification.sendDate">sendDate </Translate>
                    </FormLabel>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DateTimePicker
                        value={values.sendDate}
                        onChange={event => {
                          setFieldValue('sendDate', event.$d);
                          return event.$d;
                        }}
                        renderInput={params => <TextField {...params} variant={'filled'} value={values.sendDate} />}
                      />
                    </LocalizationProvider>
                    {touched.sendDate && errors.sendDate && (
                      <FormHelperText error id="standard-weight-helper-text">
                        {errors.sendDate.toString()}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
              <br /> <br />
            </DialogContent>
            <DialogActions>
              <Button size={'large'} onClick={handleClose} variant="outlined" color={'secondary'}>
                <Translate contentKey="kafaatApp.business.home.cancel">Cancel</Translate>
              </Button>
              <LoadingButton size={'large'} color="primary" variant="contained" loading={isSubmitting} type={'submit'}>
                <Translate contentKey="kafaatApp.business.home.save">Save</Translate>
              </LoadingButton>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};

export default NotificationCreate;
