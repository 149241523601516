import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import Notification from './notification';

const NotificationRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<Notification />} />
  </ErrorBoundaryRoutes>
);

export default NotificationRoutes;
